import { api } from './api';
import {
  NewOrder,
  Order,
  OrderForList,
  OrderItemForCreation,
  OrdersPaginatedResponse,
  PatchOrder
} from '../models/order';
import { Params } from '../models/datagrid';
import { getOrderStepFromWorkflow } from '../utils/order.utils';

export const ordersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<OrdersPaginatedResponse, Params>({
      query: (arg) => {
        const { page, limit, filtersQuery, searchQuery } = arg;
        return `/orders?page=${page}&filter.deletedAt=$null&filter.items.deletedAt=$null&limit=${limit}${filtersQuery}${searchQuery}&sortBy=submissionDate:DESC&sortNulls=DESC`;
      },
      transformResponse: (response: OrdersPaginatedResponse) => {
        const mappedData = response.data.map((order) => {
          return {
            ...order,
            currentStep: getOrderStepFromWorkflow(order.currentStep)
          };
        });

        // Add fake data to create an empty line, allowing you to scroll far enough so that the last line is accessible above the filter.
        if ('links' in response && !('next' in response.links) && response.meta.totalItems > 0) {
          mappedData.push({
            id: -1,
            dentistName: '',
            dentistEmail: '',
            creationDate: new Date('December 17, 1995 03:24:00'),
            patientReference: '',
            orderNumber: '',
            labName: '',
            labId: 0,
            toManufacture: false
          } as OrderForList);
        }

        return {
          ...response,
          data: mappedData
        };
      }
    }),
    getOneOrder: builder.query<Order, string>({
      query: (orderNumber) => ({
        url: `/orders/${orderNumber}`,
        method: 'GET'
      }),
      providesTags: ['OneOrder']
    }),
    createOrder: builder.mutation<Order, NewOrder>({
      query: (payload: NewOrder) => ({
        url: '/orders',
        method: 'POST',
        body: payload
      })
    }),
    patchOrder: builder.mutation<Order, PatchOrder & { orderNumber: string }>({
      query: ({ orderNumber, ...patch }) => ({
        url: `/orders/${orderNumber}`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: ['OneOrder']
    }),
    createOrderItem: builder.mutation<Order, { orderNumber: string; item: OrderItemForCreation }>({
      query: (arg) => {
        const { orderNumber, item } = arg;
        return {
          url: `/orders/${orderNumber}/items`,
          method: 'POST',
          body: item
        };
      }
    })
  })
});

export const {
  useLazyGetOrdersQuery,
  useGetOneOrderQuery,
  useCreateOrderMutation,
  usePatchOrderMutation,
  useCreateOrderItemMutation
} = ordersApi;
