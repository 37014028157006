import { Navigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useLazyGetUserInfoQuery } from '../services/user.api.services.ts';
import { UserInfos } from '../models/user.tsx';

interface ProtectedRouteObj {
  isAllowed: boolean | undefined;
  neededRights: string[];
  redirectPath: string;
  children: JSX.Element;
}

const ProtectedRoute = ({
  isAllowed,
  neededRights = [],
  redirectPath = '/',
  children
}: Partial<ProtectedRouteObj>): JSX.Element => {
  // To control the call of the user detail when React mount the component
  const [getCurrentUser] = useLazyGetUserInfoQuery();

  const [hasPermission, setHasPermission] = useState<boolean | undefined>(undefined);
  const [userApiGetCalled, setUserApiGetCalled] = useState<boolean>(false);

  const manageUserRightsAccess = async () => {
    const currentUser: UserInfos = await getCurrentUser().unwrap();
    const currentUserRights = neededRights?.filter(
      (neededRight) => currentUser?.[neededRight as keyof UserInfos]
    );

    setUserApiGetCalled(true);
    setHasPermission(neededRights?.length === currentUserRights?.length);
  };

  useEffect(() => {
    if (neededRights?.length > 0 && !userApiGetCalled) {
      manageUserRightsAccess().catch();
    }
    if (neededRights?.length === 0) {
      setHasPermission(true);
      setUserApiGetCalled(false);
    }
  }, [neededRights]);

  // When the user is not allowed (not authenticated most of the time)
  // or when the user have not the required permissions.
  if (!isAllowed || hasPermission === false) {
    return <Navigate to={redirectPath} replace />;
  }

  /**
   * Until the permissions are not get from the back but the user is authenticated
   * we return an empty child until the RTK response arrived.
   */
  if (!hasPermission && neededRights?.length > 0) {
    return <></>;
  }

  return children || <Outlet />;
};

export default ProtectedRoute;
