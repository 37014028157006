import { UserInfos } from '../models/user';
import { capitalizeFirstLetter } from './utils';
import {
  FilterProps,
  MenuEntry,
  SortProps,
  TypeColWithNamePropertyPlatform
} from '../models/datagrid.tsx';
import { SortDirection, SortDirectionNb } from '../enum/datagrid.ts';
import { DentistRole } from '../enum/user.ts';
import {
  Chips,
  DropdownMenu,
  IconButton,
  Tooltip,
  TooltipContent
} from '@anatoscope/circlestorybook';
import i18next from 'i18next';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import moment from 'moment/moment';
import { TypeColWithNameProperty } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { ColorPropsEnum } from '../enum/color.ts';

export const mapUsers = (users: UserInfos[]): UserInfos[] => {
  return users?.map((user: UserInfos) => mapUser(user));
};

export const mapUser = (user: UserInfos): UserInfos => {
  return {
    ...user,
    firstName: capitalizeFirstLetter(user.firstName),
    lastName: capitalizeFirstLetter(user.lastName)
  };
};
export const columnsDataUser = (
  connectedUser: UserInfos,
  deactivateCallback: (user: UserInfos) => void
): TypeColWithNamePropertyPlatform[] => {
  return [
    {
      ...getColumnOptions(
        'lastName',
        i18next.t('team.datagrid.columns.lastName', { ns: 'profile' }),
        1
      ),
      showColumnMenuTool: false
    },
    {
      ...getColumnOptions(
        'firstName',
        i18next.t('team.datagrid.columns.firstName', { ns: 'profile' }),
        1
      ),
      showColumnMenuTool: false
    },
    {
      ...getColumnOptions('email', i18next.t('team.datagrid.columns.email', { ns: 'profile' }), 1),
      showColumnMenuTool: false,
      render: ({ value }) => {
        return (
          <>
            <IconButton
              faIconClass="fa-regular fa-envelope"
              radius="full"
              onClick={() => {
                window.open(`mailto:'${value}`, '_blank');
              }}
            />
            {value}
          </>
        );
      }
    },
    {
      ...getColumnOptions('role', i18next.t('team.datagrid.columns.role', { ns: 'profile' }), 1),
      showColumnMenuTool: false,
      maxWidth: 100,
      filterEditor: SelectFilter,
      filterEditorProps: {
        dataSource: [
          { id: DentistRole.DENTIST, label: i18next.t('user.roles.dentist', { ns: 'common' }) },
          { id: DentistRole.ASSISTANT, label: i18next.t('user.roles.assistant', { ns: 'common' }) }
        ]
      },
      render: ({ data }) => {
        const roleKey: keyof typeof DentistRole = data.role;
        const roleInEnum = Object.keys(DentistRole).includes(data.role)
          ? i18next.t(`user.roles.${DentistRole[roleKey].toLowerCase()}`, {
              ns: 'common'
            })
          : null;

        return <p>{roleInEnum || '-'}</p>;
      }
    },
    {
      ...getColumnOptions(
        'deletedAt',
        i18next.t('team.datagrid.columns.status', { ns: 'profile' }),
        1,
        false
      ),
      showColumnMenuTool: false,
      maxWidth: 100,
      filterEditor: SelectFilter,
      filterEditorProps: {
        dataSource: [
          { id: 'active', label: i18next.t('active', { ns: 'common' }) },
          { id: 'inactive', label: i18next.t('inactive', { ns: 'common' }) }
        ]
      },
      render: ({ value }) => {
        let chips = (
          <Chips
            firstLabel={i18next.t(value ? 'inactive' : 'active', { ns: 'common' })}
            color={value ? ColorPropsEnum.DANGER : ColorPropsEnum.SUCCESS}
          />
        );
        if (value) {
          chips = (
            <Tooltip>
              <TooltipContent>
                {moment(value).format(i18next.t('date.full', { ns: 'common' }))}
              </TooltipContent>
              {chips}
            </Tooltip>
          );
        }
        return chips;
      }
    },
    {
      ...getColumnOptions('menu', '', 1, false),
      showColumnMenuTool: false,
      maxWidth: 60,
      render: ({ data }) => {
        return (
          <DropdownMenu
            renderTargetButton={({ active }: { active: boolean }) => (
              <IconButton faIconClass="ellipsis-vertical" isActive={active} radius="full" />
            )}
            data={buildMenu(connectedUser, data, deactivateCallback)}
          />
        );
      }
    }
  ];
};

const buildMenu = (
  connectedUser: UserInfos,
  user: UserInfos,
  deactivateCallback: (user: UserInfos) => void
): MenuEntry[][] => {
  return [
    [
      {
        label: i18next.t('team.datagrid.menu.deactivate', { ns: 'profile' }),
        onClick: () => {
          deactivateCallback(user);
        },
        type: 'button',
        disabled:
          user.deletedAt !== undefined ||
          isAssistant(connectedUser) ||
          connectedUser.email === user.email
      }
    ]
  ];
};

export const buildUsersFilters = (filterValue?: Array<FilterProps>) => {
  let filters = '';
  if (filterValue?.length) {
    filterValue
      .filter((filter) => filter.value)
      .forEach((filter) => {
        switch (filter.name) {
          case 'role':
            filters = `${filters}&filter.${filter.name}=${filter.value}`;
            break;
          case 'deletedAt':
            filters = `${filters}&filter.deletedAt=${
              filter.value === 'active' ? '$null' : '$not:$null'
            }`;
            break;
          default:
            filters = `${filters}&filter.${filter.name}=$ilike:${filter.value}`;
            break;
        }
      });
    if (!filters.includes('&filter.role')) {
      filters = `${filters}&filter.role=$in:${DentistRole.DENTIST},${DentistRole.ASSISTANT}`;
    }
  }
  return filters;
};

export const buildSort = (sortInfo: SortProps | SortProps[]) => {
  let urlSort = '';
  if (sortInfo) {
    const sortsArray = Array.isArray(sortInfo) ? sortInfo : [sortInfo];
    sortsArray.forEach(function (sort) {
      urlSort = `${urlSort}&sortBy=${sort.name}:${
        sort.dir === SortDirectionNb.ASC_AS_NB ? SortDirection.ASC : SortDirection.DESC
      }`;
    });
  }
  return urlSort;
};

const getColumnOptions = (
  name: string,
  header: string,
  defaultFlex?: number,
  sortable?: boolean // true by default
): Partial<TypeColWithNameProperty> => {
  let column: Partial<TypeColWithNamePropertyPlatform> = {
    name: name,
    headerAlign: 'start',
    header: header,
    headerProps: { className: 'header' },
    showColumnMenuTool: true,
    showColumnMenuToolOnHover: false,
    enableColumnFilterContextMenu: false,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left'
      }
    })
  };
  if (defaultFlex) {
    column = { ...column, defaultFlex: defaultFlex };
  }
  if (sortable !== undefined) {
    column = { ...column, sortable: sortable };
  }
  return column;
};

export const isAssistant = (user?: UserInfos): boolean => user?.role === DentistRole.ASSISTANT;
