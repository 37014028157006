import styles from './plan-step.module.scss';
import { TeethMap } from '@anatoscope/circlestorybook';
import MenuTools from '../menu-tools/MenuTools.tsx';
import { useEffect, useMemo, useState } from 'react';
import { Family, ProductCategory, TeethMode } from '../../../../enum/product.ts';
import { useGetCommonTypesQuery } from '../../../../services/common-types-api.services.ts';
import {
  createOrderItems,
  handleSelectTeethMultiMode,
  handleSelectTeethMissingOrExtract,
  handleSelectTeethSingleMode
} from './plan-step.tsx';
import ProductsManager, {
  MappedProducts
} from '../../../../features/order-manager/products-manager.tsx';
import { useGetAllProductsQuery } from '../../../../services/products-api.services.ts';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks.tsx';
import {
  selectCurrentItemSelector,
  selectDetailOrderSelector,
  selectEditionPlanStep,
  selectEditionStepIsActive,
  selectEditionStepIsDisabled,
  selectEditionStepIsValid,
  selectItemsSelector
} from '../../../../store/orders/orders.selectors.tsx';
import { OrderTreatmentStep } from '../../../../enum/order.ts';
import { DentalNotation } from '../../../../enum/user.ts';
import { ColorPropsEnum } from '../../../../enum/color.ts';
import { useGetUserInfoQuery } from '../../../../services/user.api.services.ts';
import { PositionKeyString, SelectionContextEnum, ToolEnum } from '../../../../enum/map.enum.ts';
import { mapActions } from '../../../../store/map/map.reducer.tsx';
import { getRelevantComponentsForDisplay } from '../../../../features/order-manager/teeth-map/utils.ts';
import { NotAllowedCursors } from '../../../../features/order-manager/teeth-map/cursors.utils.ts';
import {
  activeItemSelector,
  cursorsSelector,
  lineAndNumberColorsSelector,
  mapComponentsSelector,
  mapContextSelector,
  mapStepBubblesSelector,
  zoneLinkPropsSelector
} from '../../../../store/map/map.selectors.tsx';
import { ComponentType, PositionKey } from '../../../../enum/component.ts';
import {
  Order,
  OrderItemComponentLight,
  OrderItemLight,
  PlanSelectionOptions
} from '../../../../models/order.tsx';
import TreatmentProductBubbleMenu from './menus/TreatmentProductBubbleMenu/TreatmentProductBubbleMenu.tsx';
import TreatmentCategoryMenu from './menus/TreatmentCategoryMenu/TreatmentCategoryMenu.tsx';
import TreatmentFamilyMenu from './menus/TreatmentFamilyMenu/TreatmentFamilyMenu.tsx';
import { ordersActions } from '../../../../store/orders/orders.reducer.tsx';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer.tsx';
import { ToastType } from '../../../../enum/error.ts';
import { useTranslation } from 'react-i18next';
import { getActiveFrameMaterial, getActiveGingiva, getActiveShade } from '../../utils.ts';
import TreatmentTeethMissingOrExtractMenu from './menus/TreatmentTeethMissingOrExtractTeethMenu/TreatmentTeethMissingOrExtractMenu.tsx';
import {
  useCreateOrderItemMutation,
  usePatchOrderMutation
} from '../../../../services/orders-api.services.ts';
import { MapContext } from '../../../../models/map.tsx';
import { Component } from '../../../../models/component.ts';

/**
 * Plan step, select a product and add it to the map.
 * @constructor
 */
const PlanStep = () => {
  // React utils.
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['treatment']);

  // Menu states.
  const isDisabledStep: boolean = useAppSelector((state) =>
    selectEditionStepIsDisabled(state, OrderTreatmentStep.PLAN)
  );
  const isActiveStep: boolean = useAppSelector((state) =>
    selectEditionStepIsActive(state, OrderTreatmentStep.PLAN)
  );
  const isValidStep: boolean = useAppSelector((state) =>
    selectEditionStepIsValid(state, OrderTreatmentStep.PLAN)
  );

  // Redux Selectors
  // Teeth Map - refacto to plan to declare correct types.
  const mapComponents = useAppSelector(mapComponentsSelector);
  const zoneLinkProps = useAppSelector(zoneLinkPropsSelector);
  const cursors = useAppSelector(cursorsSelector);
  const mapContext: MapContext | undefined = useAppSelector(mapContextSelector);
  const productBubbles = useAppSelector((state) =>
    mapStepBubblesSelector(state, OrderTreatmentStep.PLAN)
  );
  const activeMapItem = useAppSelector(activeItemSelector);

  const lineAndNumberColors = useAppSelector(lineAndNumberColorsSelector);
  // Selected elements in menu
  const selectedOptionsPlanStep: PlanSelectionOptions | undefined =
    useAppSelector(selectEditionPlanStep);
  // Current Item.
  const currentItem: OrderItemLight | undefined = useAppSelector(selectCurrentItemSelector);
  // Order and items for and from the back.
  const orderItems: OrderItemLight[] | undefined = useAppSelector(selectItemsSelector);
  const orderDetail: Partial<Order> | undefined = useAppSelector(selectDetailOrderSelector);

  // RTK Queries
  const { data: commonTypes, isSuccess: isSuccessGetCommonTypes } = useGetCommonTypesQuery();
  const { data: connectedUser } = useGetUserInfoQuery();
  const { data: products, isSuccess: isSuccessGetProducts } = useGetAllProductsQuery();
  const [patchOrder, { isSuccess: isPatchedOrder }] = usePatchOrderMutation();
  const [createOrderItem] = useCreateOrderItemMutation();

  // Manage menus like families, categories and products.
  const productsManager = useMemo(() => new ProductsManager(), []);

  // Use states.
  const [mappedProducts, setMappedProducts] = useState<MappedProducts | undefined>({});
  const [families, setFamilies] = useState<Family[]>([]);
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [selectedTeethMissingOrExtract, setSelectedTeethMissingOrExtract] = useState<
    ToolEnum | undefined
  >(undefined);

  // Constants
  const familiesObjectEnum: { [key in keyof typeof Family]: Family } | undefined =
    commonTypes?.families;
  const categoriesObjectEnum:
    | { [key in keyof typeof ProductCategory]: ProductCategory }
    | undefined = commonTypes?.productCategories;

  const stepStatus = {
    isDisabled: isDisabledStep,
    isActive: isActiveStep,
    isValid: isValidStep
  };

  /**
   * Validate the current item.
   * If the current item is valid, the function returns true.
   * Otherwise, the function returns false and dispatch toast errors and invalid the step state.
   */
  const isValidCurrentItem = (): boolean => {
    let isValid: boolean = true;
    let message: string | undefined = undefined;
    if (currentItem?.product && mapContext?.userAction) {
      if (activeMapItem.TOOTH.length === 0) {
        message = t('treatment.errors.single-range');
        isValid = false;
      }

      const minTeeth = currentItem.product?.components?.find(
        (component: Component) => component?.componentType === ComponentType.TOOTH
      )?.rule?.min;
      if (minTeeth && activeMapItem.TOOTH.length < minTeeth) {
        message = t('treatment.errors.minTeeth', { count: minTeeth });
        isValid = false;
      }
    }

    if (!isValid && message) {
      dispatch(
        feedbackActions.setToast({
          type: ToastType.DANGER,
          message
        })
      );
      dispatch(
        ordersActions.setValidCreationStepsStatus({
          step: OrderTreatmentStep.PLAN,
          isValid: false
        })
      );
    }

    return isValid;
  };

  /**
   * Reset Map Context and selections.
   */
  const resetMapReducer = (): void => {
    dispatch(mapActions.removeActiveProduct());
    dispatch(mapActions.resetSelectionTeeth());
    dispatch(mapActions.resetMapContext());
  };

  /**
   * Reset order current selection.
   */
  const resetOrderReducer = (): void => {
    dispatch(ordersActions.setCurrentItem(undefined));
    dispatch(
      ordersActions.setStepPlanSelections({
        selections: { product: undefined }
      })
    );
  };

  /**
   * Add current item to order items.
   */
  const addCurrentItemToOrderItems = (): void => {
    dispatch(
      ordersActions.setValidCreationStepsStatus({
        step: OrderTreatmentStep.PLAN,
        isValid: true
      })
    );
    const counter = orderItems?.filter((orderItem) => {
      return orderItem?.product?.id === currentItem?.product.id;
    }).length;
    dispatch(
      mapActions.commitProductToMap({
        productId: currentItem?.product.id as number,
        uniqueProductId: currentItem?.product.id + '_' + counter
      })
    );

    if (currentItem?.itemComponents?.length) {
      const itemComponents: OrderItemComponentLight[] = [];
      currentItem?.itemComponents?.forEach((component: OrderItemComponentLight) => {
        itemComponents.push({
          ...component,
          teethPositions: activeMapItem.TOOTH as PositionKey[]
        });
      });
      const itemToSave = { ...currentItem, itemComponents };
      dispatch(ordersActions.setItem(itemToSave));
      dispatch(
        ordersActions.setDiagnostic({
          teethToBeExtracted: mapComponents.EXTRACT as number[],
          missingTeeth: mapComponents.MISSING as number[]
        })
      );

      dispatch(
        ordersActions.setValidCreationStepsStatus({
          step: OrderTreatmentStep.PLAN,
          isValid: true
        })
      );
    }
  };

  /**
   * Add multi range item to order items.
   * When we used teeth options after, the map cannot be reset like that to avoid the disappearance of the item.
   * @param resetMap
   */
  const addMultiRangeItemToOrderItems = (resetMap: boolean = true): void => {
    if (mapContext?.teethMode === TeethMode.MULTI_RANGE) {
      addCurrentItemToOrderItems();
      resetOrderReducer();
      if (resetMap) {
        resetMapReducer();
      }
    }
  };

  // User interactions

  /**
   * Validate and submit the order with diagnostic and items.
   */
  const handleSubmit = async (): Promise<void> => {
    /**
     * To add a multi-range product.
     */
    const isValidItem: boolean = isValidCurrentItem();
    if (isValidItem && currentItem) {
      addCurrentItemToOrderItems();
    }
    if (isValidItem && isValidStep && !isDisabledStep && orderItems) {
      dispatch(
        ordersActions.setDiagnostic({
          teethToBeExtracted: mapComponents.EXTRACT as number[],
          missingTeeth: mapComponents.MISSING as number[]
        })
      );
      await createOrderItems(orderDetail?.orderNumber as string, orderItems, createOrderItem);
      patchOrder({
        orderNumber: orderDetail?.orderNumber as string,
        patient: orderDetail?.patient
      });

      if (isPatchedOrder) {
        dispatch(
          feedbackActions.setToast({
            type: ToastType.SUCCESS,
            message: t('treatment.plan.success')
          })
        );
      }
    }
  };

  /**
   * Function to select a tooth.
   *
   * @param {PositionKeyString} selectedTooth - The tooth to be selected.
   */
  const handleSelectTooth = (selectedTooth: PositionKeyString): void => {
    handleSelectTeethMissingOrExtract(selectedTooth, selectedTeethMissingOrExtract, dispatch);
    if (currentItem?.itemComponents && commonTypes && mapContext) {
      const activeShade = getActiveShade(currentItem.itemComponents, commonTypes);
      const activeGingiva = getActiveGingiva(currentItem.itemComponents, commonTypes);
      const activeFrameMaterial = getActiveFrameMaterial(currentItem.itemComponents, commonTypes);

      switch (currentItem?.product?.teethMode) {
        case TeethMode.SINGLE_RANGE:
          handleSelectTeethSingleMode(
            selectedTooth,
            {
              activeShade,
              activeGingiva,
              activeFrameMaterial
            },
            mapContext,
            currentItem,
            dispatch
          );
          break;
        case TeethMode.MULTI_RANGE:
          handleSelectTeethMultiMode(
            selectedTooth,
            {
              activeShade,
              activeGingiva,
              activeFrameMaterial
            },
            mapContext,
            currentItem,
            dispatch
          );
          break;
        default:
          break;
      }

      // update selection teeth for cursors
      dispatch(mapActions.computeProductSelectionTooth());
    }
  };

  /**
   * Remove Missing Teeth or Extract Teeth selections
   * @param event
   */
  const handleClickOutsideTheMapWithTeethMissingOrExtract = (
    event: React.MouseEvent<HTMLElement>
  ): void => {
    /**
     * Deactivated the teeth options when the user clicks somewhere else the menu.
     */
    const eventTarget = event.target as HTMLElement;
    if (!eventTarget.closest('#plan-step--teeth-map--teeth-missing-extract, #teeth-map')) {
      setSelectedTeethMissingOrExtract(undefined);
    }
  };

  /**
   * Set all available families and categories for the lab.
   */
  useEffect(() => {
    if (isSuccessGetCommonTypes && !families.length && !categories.length) {
      setFamilies(familiesObjectEnum ? Object.values(familiesObjectEnum) : []);
      setCategories(categoriesObjectEnum ? Object.values(categoriesObjectEnum) : []);
    }
  }, [isSuccessGetCommonTypes]);

  /**
   * Set all available products.
   */
  useEffect(() => {
    if (products && families.length && categories.length && products?.data.length > 0) {
      productsManager.init(families, categories, products.data);
      setMappedProducts(productsManager?.mappedProducts);
    }
  }, [categories, families, isSuccessGetProducts]);

  /**
   * Set the current product selected on the map.
   */
  useEffect(() => {
    if (currentItem) {
      const componentTooth = currentItem?.product?.components?.find(
        (component) => component.componentType === ComponentType.TOOTH
      );

      dispatch(
        mapActions.initMapContext({
          productId: currentItem.product.id,
          teethMode: currentItem.product.teethMode,
          teethComponentRule: componentTooth?.rule,
          productRule: currentItem?.product.productRule
        })
      );
      dispatch(mapActions.initSelectionTooth());
    }
  }, [currentItem]);

  /**
   * If all items are deleted, the step becomes invalid.
   */
  useEffect(() => {
    if (!orderItems) {
      dispatch(
        ordersActions.setValidCreationStepsStatus({
          step: OrderTreatmentStep.PLAN,
          isValid: false
        })
      );
    }
  }, [orderItems]);

  /**
   * Add the current finished item to the items list.
   */
  useEffect(() => {
    if (
      mapContext?.userAction &&
      [SelectionContextEnum.RANGE_ENDED].includes(mapContext?.userAction) &&
      isValidCurrentItem()
    ) {
      addCurrentItemToOrderItems();
      resetMapReducer();
      resetOrderReducer();
    }
  }, [mapContext?.userAction]);

  return (
    <form
      className={styles['plan-step']}
      onClick={handleClickOutsideTheMapWithTeethMissingOrExtract}>
      <div className={styles['plan-step--middle']}>
        <div data-cy="planStepFamilies">
          <TreatmentFamilyMenu
            isValidCurrentItemCallback={isValidCurrentItem}
            addMultiRangeCallback={addMultiRangeItemToOrderItems}
            mappedProducts={mappedProducts}
            familiesObjectEnum={familiesObjectEnum}
            selectedOptions={selectedOptionsPlanStep}
            stepStatus={stepStatus}
            productsManager={productsManager}
            submitCallback={handleSubmit}
            isLoading={!isSuccessGetCommonTypes || !isSuccessGetProducts}
          />
        </div>
        <div data-cy="planStepTeethMap" className={styles['plan-step--teeth-map']}>
          <TeethMap
            notation={
              connectedUser?.dentalNotation ? connectedUser.dentalNotation : DentalNotation.ISO
            }
            onClick={(tooth: PositionKeyString) => handleSelectTooth(tooth)}
            patientMouth={getRelevantComponentsForDisplay(mapComponents)}
            cursors={currentItem || selectedOptionsPlanStep ? cursors : NotAllowedCursors}
            teethShades={mapComponents['SHADES']}
            zoneLinkProps={zoneLinkProps}
            color={ColorPropsEnum.WHITE}
            bubbles={productBubbles}
            lineAndNumberColors={lineAndNumberColors}
          />
          <div data-cy="planStepTeeth">
            <TreatmentTeethMissingOrExtractMenu
              stepStatus={stepStatus}
              isValidCurrentItemCallback={isValidCurrentItem}
              addMultiRangeCallback={addMultiRangeItemToOrderItems}
              selectedTeethMissingOrExtract={selectedTeethMissingOrExtract}
              setSelectedTeethMissingOrExtract={setSelectedTeethMissingOrExtract}
            />
          </div>
        </div>
        <div data-cy="planStepMenuTools" className={styles['plan-step--menu-tools']}>
          <MenuTools />
        </div>
      </div>

      <div data-cy="planStepProducts" className={styles['plan-step--products']}>
        <div data-cy="planStepCategories">
          {isSuccessGetCommonTypes &&
            isSuccessGetProducts &&
            categoriesObjectEnum &&
            mappedProducts &&
            selectedOptionsPlanStep && (
              <TreatmentCategoryMenu
                isValidCurrentItemCallback={isValidCurrentItem}
                addMultiRangeCallback={addMultiRangeItemToOrderItems}
                categoriesObjectEnum={categoriesObjectEnum}
                mappedProducts={mappedProducts}
                selectedOptions={selectedOptionsPlanStep}
                stepStatus={stepStatus}
              />
            )}
        </div>
        <div data-cy="planStepProductsItems">
          {isSuccessGetCommonTypes &&
            isSuccessGetProducts &&
            selectedOptionsPlanStep &&
            mappedProducts && (
              <TreatmentProductBubbleMenu
                isValidCurrentItemCallback={isValidCurrentItem}
                addMultiRangeCallback={addMultiRangeItemToOrderItems}
                selectedOptions={selectedOptionsPlanStep}
                mappedProducts={mappedProducts}
                stepStatus={stepStatus}
              />
            )}
        </div>
      </div>
    </form>
  );
};
export default PlanStep;
