import { Text, TextField } from '@anatoscope/circlestorybook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import styles from './reference-step.module.scss';
import { useTranslation } from 'react-i18next';
import useForm from '../../../../utils/useForm.tsx';
import { useEffect, useState } from 'react';
import { GoToPlanStep as goToPlanStep } from './reference-step.tsx';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks.tsx';
import { ordersActions } from '../../../../store/orders/orders.reducer.tsx';
import { OrderTreatmentStep } from '../../../../enum/order.ts';
import { useGetUserInfoQuery } from '../../../../services/user.api.services.ts';
import {
  useCreateOrderMutation,
  usePatchOrderMutation
} from '../../../../services/orders-api.services.ts';
import { NewOrder, Order } from '../../../../models/order.tsx';
import {
  selectDetailOrderSelector,
  selectEditionStepIsDisabled,
  selectReferenceValue
} from '../../../../store/orders/orders.selectors.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { ColorPropsEnum } from '../../../../enum/color.ts';

const ReferenceStep = () => {
  const navigate = useNavigate();
  const { orderNumber } = useParams();
  const { t } = useTranslation(['treatment']);
  const detailOrder = useAppSelector(selectDetailOrderSelector);
  const referenceOrder = useAppSelector(selectReferenceValue);
  const isDisabledStep = useAppSelector((state) =>
    selectEditionStepIsDisabled(state, OrderTreatmentStep.REFERENCE)
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [newOrder, setNewOrder] = useState<Partial<Order> | undefined>(detailOrder);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const onSubmit = () => {
    if (newOrder && !newOrder.orderNumber) {
      createOrder(newOrder as NewOrder);
    } else if (newOrder) {
      if (isUpdated) {
        patchOrder({
          patient: {
            reference: newOrder.patient?.reference
          },
          orderNumber: newOrder.orderNumber ?? ''
        });
      } else {
        goToPlanStep(dispatch);
        navigate(`/treatment/${newOrder?.orderNumber}/edit/${OrderTreatmentStep.PLAN}`);
      }
    }
  };

  const { data: connectedUser, isSuccess: isConnectedUserSuccess } = useGetUserInfoQuery();
  const initReference = { reference: referenceOrder ?? '' };
  const { values, errors, handleSubmit, handleBlur, handleChange } = useForm(
    initReference,
    onSubmit
  );
  const dispatch = useAppDispatch();

  const [createOrder, { isSuccess: isCreatedOrder, data: createdOrder }] = useCreateOrderMutation();
  const [patchOrder, { isSuccess: isPatchedOrder, data: patchedOrder }] = usePatchOrderMutation();

  // Init the new order with the current user data when we create an order.
  useEffect(() => {
    if (!orderNumber && isConnectedUserSuccess) {
      const newOrder = {
        dentistName: `${connectedUser?.firstName} ${connectedUser?.lastName}`,
        dentistEmail: `${connectedUser?.email}`,
        clinicName: connectedUser?.clinic?.name ?? '',
        clinicId: connectedUser?.clinic?.id ?? -1,
        patient: {
          reference: (values.reference as string) ?? ''
        },
        labId: connectedUser?.laboratory?.id,
        labName: connectedUser?.laboratory?.name
      };
      // Init the references to update in live the navigation bar.
      dispatch(
        ordersActions.setOrderReferences({
          patient: { reference: newOrder.patient.reference }
        })
      );
      setNewOrder(newOrder);
      setIsLoaded(true);
    }
  }, [isConnectedUserSuccess]);

  // Load the detail order data in the state when we edit an order.
  useEffect(() => {
    if (orderNumber && detailOrder) {
      setNewOrder(detailOrder);
      setIsLoaded(true);
    }
  }, [detailOrder]);

  // Patch state and redux when the input value changes. Only if the component is loaded of course. Otherwise,
  // it's a bit more difficult.
  useEffect(() => {
    if (isLoaded) {
      dispatch(ordersActions.resetPlanEdition());
      const patchOrder = {
        ...newOrder,
        ...{
          patient: {
            reference: (values.reference as string) ?? ''
          }
        }
      };

      // Update the navigation bar.
      dispatch(
        ordersActions.setOrderReferences({
          orderNumber: patchOrder?.orderNumber,
          patient: {
            reference: patchOrder?.patient.reference
          }
        })
      );
      setNewOrder(patchOrder);

      dispatch(
        ordersActions.setValidCreationStepsStatus({
          step: OrderTreatmentStep.REFERENCE,
          isValid: !!values?.reference
        })
      );
    }
  }, [values, isLoaded]);

  // Patch redux when the api returns data after create or patch the order.
  useEffect(() => {
    if (isCreatedOrder) {
      dispatch(
        ordersActions.setOrderReferences({
          orderNumber: createdOrder?.orderNumber,
          patient: {
            reference: createdOrder?.patient.reference
          }
        })
      );
      setNewOrder(createdOrder);
      goToPlanStep(dispatch);
      navigate(`/treatment/${createdOrder?.orderNumber}/edit/${OrderTreatmentStep.PLAN}`);
    }
    if (isPatchedOrder) {
      dispatch(
        ordersActions.setOrderReferences({
          orderNumber: patchedOrder?.orderNumber,
          patient: {
            reference: patchedOrder?.patient.reference
          }
        })
      );
      setNewOrder(patchedOrder);
      goToPlanStep(dispatch);
      navigate(`/treatment/${patchedOrder?.orderNumber}/edit/${OrderTreatmentStep.PLAN}`);
    }
  }, [isCreatedOrder, isPatchedOrder]);

  return (
    isLoaded && (
      <div className={styles['reference-step']}>
        <form onSubmit={handleSubmit}>
          <div
            data-cy="referencePatientFieldset"
            className={[
              styles['reference-step__fieldset'],
              errors?.reference ? styles['reference-step__fieldset-danger'] : ''
            ].join(' ')}>
            <div className={styles['reference-step__fieldset__textfield']}>
              <TextField
                data-cy="referencePatient"
                id="referencePatient"
                name="reference"
                label={t('treatment.referencePatient')}
                onBlur={handleBlur}
                onChange={(event: React.FormEvent) => {
                  handleChange(event);
                  setIsUpdated(true);
                }}
                value={values.reference}
                helperText={
                  errors?.reference ? t('treatment.creation.referenceMandatory') : undefined
                }
                placeholder={t('treatment.referencePatient')}
                labelColor="grey"
                size="s"
                radius="full"
                inputHeight="l"
                variant={errors?.reference ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
                isDisabled={isDisabledStep}
              />
            </div>
            <button
              data-cy="referencePatientSubmit"
              type="submit"
              className={styles['reference-step__fieldset__submit']}
              disabled={isDisabledStep}>
              <FontAwesomeIcon icon={faCheck} size={'lg'} color={ColorPropsEnum.WHITE} />
            </button>
          </div>
          <div className={styles['reference-step__helper']}>
            <Text
              data-cy="referencePatientHelper"
              size="s"
              color={ColorPropsEnum.WHITE}
              label={t('treatment.helper.anonymous')}
              bold={true}
            />
            <Text
              data-cy="referencePatientDescription"
              color={ColorPropsEnum.WHITE}
              size="s"
              label={t('treatment.helper.description')}
            />
          </div>
        </form>
      </div>
    )
  );
};
export default ReferenceStep;
