// Sorry but when we used a typed library that not have any coherence with their docs...
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import './datagrid-override.scss';
import i18next from 'i18next';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';

const DatagridFeature = ({ ...props }: Partial<TypeDataGridProps>) => {
  // Adding custom styles to each column header & cell
  props.columns?.forEach((element: TypeColumn & { cellProps: CellProps }) => {
    (element.textAlign as string) = 'center';
  });

  return (
    <ReactDataGrid
      {...props}
      licenseKey="AppName=multi_app,Company=CircleAnatoscopeIntuitiveDesign,ExpiryDate=2024-09-27T00:00:00.000Z,LicenseDeveloperCount=7,LicenseType=multi_app,Ref=CircleAnatoscopeIntuitiveDesignLicenseRef,Z=1092652003-1280136484-14595002217877925101092652003-220672582"
      showHoverRows={false}
      defaultLimit={25}
      showCellBorders={false}
      showColumnMenuTool={false}
      loadingText={i18next.t('loading', { ns: 'common' })}
      emptyText={i18next.t('datagrid.noData', { ns: 'common' })}
      livePagination
      columnUserSelect={true}
      scrollThreshold={0.7}
      pagination
    />
  );
};

export default DatagridFeature;
