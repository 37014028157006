import { Family, ProductCategory } from '../../../../../../enum/product.ts';
import { Text } from '@anatoscope/circlestorybook';
import i18next from 'i18next';
import { ColorPropsEnum } from '../../../../../../enum/color.ts';
import { MappedProducts } from '../../../../../../features/order-manager/products-manager.tsx';
import { CreationStep, PlanSelectionOptions } from '../../../../../../models/order.tsx';
import { ordersActions } from '../../../../../../store/orders/orders.reducer.tsx';
import { useAppDispatch } from '../../../../../../store/hooks.tsx';
import styles from './treatment-category-menu.module.scss';

/**
 * Treatment categories menu when the user selects a family.
 * @param categoriesObjectEnum
 * Enum of categories.
 * @param stepStatus
 * @param selectedOptions
 *   User selections (family, category, product) to add styles on bubbles
 * @param mappedProducts
 *   All products activated for the user lab.
 * @param stepStatus
 *   The status (active, valid or disabled) of the current step (reference, plan, custom...)
 * @param isValidCurrentItemCallback
 *   Check if a current item exists and respect the rules defined by the lab. If not, the user should finish his adding
 *   before changing his selection.
 * @param addMultiRangeCallback
 *   If the current item is valid and a multi-range product, we assume that the user have finished his adding.
 * @constructor
 */
const TreatmentCategoryMenu = ({
  categoriesObjectEnum,
  mappedProducts,
  selectedOptions,
  stepStatus,
  isValidCurrentItemCallback,
  addMultiRangeCallback
}: {
  categoriesObjectEnum: { [key in keyof typeof ProductCategory]: ProductCategory };
  mappedProducts: MappedProducts;
  selectedOptions: PlanSelectionOptions;
  stepStatus: CreationStep;
  isValidCurrentItemCallback: () => boolean;
  addMultiRangeCallback: () => void;
}) => {
  const dispatch = useAppDispatch();

  /**
   * Dispatch the new category selected and reset the product selected.
   * @param category
   */
  const handleClickCategoryButton = (category: ProductCategory): void => {
    const isValidCurrentItem = isValidCurrentItemCallback();
    if (!stepStatus.isDisabled && isValidCurrentItem) {
      addMultiRangeCallback();
      dispatch(
        ordersActions.setStepPlanSelections({
          selections: { category, product: undefined }
        })
      );
    }
  };

  const mappedProductForSelectedFamily = mappedProducts[selectedOptions.family as Family];
  const categories = Object.values(categoriesObjectEnum)?.map((category: ProductCategory) => {
    return (
      mappedProductForSelectedFamily &&
      Object.keys(mappedProductForSelectedFamily).includes(category) && (
        <button
          data-cy="planStepProductsCategory"
          data-cy-selected={selectedOptions.category && selectedOptions.category === category}
          type="button"
          key={`${selectedOptions.family}-${category}`}
          onClick={() => handleClickCategoryButton(category)}
          disabled={!stepStatus.isActive || stepStatus.isDisabled}>
          <Text
            label={i18next.t(`categories.${category}`, { ns: 'catalog' })}
            color={ColorPropsEnum.WHITE}
            className={[
              styles['treatment-category-menu--categories--item'],
              selectedOptions.category && selectedOptions.category === category
                ? styles['treatment-category-menu--categories--item--selected']
                : ''
            ].join(' ')}
          />
        </button>
      )
    );
  });

  return <div className={styles['treatment-category-menu--categories']}>{categories ?? ''}</div>;
};

export default TreatmentCategoryMenu;
