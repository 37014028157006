import styles from './navigation-bar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faDroplet,
  faHashtag,
  faPaperclip,
  faTooth
} from '@fortawesome/pro-light-svg-icons';
import {
  faDroplet as faDropletSolid,
  faPaperclip as faPaperSolid,
  faTooth as faToothSolid
} from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import {
  BreadcrumbItem,
  BreadcrumbOrder,
  BreadcrumbOrderItem
} from '../../../../../models/navigation.tsx';
import {
  selectEditionStepIsActive,
  selectEditionStepIsDisabled,
  selectEditionStepIsValid,
  selectOrderNumberValue,
  selectReferenceValue
} from '../../../../../store/orders/orders.selectors.tsx';
import { OrderTreatmentStep } from '../../../../../enum/order.ts';
import { Divider } from '@anatoscope/circlestorybook';
import { useNavigate } from 'react-router-dom';
import { goToDisabledTargetStep, goToValidTargetStep } from '../../navigation.tsx';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks.tsx';
import { useGetOneOrderQuery } from '../../../../../services/orders-api.services.ts';
import { useEffect, useState } from 'react';
import { isNotSubmittingOrder } from '../../../../../utils/order.utils.ts';
import { ColorPropsEnum } from '../../../../../enum/color.ts';

const NavigationBar = () => {
  const { t } = useTranslation(['treatment']);
  const referencePatient: string | undefined = useAppSelector(selectReferenceValue);
  const orderNumber: string | undefined = useAppSelector(selectOrderNumberValue);
  const [skip, setSkip] = useState(!orderNumber);
  const { data: order } = useGetOneOrderQuery(orderNumber as string, {
    skip
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const stepTreatment: BreadcrumbOrder = {
    [OrderTreatmentStep.PLAN]: {
      id: OrderTreatmentStep.PLAN,
      label: t('treatment.steps.plan'),
      iconLight: faTooth,
      iconSolid: faToothSolid,
      active: useAppSelector((state) => selectEditionStepIsActive(state, OrderTreatmentStep.PLAN)),
      valid: useAppSelector((state) => selectEditionStepIsValid(state, OrderTreatmentStep.PLAN)),
      disable: useAppSelector((state) =>
        selectEditionStepIsDisabled(state, OrderTreatmentStep.PLAN)
      )
    },
    [OrderTreatmentStep.CUSTOM]: {
      id: OrderTreatmentStep.CUSTOM,
      label: t('treatment.steps.custom'),
      iconLight: faDroplet,
      iconSolid: faDropletSolid,
      active: useAppSelector((state) =>
        selectEditionStepIsActive(state, OrderTreatmentStep.CUSTOM)
      ),
      valid: useAppSelector((state) => selectEditionStepIsValid(state, OrderTreatmentStep.CUSTOM)),
      disable: useAppSelector((state) =>
        selectEditionStepIsDisabled(state, OrderTreatmentStep.CUSTOM)
      )
    },
    [OrderTreatmentStep.SCAN]: {
      id: OrderTreatmentStep.SCAN,
      label: t('treatment.steps.scan'),
      iconLight: faPaperclip,
      iconSolid: faPaperSolid,
      active: useAppSelector((state) => selectEditionStepIsActive(state, OrderTreatmentStep.SCAN)),
      valid: useAppSelector((state) => selectEditionStepIsValid(state, OrderTreatmentStep.SCAN)),
      disable: useAppSelector((state) =>
        selectEditionStepIsDisabled(state, OrderTreatmentStep.SCAN)
      )
    }
  };

  const stepReference: BreadcrumbItem = {
    id: OrderTreatmentStep.REFERENCE,
    label: t('treatment.referencePatient'),
    active: useAppSelector((state) =>
      selectEditionStepIsActive(state, OrderTreatmentStep.REFERENCE)
    ),
    valid: useAppSelector((state) => selectEditionStepIsValid(state, OrderTreatmentStep.REFERENCE)),
    disable: useAppSelector((state) =>
      selectEditionStepIsDisabled(state, OrderTreatmentStep.REFERENCE)
    )
  };

  const stepSubmit: BreadcrumbOrderItem = {
    id: OrderTreatmentStep.SUBMIT,
    iconLight: faCheck,
    iconSolid: faCheck,
    label: t('treatment.actions.submit'),
    active: useAppSelector((state) => selectEditionStepIsActive(state, OrderTreatmentStep.SUBMIT)),
    valid: useAppSelector((state) => selectEditionStepIsValid(state, OrderTreatmentStep.SUBMIT)),
    disable: useAppSelector((state) =>
      selectEditionStepIsDisabled(state, OrderTreatmentStep.SUBMIT)
    )
  };

  useEffect(() => {
    setSkip(!orderNumber);
  }, [orderNumber]);

  return (
    <div
      data-cy="treatmentNavigationBar"
      className={[
        styles['navigation-bar'],
        !referencePatient && !orderNumber ? styles['navigation-bar__init-state'] : ''
      ].join(' ')}>
      <button
        data-cy="treatmentNavigationBarReference"
        data-cy-active={stepReference.active}
        data-cy-valid={stepReference.valid}
        data-cy-disabled={stepReference.disable}
        onClick={() => {
          if (order && isNotSubmittingOrder(order)) {
            goToDisabledTargetStep(navigate, dispatch, OrderTreatmentStep.REFERENCE, orderNumber);
          } else {
            goToValidTargetStep(
              navigate,
              dispatch,
              {
                isActive: stepReference.active,
                isValid: stepReference.valid
              },
              OrderTreatmentStep.REFERENCE,
              orderNumber
            );
          }
        }}
        className={[
          styles['navigation-bar__item'],
          stepReference.active ? styles['navigation-bar__item-active'] : '',
          stepReference.valid ? styles['navigation-bar__item-validated'] : '',
          stepReference.disable ? styles['navigation-bar__item-disabled'] : '',
          order && isNotSubmittingOrder(order) ? styles['navigation-bar__item-navigable'] : ''
        ].join(' ')}>
        <span
          className={[
            styles['navigation-bar__item__check'],
            styles['navigation-bar__item__reference__check']
          ].join(' ')}></span>
        <div
          className={[
            styles['navigation-bar__item__content'],
            styles['navigation-bar__reference']
          ].join(' ')}>
          {referencePatient && (
            <div className={styles['navigation-bar__reference__reference-client']}>
              <span
                data-cy="treatmentNavigationBarReferenceText"
                className={styles['navigation-bar__reference__reference-client__content']}>
                {referencePatient}
              </span>
            </div>
          )}
          {orderNumber && (
            <>
              <div className={styles['navigation-bar__reference__separator']}>
                <Divider direction="vertical" />
              </div>
              <div
                data-cy="treatmentNavigationBarReferenceOrderNumber"
                className={styles['navigation-bar__reference__order-number']}>
                {orderNumber}
              </div>
            </>
          )}
          {!referencePatient && !orderNumber && (
            <FontAwesomeIcon icon={faHashtag} size={'xl'} color={ColorPropsEnum.WHITE} />
          )}
        </div>
        <span className={styles['navigation-bar__item__title']}>{stepReference.label}</span>
      </button>
      {Object.values(stepTreatment).map((step: BreadcrumbOrderItem) => {
        return (
          <button
            type="button"
            data-cy={`treatmentNavigationBar${step.id}`}
            data-cy-active={step.active}
            data-cy-valid={step.valid}
            data-cy-disabled={step.disable}
            key={`step-${step.id}`}
            onClick={() => {
              if (order && isNotSubmittingOrder(order)) {
                goToDisabledTargetStep(
                  navigate,
                  dispatch,
                  step.id as OrderTreatmentStep,
                  orderNumber
                );
              } else {
                goToValidTargetStep(
                  navigate,
                  dispatch,
                  {
                    isActive: step.active,
                    isValid: step.valid
                  },
                  step.id as OrderTreatmentStep,
                  orderNumber
                );
              }
            }}
            className={[
              styles['navigation-bar__item'],
              step.active ? styles['navigation-bar__item-active'] : '',
              step.valid ? styles['navigation-bar__item-validated'] : '',
              step.disable ? styles['navigation-bar__item-disabled'] : '',
              order && isNotSubmittingOrder(order) ? styles['navigation-bar__item-navigable'] : ''
            ].join(' ')}>
            <span className={styles['navigation-bar__item__check']}></span>
            <div
              className={[
                styles['navigation-bar__item__content'],
                styles['navigation-bar__plan']
              ].join(' ')}>
              <FontAwesomeIcon
                icon={step.active ? step.iconSolid : step.iconLight}
                size={'xl'}
                color={ColorPropsEnum.WHITE}
              />
            </div>
            <span className={styles['navigation-bar__item__title']}>{step.label}</span>
          </button>
        );
      })}
      <div
        data-cy="treatmentNavigationBarSubmit"
        data-cy-active={stepSubmit.active}
        data-cy-valid={stepSubmit.valid}
        data-cy-disabled={stepSubmit.disable}
        className={[
          styles['navigation-bar__item'],
          stepSubmit.active ? styles['navigation-bar__item-active'] : '',
          stepSubmit.valid ? styles['navigation-bar__item-validated'] : '',
          stepSubmit.disable ? styles['navigation-bar__item-disabled'] : ''
        ].join(' ')}>
        <span
          className={[
            styles['navigation-bar__item__check'],
            styles['navigation-bar__item__submit__check']
          ].join(' ')}></span>
        <div
          className={[
            styles['navigation-bar__item__content'],
            styles['navigation-bar__submit']
          ].join(' ')}>
          <FontAwesomeIcon icon={stepSubmit.iconLight} size={'xl'} color={ColorPropsEnum.WHITE} />
        </div>
        <span className={styles['navigation-bar__item__title']}>{stepSubmit.label}</span>
      </div>
    </div>
  );
};
export default NavigationBar;
