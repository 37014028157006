import { Outlet } from 'react-router-dom';
import { Toast } from '@anatoscope/circlestorybook';
import './public-app.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { toastSelector } from '../../store/feedback/feedback.selector';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import { useTranslation } from 'react-i18next';

const PublicApp = () => {
  const { t } = useTranslation(['common']);
  const toast = useAppSelector(toastSelector);
  const dispatch = useAppDispatch();

  return (
    <div className="public-app" data-cy="publicApp">
      {toast && (
        <Toast
          data-cy={`toast-${toast.type}`}
          message={'message' in toast ? toast.message : t('generic', { ns: 'error' })}
          onClose={() => {
            dispatch(feedbackActions.resetToast());
          }}
          autoHideDuration={8000}
          type={toast.type}
        />
      )}
      <main className="public-app__main">
        <Outlet />
      </main>
    </div>
  );
};
export default PublicApp;
