import { createSelector } from '@reduxjs/toolkit';
import { AuthenticationReducer, UserInfos } from '../../models/user';
import { userApi } from '../../services/user.api.services';

interface ReducerState {
  authenticationState: AuthenticationReducer;
}

const connectedUserSelector = (state: ReducerState) => state.authenticationState.connectedUser;

const isAuthenticatedSelector = (state: ReducerState) => state.authenticationState.isAuthenticated;

const authenticatedUserResultSelector = userApi.endpoints.getUserInfo.select();

const authenticatedUserSelector = createSelector(
  authenticatedUserResultSelector,
  (userResult) => userResult?.data ?? ({} as UserInfos)
);

export { connectedUserSelector, isAuthenticatedSelector, authenticatedUserSelector };
