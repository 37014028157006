import styles from './not-found-page.module.scss';
import { Text, Link, Box } from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation(['notFound']);
  return (
    <Box radius="large">
      <div className={styles['not-found-page__main']}>
        <div className={styles['not-found-page__main__image']}></div>
        <div className={styles['not-found-page__main__text']}>
          <Text bold label={t('title', { ns: 'notFound' })} size="s" type="title" />
          <Text label={t('text', { ns: 'notFound' })} />
          <Link
            label={t('link', { ns: 'notFound' })}
            href="/"
            className={styles['not-found-page__links__column__item']}
          />
        </div>
      </div>
    </Box>
  );
};

export default NotFoundPage;
