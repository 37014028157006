import {
  OrderItemComponentForCreation,
  OrderItemComponentLight,
  OrderItemForCreation,
  OrderItemLight
} from '../../../../models/order.tsx';
import TooltipDetails from './tooltip-details/TooltipDetails.tsx';
import { MapContext, ProductBubble, productBubbleSkeleton } from '../../../../models/map.tsx';
import { ColorPropsEnum } from '../../../../enum/color.ts';
import { PositionKeyString, SelectionContextEnum, ToolEnum } from '../../../../enum/map.enum.ts';
import { mapActions } from '../../../../store/map/map.reducer.tsx';
import { OrderTreatmentStep } from '../../../../enum/order.ts';
import {
  GingivaShadeEnum,
  MaterialEnum,
  PositionKey,
  ToothShadeEnum
} from '../../../../enum/component.ts';
import { ordersActions } from '../../../../store/orders/orders.reducer.tsx';
import { Dispatch } from '@reduxjs/toolkit';
import { getFamilyColor } from '../../../../features/order-manager/teeth-map/utils.ts';

// Copy and paste from lab except the bubble function.

/**
 * Create and patch item to order.
 * @param orderNumber
 * @param orderItemsToCreate
 * @param creationCallback
 */
const createOrderItems = async (
  orderNumber: string,
  orderItemsToCreate: OrderItemLight[],
  creationCallback: ({
    orderNumber,
    item
  }: {
    orderNumber: string;
    item: OrderItemForCreation;
  }) => void
): Promise<void> => {
  if (orderNumber && orderItemsToCreate?.length) {
    for (const orderItem of orderItemsToCreate) {
      const item: OrderItemForCreation = {
        ...orderItem,
        product: {
          id: orderItem.product.id
        },
        tags: [],
        itemComponents: orderItem?.itemComponents?.map((item: OrderItemComponentLight) => {
          const itemToCreate: OrderItemComponentForCreation = {
            componentType: item.componentType
          };
          if (item.material?.id) {
            itemToCreate.material = { id: item.material?.id };
          } else if (item.materials?.[0].id) {
            itemToCreate.material = { id: item.materials[0].id };
          }

          if (item?.shades?.[0].id) {
            itemToCreate.shade = { id: item.shades[0].id };
          }

          if (item?.shapes?.[0].id) {
            itemToCreate.shape = { id: item.shapes[0].id };
          }

          if (item?.aspects?.[0].id) {
            itemToCreate.aspect = { id: item.aspects[0].id };
          }

          if (item.teethPositions?.length) {
            itemToCreate.teethPositions = item.teethPositions;
          }

          if (item.injectionPositions) {
            itemToCreate.injectionPositions = item.injectionPositions;
          }
          if (item.stumpPositions?.length) {
            itemToCreate.stumpPositions = item.stumpPositions;
          }

          if (item.toothStratificationTechnique) {
            itemToCreate.toothStratificationTechnique = item.toothStratificationTechnique;
          }
          return itemToCreate;
        })
      };

      await creationCallback({ orderNumber, item });
    }
  }
};

/**
 * Create with a skeleton, the bubble picto with product detail tooltip.
 *
 * @param currentItem
 *   Current selected item.
 * @param toothPosition
 *   Position tooth concerned by the bubble.
 */
const createBubblePictoDetailStructureObj = (
  currentItem: OrderItemLight,
  toothPosition: PositionKeyString
): ProductBubble => {
  return {
    ...productBubbleSkeleton,
    ...{
      url: currentItem.product.imageUrl,
      color: `family-${currentItem.product.family.toLowerCase()}` as ColorPropsEnum,
      tooltip: {
        position: 'right',
        isClickable: true,
        children: (
          <TooltipDetails bubbleItem={currentItem} toothPosition={+toothPosition as PositionKey} />
        )
      }
    }
  } as ProductBubble;
};

/**
 * Manage all single product
 *
 * @param selectedTooth
 *   Position selected
 * @param activeComponent
 *   Active Component
 * @param mapContext
 *   Map Context from redux
 * @param currentItem
 *   Current selected Item
 * @param dispatch
 *   Dispatch
 */
const handleSelectTeethSingleMode = (
  selectedTooth: PositionKeyString,
  activeComponent: {
    activeShade: string | undefined;
    activeGingiva: string | undefined;
    activeFrameMaterial: string | undefined;
  },
  mapContext: MapContext,
  currentItem: OrderItemLight,
  dispatch: Dispatch
) => {
  // 1. click occurred on tooth map && context is not defined => it's the first click
  if (!mapContext.userAction) {
    dispatch(
      mapActions.setProductBubbleOnTooth({
        position: selectedTooth,
        bubbles: {
          [OrderTreatmentStep.PLAN]: createBubblePictoDetailStructureObj(currentItem, selectedTooth)
        }
      })
    );

    dispatch(
      mapActions.startSingleRange({
        notation: selectedTooth,
        teethShade: activeComponent.activeShade as ToothShadeEnum,
        gingivaShade: activeComponent.activeGingiva as GingivaShadeEnum,
        frameMaterial: activeComponent.activeFrameMaterial as MaterialEnum,
        familyColor: getFamilyColor(currentItem.product.family)
      })
    );
  }
  // 2. click occurred on tooth map && context is range-started => it's the second click
  if (mapContext?.userAction === SelectionContextEnum.RANGE_STARTED) {
    dispatch(
      mapActions.closeSingleRange({
        notation: selectedTooth,
        teethShade: activeComponent.activeShade as ToothShadeEnum,
        gingivaShade: activeComponent.activeGingiva as GingivaShadeEnum,
        frameMaterial: activeComponent.activeFrameMaterial as MaterialEnum,
        familyColor: getFamilyColor(currentItem.product.family)
      })
    );
    if (!currentItem?.product?.productRule?.allowSaneTeethInArch) {
      dispatch(mapActions.removeSaneTeeth(selectedTooth));
    }
  }
};

/**
 * Manage all multi product
 *
 * @param selectedTooth
 *   Position selected
 * @param activeComponent
 *   Active Component
 * @param mapContext
 *   Map Context from redux
 * @param currentItem
 *   Current selected Item
 * @param dispatch
 *   Dispatch
 */
const handleSelectTeethMultiMode = (
  selectedTooth: PositionKeyString,
  activeComponent: {
    activeShade: string | undefined;
    activeGingiva: string | undefined;
    activeFrameMaterial: string | undefined;
  },
  mapContext: MapContext,
  currentItem: OrderItemLight,
  dispatch: Dispatch
) => {
  // 1. click occured on tooth map && context is not defined (first click) or context is zone-ended => it's the first click - start new zone
  if (!mapContext.userAction || mapContext?.userAction === SelectionContextEnum.ZONE_ENDED) {
    // Very first click for the multi-range.
    if (!mapContext.start) {
      dispatch(
        mapActions.setProductBubbleOnTooth({
          position: selectedTooth,
          bubbles: {
            [OrderTreatmentStep.PLAN]: createBubblePictoDetailStructureObj(
              currentItem,
              selectedTooth
            )
          }
        })
      );
    }

    dispatch(
      mapActions.startZone({
        notation: selectedTooth,
        teethShade: activeComponent.activeShade as ToothShadeEnum,
        gingivaShade: activeComponent.activeGingiva as GingivaShadeEnum,
        familyColor: getFamilyColor(currentItem.product.family),
        frameMaterial: activeComponent.activeFrameMaterial as MaterialEnum
      })
    );
  }

  // 2. click occured on tooth map && context is zone-started => it's the second click - end zone
  if (mapContext?.userAction === SelectionContextEnum.ZONE_STARTED) {
    dispatch(
      mapActions.endZone({
        notation: selectedTooth,
        teethShade: activeComponent.activeShade as ToothShadeEnum,
        gingivaShade: activeComponent.activeGingiva as GingivaShadeEnum,
        familyColor: getFamilyColor(currentItem.product.family),
        frameMaterial: activeComponent.activeFrameMaterial as MaterialEnum
      })
    );
  }

  // Compute zone link positions to draw them
  dispatch(mapActions.computeZoneLinkPositions());

  dispatch(
    ordersActions.setValidCreationStepsStatus({
      step: OrderTreatmentStep.PLAN,
      isValid: true
    })
  );
};

/**
 * Dispatch the extract and missing tooth on redux.
 *
 * @param selectedTooth
 *   Position selected
 * @param selectedTeethMissingOrExtract
 *   Option selected
 * @param dispatch
 *   Dispatch
 */
const handleSelectTeethMissingOrExtract = (
  selectedTooth: PositionKeyString,
  selectedTeethMissingOrExtract: ToolEnum | undefined,
  dispatch: Dispatch
) => {
  if (selectedTeethMissingOrExtract === ToolEnum.EXTRACT) {
    dispatch(mapActions.toggleToothExtract(selectedTooth));
  }
  if (selectedTeethMissingOrExtract === ToolEnum.MISSING) {
    dispatch(mapActions.toggleToothMissing(selectedTooth));
  }
};

export {
  handleSelectTeethSingleMode,
  handleSelectTeethMultiMode,
  handleSelectTeethMissingOrExtract,
  createOrderItems
};
