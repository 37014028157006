import { createSelector } from '@reduxjs/toolkit';
import { MapReducer, Position, ProductIdForPosition, sortedPositionsArray } from '../../models/map';
import { PositionKey, ToothShadeEnum, ToothShapeEnum } from '../../enum/component';

import {
  CursorEnum,
  PositionKeyString,
  SelectionContextEnum,
  ToothSelectionEnum,
  ZoneLinkEnum
} from '../../enum/map.enum.ts';
import { ColorPropsEnum, FamilyColorEnum, SimpleColorsEnum } from '../../enum/color.ts';
import { TeethMode } from '../../enum/product.ts';
import { NotAllowedCursors } from '../../features/order-manager/teeth-map/cursors.utils';
import { RootState } from '../index.tsx';

interface ReducerState {
  mapState: MapReducer;
}

interface ComponentsForTeethMap {
  toothPositions: number[][];
  gingivaPositions: number[][];
  framePositions: number[][];
}

/**
 * Computes all array of values for components needed for the patientMouth in teethmap to work
 * In short , all position number must be sorted in array by productId
 *
 * @param {Position[]} positions
 * @returns {ComponentsForTeethMap}
 */
const computeComponentsForTeethMapByProduct = (positions: {
  [key: string]: Position;
}): ComponentsForTeethMap => {
  const toothPositions: { [key: string]: Array<number> } = {};
  const gingivaPositions: { [key: string]: Array<number> } = {};
  const framePositions: { [key: string]: Array<number> } = {};

  Object.values(positions).forEach((position) => {
    if (position.productIds.length > 0) {
      position.productIds.forEach((productIdForPosition: ProductIdForPosition) => {
        if (position?.notation) {
          toothPositions[productIdForPosition.uniqueProductId] = [
            ...(toothPositions[productIdForPosition.uniqueProductId]
              ? toothPositions[productIdForPosition.uniqueProductId]
              : []),
            +position.notation
          ];
          gingivaPositions[productIdForPosition.uniqueProductId] = [
            ...(gingivaPositions[productIdForPosition.uniqueProductId]
              ? gingivaPositions[productIdForPosition.uniqueProductId]
              : []),
            +position.notation
          ];
          if (position.frame) {
            framePositions[productIdForPosition.uniqueProductId] = [
              ...(framePositions[productIdForPosition.uniqueProductId]
                ? framePositions[productIdForPosition.uniqueProductId]
                : []),
              +position.notation
            ];
          }
        }
      });
    } else if (ToothSelectionEnum.SELECTED === position.selection && position?.notation) {
      toothPositions['-1'] = [
        ...(toothPositions['-1'] ? toothPositions['-1'] : []),
        +position.notation
      ];
      gingivaPositions['-1'] = [
        ...(gingivaPositions['-1'] ? gingivaPositions['-1'] : []),
        +position.notation
      ];
      if (position.frame) {
        framePositions['-1'] = [
          ...(framePositions['-1'] ? framePositions['-1'] : []),
          +position.notation
        ];
      }
    }
  });

  return {
    toothPositions: Object.keys(toothPositions).map((productId) => toothPositions[productId]),
    gingivaPositions: Object.keys(gingivaPositions).map((productId) => gingivaPositions[productId]),
    framePositions: Object.keys(framePositions).map((productId) => framePositions[productId])
  };
};

export const mapSelector = (state: ReducerState) => {
  return state?.mapState?.positions;
};

export const mapContextSelector = (state: ReducerState) => {
  return state?.mapState?.mapContext;
};

export const mapStepBubblesSelector = createSelector(
  [(state: RootState) => state?.mapState?.positions, (_state: RootState, step: string) => step],
  (positions: { [key: string]: Position }, step: string) => {
    return Object.entries(positions).reduce((acc, [key, position]) => {
      acc = position?.bubbles ? { ...acc, [key]: position.bubbles?.[step] } : acc;
      return acc;
    }, {});
  }
);

// Teeth, shades, gingiva, ...
export const mapComponentsSelector = createSelector([mapSelector], (positions) => {
  const componentForTeethMap = computeComponentsForTeethMapByProduct(positions);
  return {
    EXTRACT: Object.values(positions)
      .map((position) => (position.extract && position.notation ? +position.notation : undefined))
      .filter((element) => element !== undefined),
    MISSING: Object.values(positions)
      .map((position) => (position.missing && position.notation ? +position.notation : undefined))
      .filter((element) => element !== undefined),
    TOOTH: componentForTeethMap.toothPositions,
    GINGIVA: componentForTeethMap.gingivaPositions,
    FRAME: componentForTeethMap.framePositions,
    SHADES: {
      // TODO: come on! you can do better
      a1: Object.values(positions)
        .filter((position) => ToothShadeEnum.A1 === position.teethShade)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      a2: Object.values(positions)
        .filter((position) => ToothShadeEnum.A2 === position.teethShade)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      a3: Object.values(positions)
        .filter((position) => ToothShadeEnum.A3 === position.teethShade)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      a3_5: Object.values(positions)
        .filter((position) => ToothShadeEnum.A3_5 === position.teethShade)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      a4: Object.values(positions)
        .filter((position) => ToothShadeEnum.A4 === position.teethShade)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      b1: Object.values(positions)
        .filter((position) => ToothShadeEnum.B1 === position.teethShade)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      b2: Object.values(positions)
        .filter((position) => ToothShadeEnum.B2 === position.teethShade)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      b3: Object.values(positions)
        .filter((position) => ToothShadeEnum.B3 === position.teethShade)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      b4: Object.values(positions)
        .filter((position) => ToothShadeEnum.B4 === position.teethShade)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      c1: Object.values(positions)
        .filter((position) => ToothShadeEnum.C1 === position.teethShade)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      c2: Object.values(positions)
        .filter((position) => ToothShadeEnum.C2 === position.teethShade)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      c3: Object.values(positions)
        .filter((position) => ToothShadeEnum.C3 === position.teethShade)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      c4: Object.values(positions)
        .filter((position) => ToothShadeEnum.C4 === position.teethShade)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      d2: Object.values(positions)
        .filter((position) => ToothShadeEnum.D2 === position.teethShade)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      d3: Object.values(positions)
        .filter((position) => ToothShadeEnum.D3 === position.teethShade)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      d4: Object.values(positions)
        .filter((position) => ToothShadeEnum.D4 === position.teethShade)
        .map((position) => (position?.notation ? +position.notation : undefined))
    },
    SHAPES: {
      triangular: Object.values(positions)
        .filter((position) => ToothShapeEnum.TRIANGULAR === position.shape)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      oval: Object.values(positions)
        .filter((position) => ToothShapeEnum.OVAL === position.shape)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      round_triangular: Object.values(positions)
        .filter((position) => ToothShapeEnum.ROUND_TRIANGULAR === position.shape)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      round: Object.values(positions)
        .filter((position) => ToothShapeEnum.ROUND === position.shape)
        .map((position) => (position?.notation ? +position.notation : undefined)),
      square: Object.values(positions)
        .filter((position) => ToothShapeEnum.SQUARE === position.shape)
        .map((position) => (position?.notation ? +position.notation : undefined))
    }
  };
});

export const activeItemSelector = createSelector([mapSelector], (positions) => {
  return {
    TOOTH: Object.values(positions)
      .map((position) =>
        position?.notation && ToothSelectionEnum.SELECTED === position.selection
          ? +position?.notation
          : undefined
      )
      .filter((element) => element !== undefined),
    GINGIVA: Object.values(positions).find(
      (position) => ToothSelectionEnum.SELECTED === position.selection
    )?.gingivaShade,
    SHADE: Object.values(positions).find(
      (position) => ToothSelectionEnum.SELECTED === position.selection
    )?.teethShade,
    SHAPE: Object.values(positions).find(
      (position) => ToothSelectionEnum.SELECTED === position.selection
    )?.shape
  };
});

export const cursorsSelector = createSelector(
  [mapSelector, mapContextSelector],
  (positions, mapContext) => {
    const cursors: { [key: number]: CursorEnum } = { ...NotAllowedCursors };
    Object.keys(positions).forEach((position) => {
      switch (positions[position].selection) {
        case ToothSelectionEnum.UNSELECTABLE:
          cursors[+position] = CursorEnum.NOT_ALLOWED;
          break;
        case ToothSelectionEnum.SELECTABLE:
          if (mapContext?.teethMode === TeethMode.SINGLE_TOOTH) {
            cursors[+position] = CursorEnum.POINTER;
          } else if (
            !mapContext?.userAction ||
            mapContext?.userAction === SelectionContextEnum.RANGE_ENDED ||
            mapContext?.userAction === SelectionContextEnum.ZONE_ENDED
          ) {
            cursors[+position] = CursorEnum.START;
          } else if (
            mapContext?.userAction === SelectionContextEnum.RANGE_STARTED ||
            mapContext?.userAction === SelectionContextEnum.ZONE_STARTED
          ) {
            cursors[+position] = CursorEnum.END;
          }
          break;
        case ToothSelectionEnum.SELECTED:
          if (
            mapContext?.userAction === SelectionContextEnum.ZONE_STARTED &&
            position === mapContext.start
          ) {
            cursors[+position] = CursorEnum.END;
          }
          break;
      }
    });

    return cursors;
  }
);

export const zoneLinkPropsSelector = createSelector([mapSelector], (positions) => {
  let startZone: PositionKeyString | null = null;
  let endZone: PositionKeyString | null = null;
  let nextStartZone: PositionKeyString | null = null;
  const zoneLinkProps: Array<{
    startPosition: PositionKey;
    endPosition: PositionKey;
    color: SimpleColorsEnum.GREY | SimpleColorsEnum.PINK;
  }> = [];

  sortedPositionsArray.forEach((position: PositionKeyString) => {
    switch (positions[position]?.zone_link) {
      case ZoneLinkEnum.START:
        startZone = position;
        break;
      case ZoneLinkEnum.END:
        endZone = position;
        break;
      case ZoneLinkEnum.END_START:
        if (startZone) {
          endZone = position;
          nextStartZone = position;
        }
        break;
    }

    // We have a start and end position for our zone link, we can save it for display
    if (startZone && endZone) {
      zoneLinkProps.push({
        startPosition: +startZone as PositionKey,
        endPosition: +endZone as PositionKey,
        color: ColorPropsEnum.GREY // TODO handle color according to Definitive/Provisional product
      });
      startZone = nextStartZone ?? null;
      endZone = null;
    }
  });
  return zoneLinkProps;
});

export const lineAndNumberColorsSelector = createSelector([mapSelector], (positions) => {
  return {
    'family-remov': Object.values(positions)
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_REMOV)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    'family-fixed': Object.values(positions)
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_FIXED)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    'family-implant': Object.values(positions)
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_IMPLANT)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    'family-guards': Object.values(positions)
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_GUARDS)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    'family-ocr': Object.values(positions)
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_OCR)
      .map((position) => (position?.notation ? +position.notation : undefined))
  };
});
