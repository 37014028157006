import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importing translation files
import commonFR from './translations/fr/common.json';
import commonEN from './translations/en/common.json';
import authEN from './translations/en/auth.json';
import authFR from './translations/fr/auth.json';
import errorFR from './translations/fr/error.json';
import errorEN from './translations/en/error.json';
import notFoundFR from './translations/fr/page_not-found.json';
import notFoundEN from './translations/en/page_not-found.json';
import dashboardFR from './translations/fr/page_dashboard.json';
import dashboardEN from './translations/en/page_dashboard.json';
import catalogFR from './translations/fr/catalog.json';
import catalogEN from './translations/en/catalog.json';
import hotlineFR from './translations/fr/hotline.json';
import hotlineEN from './translations/en/hotline.json';
import profileFR from './translations/fr/profile.json';
import profileEN from './translations/en/profile.json';
import treatmentFR from './translations/fr/page-treatment.json';
import treatmentEN from './translations/en/page-treatment.json';
import componentEN from './translations/en/component.json';
import componentFR from './translations/fr/component.json';

//Creating object with the variables of imported translation files
const resources = {
  fr: {
    common: commonFR,
    auth: authFR,
    error: errorFR,
    notFound: notFoundFR,
    dashboard: dashboardFR,
    catalog: catalogFR,
    hotline: hotlineFR,
    profile: profileFR,
    treatment: treatmentFR,
    component: componentFR
  },
  en: {
    common: commonEN,
    auth: authEN,
    notFound: notFoundEN,
    error: errorEN,
    dashboard: dashboardEN,
    catalog: catalogEN,
    hotline: hotlineEN,
    profile: profileEN,
    treatment: treatmentEN,
    component: componentEN
  }
};

//i18N Initialization

i18n.use(initReactI18next).init({
  resources,
  lng: 'fr', //default language
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
