import { OrderForList, OrderItem } from '../../../models/order';
import styles from './dashboard-page.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/pro-light-svg-icons';
import { ColorEnum, ColorPropsEnum } from '../../../enum/color';
import { BubblePicto, Text, Tooltip, TooltipContent } from '@anatoscope/circlestorybook';
import { OrderStepEnum } from '../../../enum/order';
import OrderSteps from './order-steps/OrderSteps';
import { getLocalizedProperty } from '../../../utils/utils.tsx';

interface RenderArg {
  value: string;
  data: OrderForList;
}

interface RenderArgProducts {
  value: OrderItem[];
}

interface RenderArgStep {
  value: OrderStepEnum;
  data: OrderForList;
}

export const dashboardColumns = [
  {
    name: 'patientReference',
    header: 'patientReference',
    width: 150,
    cellDOMProps: {
      style: {
        background: ColorEnum.PURPLE_MEDIUM,
        borderTopLeftRadius: '20px',
        borderBottomLeftRadius: '20px'
      }
    },
    render: ({ value }: RenderArg) => {
      return (
        <div className={styles['dashboard-page__datagrid__patient']}>
          <Text
            label={value}
            className={styles['dashboard-page__datagrid__patient__value']}
            color={ColorPropsEnum.WHITE}></Text>
          <span className={styles['dashboard-page__datagrid__patient__separator']}></span>
        </div>
      );
    }
  },
  {
    name: 'orderNumber',
    header: 'orderNumber',
    minWidth: 150,
    cellDOMProps: {
      style: {
        background: ColorEnum.PURPLE_MEDIUM
      }
    },
    render: ({ value }: RenderArg) => {
      return (
        <div className={styles['dashboard-page__datagrid__order-number']}>
          <span>{value}</span>
        </div>
      );
    }
  },
  {
    name: 'items',
    header: 'products',
    width: 65,
    cellDOMProps: {
      style: { color: ColorEnum.PURPLE_MEDIUM, background: ColorEnum.PURPLE_SUPER_LIGHT }
    },
    render: ({ value }: RenderArgProducts) => {
      const items = value?.map((item: OrderItem, index) => {
        if (item?.product) {
          const label = item.product[getLocalizedProperty('label')];
          return (
            <div
              key={'div' + index}
              className={styles['dashboard-page__datagrid__tooltip__product']}>
              {
                <BubblePicto
                  key={'picto' + index}
                  size="large"
                  url={item?.product.imageUrl}
                  mainTitle={label ?? undefined}
                  color={
                    item?.product?.family
                      ? ColorPropsEnum[
                          `FAMILY_${item.product.family.toUpperCase()}` as keyof typeof ColorPropsEnum
                        ]
                      : undefined
                  }
                />
              }
            </div>
          );
        }
      });
      return value?.length ? (
        <Tooltip isClickable variant={ColorPropsEnum.WHITE} position="right" maxWidthPx={400}>
          <TooltipContent>
            <div className={styles['dashboard-page__datagrid__tooltip']}>{items}</div>
          </TooltipContent>
          <BubblePicto
            isClickable
            url={value[0]?.product?.imageUrl}
            color={
              value[0]?.product?.family
                ? ColorPropsEnum[
                    `FAMILY_${value[0]?.product.family}` as keyof typeof ColorPropsEnum
                  ]
                : undefined
            }
            count={value?.length}
          />
        </Tooltip>
      ) : (
        <BubblePicto count={value?.length} />
      );
    }
  },
  {
    name: 'instructions',
    header: 'instructions',
    width: 65,
    cellDOMProps: {
      style: {
        background: ColorEnum.PURPLE_SUPER_LIGHT
      }
    },
    render: ({ value }: RenderArg) => {
      return value ? (
        <Tooltip isClickable variant={ColorPropsEnum.WHITE}>
          <TooltipContent>
            <Text label={value}></Text>
          </TooltipContent>
          <span
            className={[
              styles['dashboard-page__datagrid__instructions'],
              styles['dashboard-page__datagrid__instructions--filled']
            ].join(' ')}>
            <FontAwesomeIcon icon={faComment} size="2xl" />
          </span>
        </Tooltip>
      ) : (
        <span
          className={[
            styles['dashboard-page__datagrid__instructions'],
            styles['dashboard-page__datagrid__instructions--empty']
          ].join(' ')}>
          <FontAwesomeIcon icon={faComment} size="2xl" />
        </span>
      );
    }
  },
  {
    name: 'currentStep',
    header: 'currentStep',
    minWidth: 50,
    defaultFlex: 3,
    cellDOMProps: {
      style: { background: 'white' }
    },
    render: ({ value, data }: RenderArgStep) => {
      return (
        <OrderSteps
          className={styles['dashboard-page__datagrid__workflow-step']}
          currentStep={value}
          order={data}></OrderSteps>
      );
    }
  }
];
