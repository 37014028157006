import i18next from 'i18next';
import moment from 'moment';
import {
  ComponentType,
  DentalArchEnum,
  DentalArchEnumWithBoth,
  LowerPositions,
  UpperPositions
} from '../../../enum/component';
import { ToolEnum } from '../../../enum/map.enum';
import { MapComponents, notationToIndex } from '../../../models/map';
import { OrderItem, OrderItemComponent, OrderItemLight } from '../../../models/order';
import { DiagnosticCreation } from '../../../models/diagnostic';
import { FamilyColorEnum } from '../../../enum/color';
import { Family } from '../../../enum/product';

/**
 * Computes positions to select between two limits/nound
 *
 *
 * @param {string} bound1 - tooth notation
 * @param {string} bound2 - tooth notation
 * @returns {Array<string>} - an array of teeth notation to select
 */
export const computeRangeKeys = (bound1: string, bound2: string): Array<string> => {
  const min = Math.min(notationToIndex[bound1], notationToIndex[bound2]);
  const max = Math.max(notationToIndex[bound1], notationToIndex[bound2]);

  return Object.keys(notationToIndex).filter(
    (key) => notationToIndex[key] >= min && notationToIndex[key] <= max
  );
};

export const getRelevantComponentsForDisplay = (mapComponents: MapComponents) => {
  return {
    MISSING: mapComponents['MISSING'],
    EXTRACT: mapComponents['EXTRACT'],
    TOOTH: mapComponents['TOOTH'],
    GINGIVA: mapComponents['GINGIVA'],
    FRAME: mapComponents['FRAME']
  };
};

/*
 * Retrieves, if one, the desired component of an item
 *
 * @param {item} Item - The item to search in.
 * @param {type} string - The component type desired.
 * @returns {OrderItemComponent | undefined} The OrderItemComponent desired.
 */
export const getComponentInItemByType = (
  item: OrderItem,
  type: ComponentType
): OrderItemComponent | undefined => {
  return item.itemComponents?.find((component) => type === component.componentType);
};

/*
 * Returns if an item is either on the upper or lower arch.
 *
 * @param {item} Item - The item.
 * @returns {DentalArchEnumWithBoth} The DentalArchEnumWithBoth matching the item position.
 */
export const getItemDentalArch = (item: OrderItem): DentalArchEnumWithBoth => {
  const onLowerArch = LowerPositions.some((pos) =>
    item?.itemComponents?.[0].teethPositions?.includes(pos)
  );
  const onUpperArch = UpperPositions.some((pos) =>
    item?.itemComponents?.[0].teethPositions?.includes(pos)
  );

  if (onUpperArch && !onLowerArch) {
    return DentalArchEnumWithBoth.UPPER;
  }

  if (!onUpperArch && onLowerArch) {
    return DentalArchEnumWithBoth.LOWER;
  }

  return DentalArchEnumWithBoth.BOTH;
};

/**
 * Adds diagnostic information in the mouth object.
 *
 * @param {Object.<string, Array<number>>} mouth - The mouth object containing the dental information.
 * @param {DiagnosticCreation} diagnostic - The diagnostic object.
 */
export const addDiagnosticToMouth = (
  mouth: { [key: string]: Array<number> },
  diagnostic: DiagnosticCreation
) => {
  if (diagnostic) {
    mouth[ToolEnum.MISSING] = diagnostic.missingTeeth || [];
    mouth[ToolEnum.EXTRACT] = diagnostic.teethToBeExtracted || [];
  }
};

/**
 * Adds items information in the mouth object.
 *
 * @param {Object.<string, Array<Array<number>>>} mouth - The mouth object containing the dental information.
 * @param {Array} orderItems - The items to be added to the mouth.
 */
export const addItemsInMouth = (
  mouth: { [key: string]: Array<Array<number>> },
  orderItems: Array<OrderItemLight | OrderItem>
) => {
  if (orderItems?.length) {
    orderItems.forEach((item) => {
      item.itemComponents?.forEach((component) => {
        if (component?.teethPositions) {
          if (mouth[component.componentType]) {
            mouth[component.componentType].push(component.teethPositions);
          } else {
            mouth[component.componentType] = [component.teethPositions];
          }
        }
      });
    });
  }
};

/*
 * Return an object containing the parts of the given orderNumber, before and after the last dash.
 * It is useful to isolate the last part since it is what will mainly be used to identify an order.
 *
 * @param {orderNumber} string - The order number to split.
 * @returns {{ beforeLastPart: string, lastPart: string }} An object containing the parts of the order.
 */
export const splitOrderNumber = (
  orderNumber: string
): { beforeLastPart: string; lastPart: string } => {
  const lastIndex = orderNumber.lastIndexOf('-');

  if (lastIndex === -1) {
    return {
      beforeLastPart: orderNumber,
      lastPart: ''
    };
  }

  return {
    beforeLastPart: orderNumber.substring(0, lastIndex) + '-',
    lastPart: orderNumber.substring(lastIndex + 1)
  };
};

/*
 * Return a formatted and localized version of the date passed, if one.
 * If no valid format is passed, it formats as YYYY-MM-DD (en) or DD/MM/YYYY (fr).
 * If no date is passed, it returns the current date.
 *
 * @param {format} string - The format needed for the date.
 * @param {date} Date - The date to format.
 * @returns {string} The formatted date as a string.
 */
export const getFormattedLocalizedMomentDate = (format: string, date?: Date): string => {
  const validDateFormats = ['full', 'middle', 'small', 'withLitteralMonth'];
  const momentDate = date ? moment(date) : moment();
  let localizedFormat = i18next.t(`date.small`, { ns: 'common' });

  if (validDateFormats.includes(format)) {
    localizedFormat = i18next.t(`date.${format}`, { ns: 'common' });
  }

  return momentDate.format(localizedFormat);
};

/**
 *
 * @param {DentalArchEnum} arch
 * @returns {boolean}
 */
export const isUpperArch = (arch: DentalArchEnum): boolean => {
  return arch === DentalArchEnum.UPPER;
};

/**
 * Get family color depends on the family product
 * @param {Family} family
 * @returns {FamilyColorEnum}
 */
export const getFamilyColor = (family: Family): FamilyColorEnum => {
  switch (family) {
    case Family.FIXED:
      return FamilyColorEnum.FAMILY_FIXED;
    case Family.GUARDS:
      return FamilyColorEnum.FAMILY_GUARDS;
    case Family.REMOV:
      return FamilyColorEnum.FAMILY_REMOV;
    case Family.IMPLANT:
      return FamilyColorEnum.FAMILY_IMPLANT;
    case Family.OCCLUSION_RIMS:
      return FamilyColorEnum.FAMILY_OCR;
  }
};
