export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum SortDirectionNb {
  ASC_AS_NB = 1,
  DESC_AS_NB = -1
}

export enum FilterName {
  CURRENT_STEP = 'currentStep',
  FAMILY = 'family'
}

export enum DatagridName {
  TREATMENTS = 'treatments'
}
