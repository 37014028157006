import styles from './treatment-family-menu.module.scss';
import { Text, Loader } from '@anatoscope/circlestorybook';
import { ColorPropsEnum } from '../../../../../../enum/color.ts';
import { CreationStep, PlanSelectionOptions } from '../../../../../../models/order.tsx';
import ProductsManager, {
  MappedProducts
} from '../../../../../../features/order-manager/products-manager.tsx';
import { ordersActions } from '../../../../../../store/orders/orders.reducer.tsx';
import { Family, ProductCategory } from '../../../../../../enum/product.ts';
import { useAppDispatch } from '../../../../../../store/hooks.tsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

/**
 * Treatment family activated for the user lab.
 *
 * @param familiesObjectEnum
 *   Enum of Families.
 * @param productsManager
 *   Manager class to manage the list of families, categories and products for the current user.
 * @param submitCallback
 *   The callback when the user clicks on the valid button to go in the next step.
 * @param isLoading
 *   If the back requests are loading, show a loader. *
 * @param selectedOptions
 *   User selections (family, category, product) to add styles on bubbles
 * @param mappedProducts
 *   All products activated for the user lab.
 * @param stepStatus
 *   The status (active, valid or disabled) of the current step (reference, plan, custom...)
 * @param isValidCurrentItemCallback
 *   Check if a current item exists and respect the rules defined by the lab. If not, the user should finish his adding
 *   before changing his selection.
 * @param addMultiRangeCallback
 *   If the current item is valid and a multi-range product, we assume that the user have finished his adding.
 * @constructor
 */
const TreatmentFamilyMenu = ({
  mappedProducts,
  familiesObjectEnum,
  selectedOptions,
  stepStatus,
  productsManager,
  submitCallback,
  isLoading,
  isValidCurrentItemCallback,
  addMultiRangeCallback
}: {
  mappedProducts: MappedProducts | undefined;
  familiesObjectEnum: { [key in keyof typeof Family]: Family } | undefined;
  selectedOptions: PlanSelectionOptions | undefined;
  stepStatus: CreationStep;
  productsManager: ProductsManager;
  submitCallback: () => void;
  isLoading: boolean;
  isValidCurrentItemCallback: () => boolean;
  addMultiRangeCallback: () => void;
}) => {
  const { t } = useTranslation(['treatment']);
  const dispatch = useAppDispatch();

  /**
   * When we click on a family, we reset the category and the product selection and we dispatch the new family with the
   * new categories.
   * @param family
   */
  const handleClickFamilyButton = (family: Family): void => {
    const isValidCurrentItem = isValidCurrentItemCallback();
    if (!stepStatus.isDisabled && isValidCurrentItem) {
      addMultiRangeCallback();
      dispatch(ordersActions.resetPlanEdition());
      dispatch(
        ordersActions.setStepPlanSelections({
          selections: {
            family,
            category: productsManager.getFirstCategoryFromFamilyKey(family) as ProductCategory
          }
        })
      );
    }
  };

  const families =
    mappedProducts &&
    familiesObjectEnum &&
    Object.values(familiesObjectEnum)?.map((family: Family) => {
      return (
        Object.keys(mappedProducts).includes(family) && (
          <button
            data-cy="planStepFamily"
            data-cy-selected={selectedOptions?.family === family}
            type="button"
            className={[
              styles['treatment-family-menu--families--list--item'],
              selectedOptions?.family === family
                ? styles['treatment-family-menu--families--list--item--selected']
                : ''
            ].join(' ')}
            key={family}
            onClick={() => handleClickFamilyButton(family)}
            disabled={!stepStatus.isActive || stepStatus.isDisabled}>
            <span
              className={[
                styles['treatment-family-menu--families--list--item--family'],
                styles[
                  `treatment-family-menu--families--list--item--family--${family.toLowerCase()}`
                ]
              ].join(' ')}></span>
            <Text
              label={t(`families.${family}`, { ns: 'catalog' })}
              color={ColorPropsEnum.WHITE}
              size="s"
            />
          </button>
        )
      );
    });

  return (
    <div className={styles['treatment-family-menu--families']}>
      <Text
        data-cy="planStepFamilyLabel"
        label={t('treatment.menus.familyLabel')}
        size="l"
        color={ColorPropsEnum.WHITE}
        className={styles['treatment-family-menu--families--header']}
      />
      {!isLoading && (
        <div className={styles['treatment-family-menu--families--list']}>{families ?? ''}</div>
      )}
      {isLoading && (
        <div className={styles['treatment-family-menu--families--loading']}>
          <Loader color={ColorPropsEnum.WHITE} />
        </div>
      )}
      <button
        data-cy="planStepSubmit"
        type="button"
        className={styles['treatment-family-menu--families--list__submit']}
        disabled={stepStatus.isDisabled || !stepStatus.isValid}
        onClick={() => submitCallback()}>
        <FontAwesomeIcon icon={faCheck} size={'lg'} color={ColorPropsEnum.WHITE} />
      </button>
    </div>
  );
};
export default TreatmentFamilyMenu;
