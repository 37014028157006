import { Button, Dialog, SideBarModal, Box } from '@anatoscope/circlestorybook';
import styles from './team.module.scss';
import {
  useAddUserMutation,
  useDeactivateUserMutation,
  useGetUserInfoQuery,
  useLazyGetUsersQuery
} from '../../../../services/user.api.services';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { datagridSettingsActions } from '../../../../store/datagrids-settings/datagrids-settings.reducers.tsx';
import { usersDatagridStateSelector } from '../../../../store/datagrids-settings/datagrids-settings.selectors.tsx';
import { FilterProps, LoadDataProps } from '../../../../models/datagrid.tsx';
import { TypeSingleSortInfo } from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import DatagridFeature from '../../../../features/datagrid/DatagridFeature.tsx';
import {
  buildSort,
  buildUsersFilters,
  columnsDataUser,
  isAssistant
} from '../../../../utils/user.utils.tsx';
import { UserInfos } from '../../../../models/user.tsx';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer.tsx';
import IdentificationForm from '../../../../features/add-user-form/identification-form/IdentificationForm.tsx';
import { ColorPropsEnum } from '../../../../enum/color.ts';

const Team = () => {
  const { t } = useTranslation(['profile']);
  const dispatch = useAppDispatch();

  const { data: connectedUser } = useGetUserInfoQuery();
  const [getUsers] = useLazyGetUsersQuery();
  const [deactivateUser, { isLoading: isLoadingDeactivateUser, isSuccess: isDeactivatedUser }] =
    useDeactivateUserMutation();
  //  share results across mutation hook instances using the fixedCacheKey option
  const [, { isSuccess: isUserAdded, reset: resetAddUserMutation }] = useAddUserMutation({
    fixedCacheKey: 'shared-add-user'
  });
  const datagridSettings = useAppSelector(usersDatagridStateSelector);

  const [selectedUser, setSelectedUser] = useState<UserInfos>();
  const [isDialogDeactivateOpened, setIsDialogDeactivateOpened] = useState<boolean>(false);

  const loadData = async ({ skip, limit, sortInfo, filterValue }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filters = buildUsersFilters(filterValue);
    const sort = buildSort(sortInfo);
    try {
      const { data: users } = await getUsers({ page, limit, filters, sort });
      return users;
    } catch {
      return { data: [], count: 0 };
    }
  };

  const [sideBarOpened, setSideBarOpened] = useState(false);

  const handleSubmit = () => {
    setSideBarOpened(false);
  };

  const dataSource = useCallback(loadData, [isDeactivatedUser, isUserAdded]);

  const onFilterValueChange = (newFilterValue: FilterProps[]) => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        datagridSettingsActions.setDatagridFilters({ datagrid: 'users', filters: newFilterValue })
      );
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  };

  const onSortInfoChange = (newSortInfo: TypeSingleSortInfo) => {
    dispatch(datagridSettingsActions.setDatagridSort({ datagrid: 'users', sort: newSortInfo }));
  };

  const removeFilters = () => {
    dispatch(datagridSettingsActions.resetDatagridFilters({ datagrid: 'users' }));
  };

  const deactivateCallback = (user: UserInfos) => {
    setSelectedUser(user);
    setIsDialogDeactivateOpened(true);
  };

  const deactivate = async () => {
    if (selectedUser) {
      deactivateUser(selectedUser.email);
    }
  };

  const handleClickAddUser = () => {
    resetAddUserMutation();
    setSideBarOpened(true);
  };

  useEffect(() => {
    if (isDeactivatedUser) {
      dispatch(
        feedbackActions.setToast({
          message: t('toast.userDeactivated'),
          type: 'success'
        })
      );
      setSelectedUser(undefined);
      setIsDialogDeactivateOpened(false);
    }
  }, [isDeactivatedUser]);

  return (
    <>
      <Dialog
        title={t('dialog.deactivate.title', {
          user: `${selectedUser?.firstName} ${selectedUser?.lastName}`
        })}
        text={t('dialog.deactivate.text')}
        isOpened={isDialogDeactivateOpened}
        isLoading={isLoadingDeactivateUser}
        cancelButtonLabel={t('action.cancel', { ns: 'common' })}
        confirmButtonLabel={t('dialog.deactivate.action')}
        onCancel={() => setIsDialogDeactivateOpened(false)}
        onConfirm={deactivate}
      />
      {connectedUser && (
        <>
          <SideBarModal
            title={t('user.action.addUser')}
            isOpened={sideBarOpened}
            closeOnOutsideClick={true}
            onClose={() => setSideBarOpened(false)}>
            <Box color={ColorPropsEnum.WHITE}>
              <IdentificationForm
                withFieldset={false}
                submitCallback={handleSubmit}
                className={styles['team__identification']}
                cancelCallback={() => setSideBarOpened(false)}
              />
            </Box>
          </SideBarModal>
          <div className={styles['team__fab']}>
            <Button
              category="tertiary"
              label={t('datagrid.removeAllFilters', { ns: 'common' })}
              onClick={removeFilters}
            />
            <Button
              data-cy="team-add-user"
              iconLeft="plus"
              label={t('user.action.addUser')}
              isDisabled={isAssistant(connectedUser)}
              onClick={handleClickAddUser}
            />
          </div>
          <DatagridFeature
            style={{ minHeight: 'calc(100dvh - 22rem)' }}
            key="users"
            dataSource={dataSource}
            filterValue={datagridSettings.filters}
            onFilterValueChange={onFilterValueChange}
            sortInfo={datagridSettings.sort}
            onSortInfoChange={onSortInfoChange}
            columns={columnsDataUser(connectedUser, deactivateCallback)}
          />
        </>
      )}
    </>
  );
};

export default Team;
