import { api } from './api';
import { Establishment, EstablishmentsPaginatedResponse } from '../models/establishment.tsx';

export const establishmentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllLaboratories: builder.query<EstablishmentsPaginatedResponse, string>({
      // return all laboratories if connected user has ADMIN role, otherwise, only my laboratory is returned
      query: () => '/establishments?filter.type=laboratory'
    }),
    createClinic: builder.mutation<Establishment, Establishment>({
      query: (payload) => ({
        url: '/establishments',
        method: 'POST',
        body: payload
      })
    })
  })
});

export const { useGetAllLaboratoriesQuery, useCreateClinicMutation } = establishmentsApi;
