import { useTranslation } from 'react-i18next';
import styles from './register-page.module.scss';
import { Box, Button, Text, Divider, Wizard } from '@anatoscope/circlestorybook';
import { usersActions } from '../../../store/users/users.reducer';
import { useNavigate } from 'react-router-dom';
import { feedbackActions } from '../../../store/feedback/feedback.reducer';
import { useAppDispatch } from '../../../store/hooks';
import { useState } from 'react';
import Onboarding from '../../../features/onboarding/Onboarding';
import IdentificationForm from '../../../features/add-user-form/identification-form/IdentificationForm';
import EstablishmentForm from '../../../features/add-user-form/establishment-form/EstablishmentForm';
import { WizardStep } from '../../../models/navigation';
import { Page } from '../../../enum/user';
import { ColorPropsEnum } from '../../../enum/color';

const RegisterPage = () => {
  const { t } = useTranslation(['auth']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [startRegistration, setStartRegistration] = useState(false);
  const [selectedStep, setSelectedStep] = useState<number>(0);

  const steps: WizardStep[] = [
    { id: 0, title: t('wizard.identification') },
    { id: 1, title: t('wizard.clinic') }
  ];

  const handlePrevious = () => {
    setSelectedStep(selectedStep - 1);
  };

  const navigateNextStep = () => {
    setSelectedStep(selectedStep + 1);
  };

  const handleSubmit = () => {
    dispatch(usersActions.resetUser());
    navigate('/login');
    dispatch(
      feedbackActions.setToast({
        message: t('toast.registerSuccess'),
        type: 'success'
      })
    );
  };

  const handleClickLogin = () => {
    navigate('/login');
  };

  return (
    <div className={styles['register-page']}>
      <header className={styles['register-page__header']}>
        <img src="/logo/logo-circle-title-white-vertical.svg" alt="Circle logo" />
        <Text
          className={styles['register-page__header__title']}
          label="Shape the future"
          color={ColorPropsEnum.WHITE}></Text>
      </header>
      <Box
        padding="spacing-0"
        className={styles['register-page__box']}
        radius="large"
        color={ColorPropsEnum.WHITE}>
        <Onboarding page={Page.REGISTER} />
        <div className={styles['register-page__content']}>
          {startRegistration ? (
            <>
              <Wizard
                options={steps}
                dividerColor={ColorPropsEnum.PURPLE_LIGHT}
                selectedIndex={selectedStep}
                displayedInRow={true}
                className={styles['register-page__content__wizard']}
              />
              {selectedStep === 0 && (
                <div className={styles['register-page__content__form']}>
                  <IdentificationForm
                    withFieldset={true}
                    nextCallback={navigateNextStep}
                    previousCallback={() => setStartRegistration(false)}
                    className={styles['register-page__content__form__identification']}
                  />
                </div>
              )}
              {selectedStep === 1 && (
                <div className={styles['register-page__content__form']}>
                  <EstablishmentForm
                    submitCallback={handleSubmit}
                    previousCallback={handlePrevious}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div className={styles['register-page__content__title']}>
                <Text
                  data-cy="register-title"
                  className={styles['register-page__content__title__text']}
                  label={t('registerTitle')}
                  textAlign="center-align"
                  type="title"
                  color={ColorPropsEnum.PRIMARY}
                  size="m"
                  bold={true}
                />
              </div>
              <div className={styles['register-page__content__preregistration']}>
                <Text label={t('startRegistrationWarningFirstPart')}></Text>
                <Button
                  data-cy="start-registration"
                  label={t('startRegistration')}
                  onClick={() => {
                    setStartRegistration(true);
                  }}
                />
                <div className={styles['register-page__content__preregistration__divider']}>
                  <Divider
                    style={{
                      width: '100px'
                    }}></Divider>
                  <Text label={t('or')} color={ColorPropsEnum.GREY}></Text>
                  <Divider
                    style={{
                      width: '100px'
                    }}></Divider>
                </div>

                <div>
                  <Text
                    label={t('startRegistrationWarningSecondPart')}
                    size="m"
                    textAlign="center-align"
                  />
                  <Text
                    label={t('createDentistePath')}
                    size="m"
                    color={ColorPropsEnum.PRIMARY}
                    textAlign="center-align"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Box>
      <footer className={styles['register-page__footer']}>
        <Text
          className={styles['register-page__footer__text']}
          label={t('existingAccount')}
          size="s"
          color={ColorPropsEnum.WHITE}
        />
        <Button
          data-cy="login-link"
          variant={ColorPropsEnum.WHITE}
          category="secondary"
          label={t('login')}
          size="s"
          onClick={handleClickLogin}
        />
      </footer>
    </div>
  );
};

export default RegisterPage;
