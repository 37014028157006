import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DatagridsSettingsReducer, FilterProps } from '../../models/datagrid';
import { SortDirectionNb } from '../../enum/datagrid';
import { TypeSingleSortInfo } from '@inovua/reactdatagrid-community/types/TypeSortInfo';

const initialSettings: DatagridsSettingsReducer = {
  users: {
    filters: [
      { name: 'deletedAt', operator: 'inlist', type: 'select', value: 'active' },
      { name: 'email', operator: 'startsWith', type: 'string', value: '' },
      { name: 'firstName', operator: 'startsWith', type: 'string', value: '' },
      { name: 'lastName', operator: 'startsWith', type: 'string', value: '' },
      { name: 'role', operator: 'inlist', type: 'select' }
    ],
    sort: { name: 'email', dir: SortDirectionNb.ASC_AS_NB },
    searchValue: ''
  },
  treatments: {
    filters: [
      { name: 'currentStep', operator: 'startsWith', type: 'string', value: '' },
      { name: 'family', operator: 'startsWith', type: 'string', value: '' }
    ],
    searchValue: ''
  }
};

interface DatagridSettingSetter {
  datagrid: string;
  filters?: FilterProps[];
  sort?: TypeSingleSortInfo;
}

interface DatagridSettingSetter2 {
  datagrid: string;
  searchValue: string;
}

const datagridsSettingsSlice = createSlice({
  name: 'dgSettings',
  initialState: initialSettings,
  reducers: {
    setDatagridFilters: (state, action: PayloadAction<DatagridSettingSetter>): void => {
      state[action.payload.datagrid as keyof DatagridsSettingsReducer].filters =
        action.payload.filters;
    },
    setDatagridSort: (state, action: PayloadAction<DatagridSettingSetter>): void => {
      state[action.payload.datagrid as keyof DatagridsSettingsReducer].sort = action.payload.sort;
    },
    resetDatagridFilters: (state, action: PayloadAction<DatagridSettingSetter>): void => {
      state[action.payload.datagrid as keyof DatagridsSettingsReducer].filters =
        initialSettings[action.payload.datagrid as keyof DatagridsSettingsReducer].filters;
      state[action.payload.datagrid as keyof DatagridsSettingsReducer].sort =
        initialSettings[action.payload.datagrid as keyof DatagridsSettingsReducer].sort;
    },
    setDatagridSearch: (state, action: PayloadAction<DatagridSettingSetter2>): void => {
      state[action.payload.datagrid as keyof DatagridsSettingsReducer].searchValue =
        action.payload.searchValue;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<DatagridsSettingsReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialSettings };
    });
  }
});

const datagridSettingsActions = datagridsSettingsSlice.actions;

export { datagridsSettingsSlice, datagridSettingsActions };
