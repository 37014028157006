import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  MapClickPayload,
  MapContext,
  MapReducer,
  Position,
  ProductBubble,
  ProductIdForPosition,
  sortedLowerPositionsArray,
  sortedPositionsArray,
  sortedUpperPositionsArray
} from '../../models/map.tsx';
import { computeRangeKeys, isUpperArch } from '../../features/order-manager/teeth-map/utils';
import {
  DentalArchEnum,
  GingivaShadeEnum,
  MaterialEnum,
  PositionKey,
  ToothShadeEnum,
  ToothShapeEnum
} from '../../enum/component';
import {
  PositionKeyString,
  SelectionContextEnum,
  ToolEnum,
  ToothSelectionEnum,
  ZoneLinkEnum
} from '../../enum/map.enum.ts';
import { TeethMode } from '../../enum/product.ts';
import {
  computeInitForbiddenPositions,
  computeSingleRangeForbiddenPositions,
  getTeethBetweenZoneMultirange
} from '../../features/order-manager/teeth-map/cursors.utils';

const initialPosition: Position = {
  teethShade: undefined, // not defining a default teethShade, stb handles it
  gingivaShade: undefined,
  shape: undefined,
  frame: undefined,
  missing: false,
  extract: false,
  selection: ToothSelectionEnum.UNSELECTABLE,
  notation: undefined,
  arch: undefined,
  zone_link: undefined,
  productIds: [],
  familyColor: undefined // for color line/number of the map
};

const initialMap: MapReducer = {
  positions: {
    '18': {
      ...initialPosition,
      notation: '18',
      arch: DentalArchEnum.UPPER
    },
    '17': {
      ...initialPosition,
      notation: '17',
      arch: DentalArchEnum.UPPER
    },
    '16': {
      ...initialPosition,
      notation: '16',
      arch: DentalArchEnum.UPPER
    },
    '15': {
      ...initialPosition,
      notation: '15',
      arch: DentalArchEnum.UPPER
    },
    '14': {
      ...initialPosition,
      notation: '14',
      arch: DentalArchEnum.UPPER
    },
    '13': {
      ...initialPosition,
      notation: '13',
      arch: DentalArchEnum.UPPER
    },
    '12': {
      ...initialPosition,
      notation: '12',
      arch: DentalArchEnum.UPPER
    },
    '11': {
      ...initialPosition,
      notation: '11',
      arch: DentalArchEnum.UPPER
    },
    '21': {
      ...initialPosition,
      notation: '21',
      arch: DentalArchEnum.UPPER
    },
    '22': {
      ...initialPosition,
      notation: '22',
      arch: DentalArchEnum.UPPER
    },
    '23': {
      ...initialPosition,
      notation: '23',
      arch: DentalArchEnum.UPPER
    },
    '24': {
      ...initialPosition,
      notation: '24',
      arch: DentalArchEnum.UPPER
    },
    '25': {
      ...initialPosition,
      notation: '25',
      arch: DentalArchEnum.UPPER
    },
    '26': {
      ...initialPosition,
      notation: '26',
      arch: DentalArchEnum.UPPER
    },
    '27': {
      ...initialPosition,
      notation: '27',
      arch: DentalArchEnum.UPPER
    },
    '28': {
      ...initialPosition,
      notation: '28',
      arch: DentalArchEnum.UPPER
    },
    '48': {
      ...initialPosition,
      notation: '48',
      arch: DentalArchEnum.LOWER
    },
    '47': {
      ...initialPosition,
      notation: '47',
      arch: DentalArchEnum.LOWER
    },
    '46': {
      ...initialPosition,
      notation: '46',
      arch: DentalArchEnum.LOWER
    },
    '45': {
      ...initialPosition,
      notation: '45',
      arch: DentalArchEnum.LOWER
    },
    '44': {
      ...initialPosition,
      notation: '44',
      arch: DentalArchEnum.LOWER
    },
    '43': {
      ...initialPosition,
      notation: '43',
      arch: DentalArchEnum.LOWER
    },
    '42': {
      ...initialPosition,
      notation: '42',
      arch: DentalArchEnum.LOWER
    },
    '41': {
      ...initialPosition,
      notation: '41',
      arch: DentalArchEnum.LOWER
    },
    '31': {
      ...initialPosition,
      notation: '31',
      arch: DentalArchEnum.LOWER
    },
    '32': {
      ...initialPosition,
      notation: '32',
      arch: DentalArchEnum.LOWER
    },
    '33': {
      ...initialPosition,
      notation: '33',
      arch: DentalArchEnum.LOWER
    },
    '34': {
      ...initialPosition,
      notation: '34',
      arch: DentalArchEnum.LOWER
    },
    '35': {
      ...initialPosition,
      notation: '35',
      arch: DentalArchEnum.LOWER
    },
    '36': {
      ...initialPosition,
      notation: '36',
      arch: DentalArchEnum.LOWER
    },
    '37': {
      ...initialPosition,
      notation: '37',
      arch: DentalArchEnum.LOWER
    },
    '38': {
      ...initialPosition,
      notation: '38',
      arch: DentalArchEnum.LOWER
    }
  },
  mapContext: undefined
};

export const mapSlice = createSlice({
  name: 'map',
  initialState: initialMap,
  reducers: {
    initMapContext: (state, action: PayloadAction<Partial<MapContext>>) => {
      const { teethMode, teethComponentRule, productRule, productId } = action.payload;
      state.mapContext = {
        ...state.mapContext,
        productId,
        teethMode,
        teethComponentRule,
        productRule
      } as MapContext;
    },
    resetMapContext: (state) => {
      state.mapContext = undefined;
    },
    resetSelectionTeeth: (state) => {
      Object.keys(state.positions).forEach((positionKey) => {
        state.positions[positionKey].selection = ToothSelectionEnum.UNSELECTABLE;
      });
    },
    setTeethMode: (state, action: PayloadAction<TeethMode>) => {
      state.mapContext = {
        ...state.mapContext,
        teethMode: action.payload
      } as MapContext;
    },
    toggleToothExtract: (state, action: PayloadAction<string>) => {
      state.positions[action.payload] = {
        ...state.positions[action.payload],
        extract: !state.positions[action.payload].extract,
        missing: false
      };
    },
    toggleToothMissing: (state, action: PayloadAction<PositionKeyString>) => {
      state.positions[action.payload] = {
        ...state.positions[action.payload],
        missing: !state.positions[action.payload].missing,
        extract: false
      };
    },
    initSelectionTooth: (state) => {
      let forbiddenPositions: Array<PositionKeyString> = [];
      switch (state.mapContext?.teethMode) {
        case TeethMode.SINGLE_RANGE: {
          break;
        }
        case TeethMode.MULTI_RANGE: {
          if (
            Object.values(state.positions).find((pos) => pos.zone_link && pos.productIds.length > 0)
          ) {
            // It is not possible to add a multirange inside another multirange
            forbiddenPositions.push(
              ...getTeethBetweenZoneMultirange(state.positions, DentalArchEnum.UPPER)
            );
            forbiddenPositions.push(
              ...getTeethBetweenZoneMultirange(state.positions, DentalArchEnum.LOWER)
            );
          }
          break;
        }
      }

      const lowerForbiddenPositions = computeInitForbiddenPositions(
        state.mapContext?.teethComponentRule?.min ?? 0,
        state.positions,
        DentalArchEnum.LOWER
      );
      const upperForbiddenPositions = computeInitForbiddenPositions(
        state.mapContext?.teethComponentRule?.min ?? 0,
        state.positions,
        DentalArchEnum.UPPER
      );
      forbiddenPositions = [
        ...forbiddenPositions,
        ...lowerForbiddenPositions,
        ...upperForbiddenPositions
      ];

      Object.keys(state.positions).forEach((positionKey: string) => {
        if (state.positions[positionKey].selection !== ToothSelectionEnum.SELECTED) {
          // Not possible to select a tooth where there is missing
          // Not possible to select a tooth where there is a product -> TODO should be updated with compatibility product rules
          if (
            state.positions[positionKey].missing ||
            state.positions[positionKey].productIds.length > 0 ||
            [...new Set(forbiddenPositions)].includes(positionKey as PositionKeyString)
          ) {
            state.positions[positionKey] = {
              ...state.positions[positionKey],
              selection: ToothSelectionEnum.UNSELECTABLE
            };
          } else {
            state.positions[positionKey] = {
              ...state.positions[positionKey],
              selection: ToothSelectionEnum.SELECTABLE
            };
          }
        }
      });
    },
    computeProductSelectionTooth: (state) => {
      let forbiddenPositions: Array<PositionKeyString> = [];
      switch (state.mapContext?.teethMode) {
        case TeethMode.SINGLE_RANGE: {
          // 1. click occurred on tooth map && context is range-started => first click occured
          if (
            state.mapContext?.userAction === SelectionContextEnum.RANGE_STARTED &&
            state.mapContext?.start &&
            state.mapContext?.activeArch
          ) {
            forbiddenPositions = computeSingleRangeForbiddenPositions(
              state.mapContext.start,
              state.mapContext?.teethComponentRule?.min ?? 0,
              state.mapContext?.teethComponentRule?.max ?? 10,
              state.mapContext.activeArch
            );

            // not possible to click on the opposite arch
            if (state.mapContext?.activeArch) {
              forbiddenPositions = isUpperArch(state.mapContext.activeArch)
                ? [...forbiddenPositions, ...sortedLowerPositionsArray]
                : [...forbiddenPositions, ...sortedUpperPositionsArray];
            }
          }

          // 2. context is range-end => second click occured, product end
          if (state.mapContext?.userAction === SelectionContextEnum.RANGE_ENDED) {
            forbiddenPositions = sortedPositionsArray;
          }
          break;
        }
        case TeethMode.MULTI_RANGE: {
          if (state.mapContext.userAction) {
            // not possible to click on the opposite arch
            if (state.mapContext?.activeArch) {
              forbiddenPositions = isUpperArch(state.mapContext.activeArch)
                ? [...forbiddenPositions, ...sortedLowerPositionsArray]
                : [...forbiddenPositions, ...sortedUpperPositionsArray];
            }
          }

          break;
        }
      }

      Object.keys(state.positions).forEach((positionKey: string) => {
        if (state.positions[positionKey].selection !== ToothSelectionEnum.SELECTED) {
          // Not possible to select a tooth where there is missing
          // Not possible to select a tooth where there is a product -> TODO should be updated with compatibility product rules
          if (
            state.positions[positionKey].missing ||
            state.positions[positionKey].productIds.length > 0 ||
            [...new Set(forbiddenPositions)].includes(positionKey as PositionKeyString)
          ) {
            state.positions[positionKey] = {
              ...state.positions[positionKey],
              selection: ToothSelectionEnum.UNSELECTABLE
            };
          } else {
            state.positions[positionKey] = {
              ...state.positions[positionKey],
              selection: ToothSelectionEnum.SELECTABLE
            };
          }
        }
      });
    },
    computeMouthSelectionTooth: (state, action: PayloadAction<ToolEnum>) => {
      Object.keys(state.positions).forEach((positionKey: string) => {
        if (
          action.payload === ToolEnum.MISSING &&
          state.positions[positionKey].productIds.length > 0
        ) {
          state.positions[positionKey] = {
            ...state.positions[positionKey],
            selection: ToothSelectionEnum.UNSELECTABLE
          };
        } else {
          state.positions[positionKey] = {
            ...state.positions[positionKey],
            selection: ToothSelectionEnum.SELECTABLE
          };
        }
      });
    },
    startSingleRange: (state, action: PayloadAction<MapClickPayload>) => {
      state.positions[action.payload.notation] = {
        ...state.positions[action.payload.notation],
        selection: ToothSelectionEnum.SELECTED,
        gingivaShade: action.payload.gingivaShade,
        teethShade: action.payload.teethShade,
        shape: action.payload.shape,
        frame: action.payload.frameMaterial,
        familyColor: action.payload.familyColor
      };
      state.mapContext = {
        ...state.mapContext,
        userAction: SelectionContextEnum.RANGE_STARTED, // context: user just opened the range
        start: action.payload.notation,
        activeArch: state.mapContext?.activeArch ?? state.positions[action.payload.notation].arch
      } as MapContext;
    },
    closeSingleRange: (state, action: PayloadAction<MapClickPayload>) => {
      const rangeKeys = computeRangeKeys(state.mapContext?.start ?? '', action.payload.notation);

      Object.keys(state.positions).forEach((positionKey) => {
        if (rangeKeys.includes(positionKey)) {
          state.positions[positionKey] = {
            ...state.positions[positionKey],
            selection: ToothSelectionEnum.SELECTED,
            gingivaShade: action.payload.gingivaShade,
            teethShade: action.payload.teethShade,
            shape: action.payload.shape,
            frame: action.payload.frameMaterial,
            missing: false,
            familyColor: action.payload.familyColor
          };
        }
      });

      state.mapContext = {
        ...state.mapContext,
        userAction: SelectionContextEnum.RANGE_ENDED, // context: user just closed the range
        end: action.payload.notation
      } as MapContext;
    },
    startZone: (state, action: PayloadAction<MapClickPayload>) => {
      state.positions[action.payload.notation] = {
        ...state.positions[action.payload.notation],
        selection: ToothSelectionEnum.SELECTED,
        gingivaShade: action.payload.gingivaShade,
        teethShade: action.payload.teethShade,
        shape: action.payload.shape,
        familyColor: action.payload.familyColor,
        frame: action.payload.frameMaterial
      };
      state.mapContext = {
        ...state.mapContext,
        userAction: SelectionContextEnum.ZONE_STARTED, // context: user just opened the zone of multi range
        start: action.payload.notation,
        activeArch: state.mapContext?.activeArch ?? state.positions[action.payload.notation].arch
      } as MapContext;
    },
    endZone: (state, action: PayloadAction<MapClickPayload>) => {
      const rangeKeys = computeRangeKeys(state.mapContext?.start ?? '', action.payload.notation);
      Object.keys(state.positions).forEach((positionKey) => {
        if (rangeKeys.includes(positionKey)) {
          state.positions[positionKey] = {
            ...state.positions[positionKey],
            selection: ToothSelectionEnum.SELECTED,
            gingivaShade: action.payload.gingivaShade,
            teethShade: action.payload.teethShade,
            shape: action.payload.shape,
            familyColor: action.payload.familyColor,
            frame: action.payload.frameMaterial,
            missing: false
          };
        }
      });
      state.mapContext = {
        ...state.mapContext,
        userAction: SelectionContextEnum.ZONE_ENDED, // context: user just closed the zone of multi range
        end: action.payload.notation
      } as MapContext;
    },
    computeZoneLinkPositions: (state) => {
      // Add zone_link to positions

      // Reset zone link positions
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey] = {
            ...state.positions[positionKey],
            zone_link: undefined
          };
        }
      });

      let startArc: PositionKeyString | null = null;

      sortedPositionsArray.forEach((position: PositionKeyString, index) => {
        const currentPosition = state.positions[position];
        const prevPosition = state.positions[sortedPositionsArray[index - 1]];
        const nextPosition = state.positions[sortedPositionsArray[index + 1]];

        const isCurrentActive = currentPosition.selection === ToothSelectionEnum.SELECTED;
        const isPrevSelectable = prevPosition?.selection === ToothSelectionEnum.SELECTABLE;
        const isNextSelectable = nextPosition?.selection === ToothSelectionEnum.SELECTABLE;

        if (!currentPosition) return;

        if (
          // link zone has been started, the current position is Active, the previous and next position is Selectable
          // => single tooth where two links zone ends and begins
          startArc &&
          isCurrentActive &&
          isPrevSelectable &&
          isNextSelectable
        ) {
          currentPosition.zone_link = ZoneLinkEnum.END_START;
        } else if (
          // no link zone has been started, the current position is Active and the next position is Selectable
          // => begin the link zone
          startArc === null &&
          isCurrentActive &&
          isNextSelectable
        ) {
          startArc = position;
          currentPosition.zone_link = ZoneLinkEnum.START;
        } else if (
          // link zone has been started, the current position is Active, the previous position is Selectable
          // => end the link zone
          startArc &&
          isCurrentActive &&
          isPrevSelectable
        ) {
          currentPosition.zone_link = ZoneLinkEnum.END;
          startArc = null;
        }
      });
    },
    removeSaneTeeth: (state, action: PayloadAction<string>) => {
      const arch = state.positions[action.payload].arch;
      Object.keys(state.positions).forEach((positionKey) => {
        if (
          state.positions[positionKey].arch === arch &&
          state.positions[positionKey].selection !== ToothSelectionEnum.SELECTED &&
          state.positions[positionKey].productIds.length === 0 &&
          !state.positions[positionKey].extract
        ) {
          state.positions[positionKey].missing = true;
        }
      });
    },
    setActiveProductShade: (state, action: PayloadAction<ToothShadeEnum>) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey].teethShade = action.payload;
        }
      });
    },
    setActiveProductShape: (state, action: PayloadAction<ToothShapeEnum>) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey].shape = action.payload;
        }
      });
    },
    setActiveProductGingiva: (state, action: PayloadAction<GingivaShadeEnum>) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey].gingivaShade = action.payload;
        }
      });
    },
    setActiveProductFrameMaterial: (state, action: PayloadAction<MaterialEnum>) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey].frame = action.payload;
        }
      });
    },
    commitProductToMap: (state, action: PayloadAction<ProductIdForPosition>) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey].productIds = [
            ...state.positions[positionKey].productIds,
            action.payload
          ];
          state.positions[positionKey].selection = ToothSelectionEnum.UNSELECTABLE;
        }
      });
    },
    removeActiveProduct: (state) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey] = {
            ...initialPosition,
            notation: state.positions[positionKey].notation,
            arch: state.positions[positionKey].arch,
            productIds: state.positions[positionKey].productIds
          };
        }
      });
    },
    setProductBubbleOnTooth: (
      state,
      action: PayloadAction<{
        position: string;
        bubbles: {
          [key: string]: ProductBubble;
        };
      }>
    ) => {
      state.positions = {
        ...state.positions,
        [action.payload.position]: {
          ...state.positions[action.payload.position],
          bubbles: action.payload.bubbles
        }
      };
    },
    resetPositions: (state, action: PayloadAction<PositionKey[]>) => {
      action.payload.forEach((toothPosition: PositionKey) => {
        state.positions = {
          ...state.positions,
          [toothPosition]: initialMap.positions[toothPosition]
        };
      });
    },
    resetMap: () => initialMap
  },
  extraReducers: (builder: ActionReducerMapBuilder<MapReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialMap };
    });
  }
});

export const mapActions = mapSlice.actions;
