import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App.tsx';
import store from './store/index';
import './services/globalInterceptorSetup';
import './styles/_index.scss';

// Remove deprecated ReactDatagrid.
// Remove this part if our forked project contains a correction about DefaultProps deprecation.
if (process.env.NODE_ENV !== 'production') {
  const originalWarn = console.error;
  console.error = (...args) => {
    if (args.toString().includes('@inovua_reactdatagrid-enterprise.js')) {
      return;
    }
    originalWarn(...args);
  };
}
// endblock.

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);
