import { useEffect, useMemo, useState } from 'react';
import styles from './establishment-form.module.scss';
import { Button, Dropdown, Fieldset, TextField } from '@anatoscope/circlestorybook';
import { userSelector } from '../../../store/users/users.selectors';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { usersActions } from '../../../store/users/users.reducer';
import { useTranslation } from 'react-i18next';
import { useCreateClinicMutation } from '../../../services/establishments-api.services';
import { EstablishmentType } from '../../../enum/establishment';
import { feedbackActions } from '../../../store/feedback/feedback.reducer';
import { getMessageError } from '../../../utils/utils';
import { useAddUserMutation } from '../../../services/user.api.services';
import { Country, DentalNotation, Language } from '../../../enum/user';
import { Establishment } from '../../../models/user';
import useForm from '../../../utils/useForm';
import { StringObject, UnknownObject } from '../../../models/common';
import { ColorPropsEnum } from '../../../enum/color';

const fox3DId = 1;

type Props = {
  submitCallback: () => void;
  previousCallback: () => void;
};

const EstablishmentForm = ({ submitCallback, previousCallback }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['auth', 'common']);
  const user = useAppSelector(userSelector);

  const [addUser, { isLoading: isLoadingAddUser, isSuccess: isUserAdded }] = useAddUserMutation();
  const [createClinic, { isLoading: isLoadingCreateClinic }] = useCreateClinicMutation();
  const [createdClinicId, setCreatedClinicId] = useState<number>();

  const countries = [
    Object.values(Country).map((country) => {
      return {
        label: t(`countries.${country.toLowerCase()}`, { ns: 'common' }),
        value: country
      };
    })
  ];

  useEffect(() => {
    if (createdClinicId) {
      // Once the clinic has been created, we can create the dentist
      addNewDentist(createdClinicId);
    }
  }, [createdClinicId]);

  useEffect(() => {
    if (isUserAdded) {
      // Once everything has been created, we call the provided submitCallback
      submitCallback();
    }
  }, [isUserAdded]);

  const saveEstablishmentFormInfo = () => {
    dispatch(
      usersActions.setUser({
        ...user,
        language: Language.fr_FR,
        dentalNotation: DentalNotation.ISO,
        isAllowedToOrder: false,
        clinic: {
          name: values.name as string,
          address: {
            address: (values.address as string) || '',
            additionalAddress: (values.additionalAddress as string) || '',
            zipCode: (values.zipCode as string) || '',
            city: (values.city as string) || '',
            country: (values.country as string) || ''
          }
        }
      })
    );
  };

  const addNewDentist = async (clinicId: number) => {
    const newUser = {
      ...user,
      isAllowedToOrder: false,
      clinic: {
        id: clinicId
      },
      laboratory: {
        id: fox3DId
      }
    };
    addUser(newUser);
  };

  const addNewClinic = async () => {
    const newClinic = {
      name: values.name,
      type: EstablishmentType.CLINIC,
      address: {
        address: values.address,
        additionalAddress: values.additionalAddress,
        zipCode: values.zipCode,
        city: values.city,
        country: values.country
      }
    };

    await createClinic(newClinic as Establishment)
      .unwrap()
      .then((data) => {
        // This state is used to trigger the dentist creation after a clinic creation, see useEffect related to createdClinicId
        setCreatedClinicId(data.id);
      })
      .catch((error) => {
        dispatch(
          feedbackActions.setToast({
            message: getMessageError(error),
            type: ColorPropsEnum.DANGER
          })
        );
      });
  };

  const onSubmit = async () => {
    await addNewClinic();
  };

  const handlePreviousButton = () => {
    saveEstablishmentFormInfo();
    previousCallback();
  };

  const initUser = useMemo(
    () => ({
      name: user?.clinic?.name ?? '',
      address: user?.clinic?.address?.address ?? '',
      additionalAddress: user?.clinic?.address?.additionalAddress ?? '',
      zipCode: user?.clinic?.address?.zipCode ?? '',
      city: user?.clinic?.address?.city ?? '',
      country: user?.clinic?.address?.country ?? ''
    }),
    [user]
  );

  const validateCallback = (values: UnknownObject) => {
    const newErrors: StringObject = {};
    Object.keys(values).forEach((key) => {
      if (!values[key] && !['additionalAddress'].includes(key)) {
        newErrors[key] = 'empty';
      }
    });
    return newErrors;
  };

  const { values, errors, setValues, handleSubmit, handleSelect, handleBlur, handleChange } =
    useForm(initUser, onSubmit, undefined, validateCallback);

  useEffect(() => {
    setValues(initUser);
  }, [initUser, setValues]);

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset size="m" className={styles['establishment-form__form__fieldset']}>
        <TextField
          className={styles['establishment-form__form__fieldset__input']}
          id={'name'}
          name={'name'}
          label={t('establishment.createClinicForm.name')}
          value={values?.name}
          type={'text'}
          onBlur={handleBlur}
          onChange={handleChange}
          size={'s'}
          helperText={errors.name ? t('establishment.createClinicForm.nameMandatory') : undefined}
          variant={errors.name ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          placeholder={t('establishment.createClinicForm.placeholder.clinic')}
          radius="full"
        />

        <TextField
          className={styles['establishment-form__form__fieldset__input']}
          id={'address'}
          name={'address'}
          label={t('establishment.createClinicForm.address')}
          value={values?.address}
          type={'text'}
          onBlur={handleBlur}
          onChange={handleChange}
          size={'s'}
          helperText={
            errors.address ? t('establishment.createClinicForm.addressMandatory') : undefined
          }
          variant={errors.address ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          placeholder={t('establishment.createClinicForm.placeholder.adress')}
          radius="full"
        />
        <TextField
          className={styles['establishment-form__form__fieldset__input']}
          id={'additionalAddress'}
          name={'additionalAddress'}
          label={`${t('establishment.createClinicForm.additionalAddress')} ${
            '(' + t('form.optional', { ns: 'common' }) + ')'
          }`}
          value={values?.additionalAddress}
          type={'text'}
          onChange={handleChange}
          size={'s'}
          placeholder={t('establishment.createClinicForm.additionalAddress')}
          radius="full"
        />
        <TextField
          className={[
            styles['establishment-form__form__fieldset__input'],
            styles['establishment-form__form__fieldset__input--small']
          ].join(' ')}
          id={'zipCode'}
          name={'zipCode'}
          label={t('establishment.createClinicForm.zipCode')}
          value={values?.zipCode}
          type={'text'}
          onBlur={handleBlur}
          onChange={handleChange}
          size={'s'}
          helperText={
            errors.zipCode ? t('establishment.createClinicForm.zipCodeMandatory') : undefined
          }
          variant={errors.zipCode ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          placeholder={t('establishment.createClinicForm.zipCode')}
          radius="full"
        />
        <TextField
          className={styles['establishment-form__form__fieldset__input']}
          id={'city'}
          name={'city'}
          label={t('establishment.createClinicForm.city')}
          value={values?.city}
          type={'text'}
          onBlur={handleBlur}
          onChange={handleChange}
          size={'s'}
          helperText={errors.city ? t('establishment.createClinicForm.cityMandatory') : undefined}
          variant={errors.city ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          placeholder={t('establishment.createClinicForm.city')}
          radius="full"
        />
        <Dropdown
          id={'country'}
          name={'country'}
          data={countries}
          value={values?.country}
          label={t('establishment.createClinicForm.country')}
          variant={errors.country ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          onChange={(value: string) => handleSelect(value, 'country')}
          placeholder={t('choose')}
          radius="full"
          helperText={
            errors.country ? t('establishment.createClinicForm.countryMandatory') : undefined
          }></Dropdown>
      </Fieldset>
      <div className="form__submit-button form__submit-button--double">
        <Button
          label={t('action.previous', { ns: 'common' })}
          category="secondary"
          onClick={handlePreviousButton}
          iconLeft="fa-chevron-left"
        />
        <div className={styles['establishment-form__form__buttons--double-end']}>
          <Button
            label={t('register')}
            type="submit"
            variant={ColorPropsEnum.SUCCESS}
            isLoading={isLoadingCreateClinic || isLoadingAddUser}
            iconLeft="fa-check"
          />
        </div>
      </div>
    </form>
  );
};

export default EstablishmentForm;
