import { OrderStepEnum } from '../enum/order';
import { Family } from '../enum/product.ts';
import { WorkflowStepEnum } from '../enum/workflow-step';
import i18n from '../i18n';
import { Order } from '../models/order.tsx';

export const getOrderStepFromWorkflow = (worflowStep: WorkflowStepEnum | undefined) => {
  let orderStep;
  switch (worflowStep) {
    case WorkflowStepEnum.SUBMITTING:
      orderStep = WorkflowStepEnum.SUBMITTING;
      break;
    case WorkflowStepEnum.VALIDATION:
      orderStep = WorkflowStepEnum.VALIDATION;
      break;
    case WorkflowStepEnum.MODELING:
    case WorkflowStepEnum.MODELING_ANALYZE:
    case WorkflowStepEnum.MODELING_DESIGN:
    case WorkflowStepEnum.MODELING_EXPORT:
    case WorkflowStepEnum.MODELING_PREPARE:
    case WorkflowStepEnum.MODELING_REGISTER:
      orderStep = WorkflowStepEnum.MODELING;
      break;
    case WorkflowStepEnum.MANUFACTURING:
    case WorkflowStepEnum.CONTROL:
      orderStep = WorkflowStepEnum.MANUFACTURING;
      break;
    case WorkflowStepEnum.DELIVERY:
      orderStep = WorkflowStepEnum.DELIVERY;
      break;
    case WorkflowStepEnum.DELIVERED:
      orderStep = WorkflowStepEnum.DELIVERED;
      break;
    default:
      orderStep = undefined;
  }
  return orderStep;
};

export const mapOrderStepsForDropdown = () => {
  return Object.values(OrderStepEnum).map((orderStep) => {
    return { label: i18n.t(`orderStep.${orderStep}`, { ns: 'dashboard' }), value: orderStep };
  });
};

export const mapFamiliesForDropdown = () => {
  return Object.values(Family).map((family) => {
    return { label: i18n.t(`families.${family}`, { ns: 'catalog' }), value: family };
  });
};

export const isNotSubmittingOrder = (order: Order): boolean => {
  return !!order?.currentStep && order.currentStep !== WorkflowStepEnum.SUBMITTING;
};
