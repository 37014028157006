import styles from './instruction-form.module.scss';
import { Button, Text, TextareaField } from '@anatoscope/circlestorybook';
import useForm from '../../utils/useForm.tsx';
import { useAppDispatch, useAppSelector } from '../../store/hooks.tsx';
import { selectOrderNumberValue } from '../../store/orders/orders.selectors.tsx';
import { useGetOneOrderQuery, usePatchOrderMutation } from '../../services/orders-api.services.ts';
import { useEffect } from 'react';
import { feedbackActions } from '../../store/feedback/feedback.reducer.tsx';
import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../enum/color.ts';

type InstructionFormProps = {
  setIsOpenInstructions: (state: boolean) => void;
};

const InstructionForm = ({ setIsOpenInstructions }: InstructionFormProps) => {
  const { t } = useTranslation(['treatment']);
  const dispatch = useAppDispatch();
  const orderNumber = useAppSelector(selectOrderNumberValue);
  const { data: order } = useGetOneOrderQuery(orderNumber as string);
  const orderInstructions: string | undefined = order?.instructions;
  const initInstructions = {
    instructions: orderInstructions ?? ''
  };
  const [patchOrder, { isSuccess: isPatchedOrder }] = usePatchOrderMutation();

  const onSubmit = () => {
    if (orderNumber && values.instructions !== order?.instructions) {
      patchOrder({
        instructions: values.instructions as string,
        orderNumber
      });
    } else {
      setIsOpenInstructions(false);
    }
  };

  /**
   * Allow to erase the instructions field.
   */
  const validateCallback = () => {
    return {};
  };

  const { values, handleSubmit, handleChange } = useForm(
    initInstructions,
    onSubmit,
    undefined,
    validateCallback
  );

  useEffect(() => {
    if (isPatchedOrder) {
      dispatch(
        feedbackActions.setToast({
          message: t('treatment.instructions.success'),
          type: 'success'
        })
      );
      setIsOpenInstructions(false);
    }
  }, [isPatchedOrder]);

  useEffect(() => {
    setIsOpenInstructions(true);
  }, []);

  return (
    <form onSubmit={handleSubmit} className={styles['instruction-form']} data-cy="instruction-form">
      <Text
        className={styles['instruction-form__label']}
        label={t('treatment.instructions.label')}
        size="s"
        textAlign="center-align"
        type="title"
        bold
        color={ColorPropsEnum.WHITE}
      />
      <TextareaField
        data-cy="instruction-form-textarea"
        id="instructions"
        name="instructions"
        className={styles['instruction-form__textarea']}
        inputHeight="xl"
        label={t('treatment.instructions.textareaLabel')}
        labelColor="grey"
        value={values?.instructions}
        onChange={handleChange}
      />
      <Button
        data-cy="instruction-form-submit"
        className={styles['instruction-form__submit']}
        category="primary"
        label={t('treatment.instructions.save')}
        variant="default"
        type="submit"
      />
    </form>
  );
};
export default InstructionForm;
