import styles from './tooltip.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import {
  arrow,
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useRole
} from '@floating-ui/react';
import TooltipContent from './TooltipContent/TooltipContent.tsx';

type TooltipProps = {
  children: React.ReactNode;
  position: Placement;
  maxWidthPx: number;
  className: string;
  zIndex: number;
  openTooltip: boolean;
};

const Tooltip = ({
  children,
  position = 'top',
  maxWidthPx = 300,
  zIndex = 50,
  className,
  openTooltip
}: Partial<TooltipProps>) => {
  const [isOpen, setIsOpen] = useState(openTooltip);

  useEffect(() => {
    if (typeof openTooltip !== 'undefined') {
      setIsOpen(openTooltip);
    }
  }, [openTooltip]);

  const arrowRef = useRef(null);
  const floatingRef = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: position,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip(),
      shift(),
      arrow({
        element: arrowRef
      })
    ]
  });

  const focus = useFocus(floatingRef.context);
  const click = useClick(floatingRef.context, { enabled: true });
  const dismiss = useDismiss(floatingRef.context);
  const role = useRole(floatingRef.context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([focus, click, dismiss, role]);
  const tooltipRoot = document.getElementById('tooltip-root');
  return (
    <span>
      <span
        className={[styles['tooltip__target'], className].join(' ')}
        ref={floatingRef.refs.setReference}
        {...getReferenceProps()}>
        {React.Children.map(children, (child: React.ReactNode) => {
          if ((child as React.ReactElement)?.type !== TooltipContent) {
            return child;
          }
        })}
      </span>
      {React.Children.map(children, (child: React.ReactNode) => {
        if ((child as React.ReactElement)?.type === TooltipContent) {
          return React.cloneElement(child as React.ReactElement, {
            tooltipRoot,
            floatingPropsCallback: getFloatingProps,
            isOpen,
            arrowRef,
            floatingRef,
            maxWidthPx,
            zIndex
          });
        }
      })}
    </span>
  );
};

export default Tooltip;
