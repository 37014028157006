import { NavigateFunction } from 'react-router-dom';
import { OrderTreatmentStep } from '../../../enum/order.ts';
import { Dispatch } from '@reduxjs/toolkit';
import { ordersActions } from '../../../store/orders/orders.reducer.tsx';
import { CreationStep } from '../../../models/order.tsx';

/**
 * Redirect the user to a valid and not already loaded step.
 * Cannot allow a user to move forward in the creation/edit treatment step, only behind.
 * For Submitting order only.
 *
 * @param navigate
 * @param dispatch
 * @param targetStepStatus
 * @param targetStep
 * @param orderNumber
 */
const goToValidTargetStep = (
  navigate: NavigateFunction,
  dispatch: Dispatch,
  targetStepStatus: Partial<CreationStep>,
  targetStep: OrderTreatmentStep,
  orderNumber: string | undefined
): void => {
  if (!targetStepStatus.isActive && targetStepStatus.isValid && orderNumber) {
    dispatch(ordersActions.resetStepStatus());
    dispatch(
      ordersActions.setStepStatus({
        step: targetStep,
        stepStatus: {
          isActive: true,
          isValid: true,
          isDisabled: false
        }
      })
    );
    navigate(`/treatment/${orderNumber}/edit/${targetStep}`);
  }
};

/**
 * Go to all steps if the order is already submitted.
 * @param navigate
 * @param dispatch
 * @param targetStep
 * @param orderNumber
 */
const goToDisabledTargetStep = (
  navigate: NavigateFunction,
  dispatch: Dispatch,
  targetStep: OrderTreatmentStep,
  orderNumber: string | undefined
): void => {
  dispatch(ordersActions.resetActiveStepStatus());
  dispatch(
    ordersActions.setStepStatus({
      step: targetStep,
      stepStatus: {
        isActive: true,
        isValid: true,
        isDisabled: true
      }
    })
  );
  navigate(`/treatment/${orderNumber}/edit/${targetStep}`);
};

export { goToValidTargetStep, goToDisabledTargetStep };
