import axios from 'axios';

/**
 * Add additional data in firebase token with MS-Users.
 * This data must be only concerned security and access control.
 *
 * @param email
 * @param idToken
 */
export const setClaims = (email: string, idToken: string): Promise<void> => {
  const url = `${import.meta.env.VITE_API_GCP_GATEWAY}/users/${email}/claims`;
  return axios.post(url, { idToken });
};
