export enum LabelVariantEnum {
  PURPLE = 'purple',
  PURPLE_BOLD = 'purple-bold',
  GREY = 'grey',
  GREY_BOLD = 'grey-bold',
  BORDERED = 'bordered'
}

export enum LineFillEnum {
  DOTTED = 'dotted',
  FILLED = 'filled'
}

export enum ArrowDirectionEnum {
  LEFT = 'left',
  RIGHT = 'right'
}

export enum ArrowVariantEnum {
  GREY = 'grey',
  PURPLE = 'purple'
}
