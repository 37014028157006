import { FullProduct, Product } from '../../models/product.tsx';
import { ComponentType } from '../../enum/component.ts';
import { Component } from '../../models/component.ts';
import { OrderItemComponentLight } from '../../models/order.tsx';
import { CommonTypes, Material, Shade } from '../../models/common-types.tsx';

/**
 * Determine if the product has a frame component.
 *
 * @param detailProduct
 */
const hasFrameComponent = (detailProduct: Product | FullProduct): boolean =>
  !!(detailProduct?.components && getFrameComponent(detailProduct?.components));

const getFrameComponent = (
  components: Component[] | OrderItemComponentLight[]
): Component | OrderItemComponentLight | undefined =>
  components?.find((component) => component.componentType === ComponentType.FRAME);

const getActiveShade = (itemComponents: OrderItemComponentLight[], commonTypes: CommonTypes) => {
  const toothComponent = itemComponents.find(
    (component) => component.componentType === ComponentType.TOOTH
  );
  return commonTypes.shades.find((shade: Shade) => shade.id === toothComponent?.shade?.id)?.code;
};

const getActiveGingiva = (itemComponents: OrderItemComponentLight[], commonTypes: CommonTypes) => {
  const gingivaComponent = itemComponents.find(
    (component) => component.componentType === ComponentType.GINGIVA
  );
  return commonTypes.shades.find((shade: Shade) => shade.id === gingivaComponent?.shade?.id)?.code;
};

const getActiveFrameMaterial = (
  itemComponents: OrderItemComponentLight[],
  commonTypes: CommonTypes
) => {
  const frameComponent: OrderItemComponentLight = getFrameComponent(
    itemComponents
  ) as OrderItemComponentLight;
  return commonTypes.materials.find(
    (material: Material) => material.id === frameComponent?.material?.id
  )?.code;
};

export {
  hasFrameComponent,
  getFrameComponent,
  getActiveFrameMaterial,
  getActiveGingiva,
  getActiveShade
};
