import { OrdersReducer } from '../../models/order.ts';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../index.tsx';
import { Edition, Order, OrderReferences, OrderSteps } from '../../models/order.tsx';
import { Patient } from '../../models/patient.tsx';
import { OrderTreatmentStep } from '../../enum/order.ts';

interface ReducerState {
  orderState: OrdersReducer;
}

export const selectEditionStepIsValid = createSelector(
  [(state: RootState) => state.orderState.editionSteps, (_state: RootState, step: string) => step],
  (creationSteps: OrderSteps, step: string) => creationSteps[step as keyof OrderSteps].isValid
);

export const selectEditionStepIsActive = createSelector(
  [(state: RootState) => state.orderState.editionSteps, (_state: RootState, step: string) => step],
  (creationSteps: OrderSteps, step: string) => creationSteps[step as keyof OrderSteps].isActive
);

export const selectEditionStepIsDisabled = createSelector(
  [(state: RootState) => state.orderState.editionSteps, (_state: RootState, step: string) => step],
  (creationSteps: OrderSteps, step: string) => creationSteps[step as keyof OrderSteps].isDisabled
);

export const selectDetailOrderSelector = (state: ReducerState) => state.orderState.order;

export const selectItemsSelector = (state: ReducerState) => state.orderState.items;

export const selectCurrentItemSelector = (state: ReducerState) => state.orderState.currentItem;

export const selectEditionSelector = (state: ReducerState) => state.orderState.edition;

export const selectReferenceValue = createSelector(
  [selectDetailOrderSelector, () => 'patient'],
  (orderDetail: Partial<Order> | undefined, key: string) => {
    let orderDetailPatient = undefined;
    if (orderDetail) {
      orderDetailPatient = orderDetail[key as keyof OrderReferences] as Patient;
      return orderDetailPatient?.reference;
    }
    return orderDetailPatient;
  }
);

export const selectOrderNumberValue = createSelector(
  [selectDetailOrderSelector, () => 'orderNumber'],
  (orderDetail: Partial<Order> | undefined, key: string) =>
    orderDetail ? (orderDetail[key as keyof OrderReferences] as string) : undefined
);

export const selectEditionPlanStep = createSelector(
  [selectEditionSelector, () => OrderTreatmentStep.PLAN],
  (edition: Edition, step: string) => (edition ? edition[step].selectedOptions : undefined)
);
