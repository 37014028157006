import i18next from 'i18next';
import moment from '../../../../moment-config';

import { OrderStepEnum } from '../../../../enum/order';
import { OrderForList } from '../../../../models/order';
import {
  ArrowDirectionEnum,
  ArrowVariantEnum,
  LabelVariantEnum,
  LineFillEnum
} from './OrderStepsItem/order-steps-item.enum';

// There is an available user action for the step
export const stepItemIsClickable = (step: OrderStepEnum): boolean => {
  switch (step) {
    case OrderStepEnum.SUBMITTING:
      return true;
    case OrderStepEnum.MANUFACTURING:
    case OrderStepEnum.VALIDATION:
    case OrderStepEnum.MODELING:
    case OrderStepEnum.DELIVERY:
    case OrderStepEnum.DELIVERED:
      return false;
    default:
      return false;
  }
};

const getOrderStepIndex = (step: OrderStepEnum): number => {
  return Object.values(OrderStepEnum).indexOf(step);
};

const isFirstStep = (step: OrderStepEnum): boolean => {
  const index = getOrderStepIndex(step);
  return index === 0;
};

export const isLastStep = (step: OrderStepEnum): boolean => {
  const index = getOrderStepIndex(step);
  return index === Object.values(OrderStepEnum).length - 1;
};

export const isCurrentStep = (currentStep: OrderStepEnum, step: OrderStepEnum): boolean => {
  return currentStep === step.toLowerCase();
};

const isAfterCurrentStep = (step: OrderStepEnum, currentStep: OrderStepEnum): boolean => {
  const index = getOrderStepIndex(step);
  return getOrderStepIndex(currentStep) < index;
};

export const computeLabel = (
  currentStep: OrderStepEnum,
  step: OrderStepEnum,
  order: OrderForList
): string => {
  if (isFirstStep(step)) {
    return isCurrentStep(currentStep, step)
      ? i18next.t('orderStep.submitting', { ns: 'dashboard' })
      : moment(order?.submissionDate).format('DD MMM');
  }

  if (isLastStep(step)) {
    const expectedDate = order?.expectedDate ? moment(order?.expectedDate).format('DD MMM') : '';
    return isCurrentStep(currentStep, step)
      ? i18next.t('orderStep.delivered', { ns: 'dashboard' })
      : expectedDate;
  }
  return i18next.t(`orderStep.${step}`, { ns: 'dashboard' });
};

export const computeLabelVariant = (
  currentStep: OrderStepEnum,
  step: OrderStepEnum
): LabelVariantEnum => {
  if (isFirstStep(step)) {
    return LabelVariantEnum.PURPLE_BOLD;
  }

  if (isCurrentStep(currentStep, step)) {
    return LabelVariantEnum.BORDERED;
  }
  if (isLastStep(step)) {
    return LabelVariantEnum.GREY_BOLD;
  }

  if (isAfterCurrentStep(step, currentStep)) {
    return LabelVariantEnum.GREY;
  }

  return LabelVariantEnum.PURPLE;
};

export const computeLineFill = (
  step: OrderStepEnum,
  currentStep: OrderStepEnum
): LineFillEnum | undefined => {
  if (isLastStep(step)) {
    return undefined;
  }
  return getOrderStepIndex(currentStep) < getOrderStepIndex(step) + 1
    ? LineFillEnum.DOTTED
    : LineFillEnum.FILLED;
};

export const computeArrowDirection = (step: OrderStepEnum): ArrowDirectionEnum | undefined => {
  if (isFirstStep(step)) {
    return ArrowDirectionEnum.RIGHT;
  }

  if (isLastStep(step)) {
    return ArrowDirectionEnum.LEFT;
  }

  return undefined;
};

export const computeArrowVariant = (
  currentStep: OrderStepEnum,
  step: OrderStepEnum
): ArrowVariantEnum => {
  if (isFirstStep(step)) {
    return isCurrentStep(currentStep, step) ? ArrowVariantEnum.GREY : ArrowVariantEnum.PURPLE;
  }
  return isCurrentStep(currentStep, step) ? ArrowVariantEnum.PURPLE : ArrowVariantEnum.GREY;
};
