import { Dropdown, Text, TextField } from '@anatoscope/circlestorybook';
import styles from './my-clinic.module.scss';
import { useGetUserInfoQuery } from '../../../../services/user.api.services';
import { useTranslation } from 'react-i18next';
import SkeletonList from '../../../../features/skeleton-list/SkeletonList';
import { Country } from '../../../../enum/user';
import { ColorPropsEnum } from '../../../../enum/color';

const MyClinic = () => {
  const { t } = useTranslation(['profile']);
  const { data: user, isLoading: isGetUserLoading } = useGetUserInfoQuery();

  const countries = [
    Object.values(Country).map((country) => {
      return {
        label: t(`countries.${country.toLowerCase()}`, { ns: 'common' }),
        value: country
      };
    })
  ];

  return (
    <>
      <form className={styles['my-clinic__form']}>
        <div className={styles['my-clinic__content']}>
          <article className={styles['my-clinic__article']}>
            <div>
              <Text
                className={styles['my-clinic__article__title']}
                label={t('myClinic.address.title')}
                bold={true}
                color={ColorPropsEnum.PRIMARY}></Text>
              {isGetUserLoading ? (
                <SkeletonList
                  className={styles['my-clinic__skeleton']}
                  heightItem="30px"
                  widthItem="200px"
                  count={6}></SkeletonList>
              ) : (
                <>
                  <TextField
                    color={ColorPropsEnum.PRIMARY}
                    id="name"
                    name="name"
                    isDisabled={true}
                    label={t('myClinic.address.labelInput.name')}
                    value={user?.clinic?.name}
                    className={styles['my-clinic__article__input']}
                    radius="full"
                  />
                  <TextField
                    color={ColorPropsEnum.PRIMARY}
                    id="address"
                    name="address"
                    isDisabled={true}
                    label={t('myClinic.address.labelInput.address')}
                    value={user?.clinic?.address.address}
                    className={styles['my-clinic__article__input']}
                    radius="full"
                  />
                  <TextField
                    id="additionalAddress"
                    name="additionalAddress"
                    label={t('myClinic.address.labelInput.additionalAddress')}
                    isDisabled={true}
                    value={user?.clinic?.address.additionalAddress}
                    className={styles['my-clinic__article__input']}
                    radius="full"
                  />
                  <TextField
                    color={ColorPropsEnum.PRIMARY}
                    id="zipCode"
                    name="zipCode"
                    label={t('myClinic.address.labelInput.zipCode')}
                    value={user?.clinic?.address.zipCode}
                    isDisabled={true}
                    className={styles['my-clinic__article__input--small']}
                    radius="full"
                  />
                  <TextField
                    color={ColorPropsEnum.PRIMARY}
                    id="city"
                    name="city"
                    label={t('myClinic.address.labelInput.city')}
                    value={user?.clinic?.address.city}
                    className={styles['my-clinic__article__input']}
                    isDisabled={true}
                    radius="full"
                  />
                  <TextField
                    color={ColorPropsEnum.PRIMARY}
                    id="region"
                    name="region"
                    label={t('myClinic.address.labelInput.region')}
                    value={user?.clinic?.address.region}
                    className={styles['my-clinic__article__input']}
                    isDisabled={true}
                    radius="full"
                  />
                  <Dropdown
                    id="country"
                    data={countries}
                    value={user?.clinic?.address.country}
                    isDisabled={true}
                    radius="full"
                    label={t('myClinic.address.labelInput.country')}></Dropdown>
                </>
              )}
            </div>
          </article>
        </div>
      </form>
    </>
  );
};

export default MyClinic;
