import { DatagridsSettingsReducer } from '../../models/datagrid';

interface ReducerState {
  datagridsSettingsState: DatagridsSettingsReducer;
}

export const treatmentsDatagridStateSelector = (state: ReducerState) =>
  state.datagridsSettingsState.treatments;

export const usersDatagridStateSelector = (state: ReducerState) =>
  state.datagridsSettingsState.users;
