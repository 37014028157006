import PublicApp from './pages/public/PublicApp';
import ErrorPage from './pages/error-page/ErrorPage';
import ProtectedRoute from './auth/ProtectedRoute';
import LoginPage from './pages/public/login-page/LoginPage';
import RegisterPage from './pages/public/register-page/RegisterPage.tsx';
import NotFoundPage from './pages/not-found-page/NotFoundPage';
import PrivateApp from './pages/private/PrivateApp';
import DashboardPage from './pages/private/dashboard-page/DashboardPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from './store/hooks';
import { isAuthenticatedSelector } from './store/auth/auth.selectors';
import HotlinePage from './pages/private/hotline-page/HotlinePage.tsx';
import ProfilePage from './pages/private/profile-page/ProfilePage.tsx';
import PersonalInformation from './pages/private/profile-page/PersonalInformation/PersonalInformation.tsx';
import Team from './pages/private/profile-page/team/Team.tsx';
import MyClinic from './pages/private/profile-page/my-clinic/MyClinic.tsx';
import ReferenceStep from './pages/private-treatment/treatment-page/reference-step/ReferenceStep.tsx';
import TreatmentPage from './pages/private-treatment/treatment-page/TreatmentPage.tsx';
import PrivateTreatmentApp from './pages/private-treatment/PrivateTreatmentApp.tsx';
import { isCurrentUser } from './services/firebase.services.tsx';
import { OrderTreatmentMode, OrderTreatmentStep } from './enum/order.ts';
import PlanStep from './pages/private-treatment/treatment-page/plan-step/PlanStep.tsx';

const RoutesDentist = () => {
  // Redux authentication boolean and Firebase state of authentication to improve the security.
  const isAuthenticated = useAppSelector(isAuthenticatedSelector) && isCurrentUser();
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/treatments" />} />
      <Route element={<PublicApp />} errorElement={<ErrorPage />}>
        <Route
          path="login"
          element={
            <ProtectedRoute redirectPath="/treatments" isAllowed={!isAuthenticated}>
              <LoginPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="register"
          element={
            <ProtectedRoute redirectPath="/register" isAllowed={!isAuthenticated}>
              <RegisterPage />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        element={
          <ProtectedRoute redirectPath="/login" isAllowed={isAuthenticated}>
            <PrivateApp />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}>
        <Route index path="treatments" element={<DashboardPage />} />
        <Route index path="profile" element={<Navigate to="/profile/personal-information" />} />
        <Route
          path="/profile/personal-information"
          element={
            <ProfilePage>
              <PersonalInformation />
            </ProfilePage>
          }
        />
        <Route
          path="/profile/team"
          element={
            <ProfilePage>
              <Team />
            </ProfilePage>
          }
        />
        <Route
          path="/profile/clinic"
          element={
            <ProfilePage>
              <MyClinic />
            </ProfilePage>
          }
        />
        <Route path="hotline" element={<HotlinePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>

      <Route
        element={
          // For the order detail, if a dentist, without this right, can see the details, despite everything,
          // we can get inspired by https://reactrouter.com/en/main/route/loader and, in protected route, additionally
          // conditioning the loading after the status order.
          <ProtectedRoute isAllowed={isAuthenticated} neededRights={['isAllowedToOrder']}>
            <PrivateTreatmentApp />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}>
        <Route
          index
          path="treatment/create"
          element={
            <TreatmentPage step={OrderTreatmentStep.REFERENCE} mode={OrderTreatmentMode.CREATE}>
              <ReferenceStep />
            </TreatmentPage>
          }
        />
        <Route
          index
          path="treatment/:orderNumber/edit/reference"
          element={
            <TreatmentPage step={OrderTreatmentStep.REFERENCE} mode={OrderTreatmentMode.EDIT}>
              <ReferenceStep />
            </TreatmentPage>
          }
        />
        <Route
          index
          path="treatment/:orderNumber/edit/plan"
          element={
            <TreatmentPage step={OrderTreatmentStep.PLAN} mode={OrderTreatmentMode.EDIT}>
              <PlanStep />
            </TreatmentPage>
          }
        />
      </Route>
    </Routes>
  );
};

export default RoutesDentist;
