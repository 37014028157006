// This is temporary
// To Do: add a date format parameter (short / medium / long )
// handle locale
// separator should not be in parameters
// Use moment library

import i18next from 'i18next';
import { ErrorCode } from '../enum/error';
import i18n from '../i18n';
import { Country, Language } from '../enum/user';

export const formatDate = (dateToFormat: Date, options?: Intl.DateTimeFormatOptions) => {
  options = options
    ? { ...options, hour12: false }
    : {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      };
  return dateToFormat.toLocaleString(i18n.language, options);
};

/* We specify this rule because we don't know the payload type, and we use explicitly error as object after.*/
/* So this is an emergency case, don't reproduce */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const getMessageError = (error: any): string => {
  let message = i18next.t('error.generic', { ns: 'common' });
  if ('data' in error) {
    if (typeof error.data === 'object') {
      // Handle error from our backend
      if (Object.values(ErrorCode).includes(error.data.error)) {
        message = i18next.t(error.data.error, { ns: 'error' });
      } else if (error.status !== 500) {
        message = error.data.message;
      }
    }
  }

  return message;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.toLowerCase().charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};

export const countries = [
  Object.values(Country).map((country) => {
    return {
      label: i18next.t(`countries.${country.toLowerCase()}`, { ns: 'common' }),
      value: country
    };
  })
];

export const languages = Object.keys(Language).map((value) => {
  return { label: i18next.t(`language.${value}`, { ns: 'common' }), value: value };
});
export const getLocalizedProperty = (property: string): string => {
  switch (property) {
    case 'name':
    case 'label':
      // This adds the locale after the filter name (for example, label -> labelFr or labelEn)
      return `label${capitalizeFirstLetter(i18next.language)}`;
    case 'products':
      return `products${capitalizeFirstLetter(i18next.language)}`;
    default:
      return property;
  }
};
