import { OrderStepEnum } from '../../../../enum/order';
import { OrderForList } from '../../../../models/order';
import OrderStepsItem from './OrderStepsItem/OrderStepsItem';
import {
  computeArrowDirection,
  computeArrowVariant,
  computeLabel,
  computeLabelVariant,
  computeLineFill,
  isCurrentStep,
  isLastStep,
  stepItemIsClickable
} from './order-steps';
import styles from './order-steps.module.scss';

interface OrderStepsProps {
  currentStep?: OrderStepEnum;
  className?: string;
  order: OrderForList;
}

const OrderSteps = ({ currentStep, className, order }: OrderStepsProps) => {
  return (
    <div key={order.orderNumber} className={[styles['order-steps'], className].join(' ')}>
      {currentStep &&
        Object.values(OrderStepEnum).map((step) => {
          return (
            <div
              className={[
                styles['order-steps__item'],
                isLastStep(step) ? styles['order-steps__item--last'] : ''
              ].join(' ')}
              key={`${order.id}-${order.orderNumber}-${step}`}>
              {currentStep && (
                <OrderStepsItem
                  label={computeLabel(currentStep, step, order)}
                  isClickable={isCurrentStep(currentStep, step) && stepItemIsClickable(step)}
                  labelVariant={computeLabelVariant(currentStep, step)}
                  arrowDirection={computeArrowDirection(step)}
                  arrowVariant={computeArrowVariant(currentStep, step)}
                  lineFill={computeLineFill(step, currentStep)}></OrderStepsItem>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default OrderSteps;
