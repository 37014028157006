import React from 'react';
import ReactDOM from 'react-dom';
import { Placement, UseFloatingReturn } from '@floating-ui/react';
import styles from './tooltip-content.module.scss';
import { ColorPropsEnum } from '../../../enum/color.ts';
import { BorderRadiusType } from '../../../enum/styles.ts';

type TooltipContentProps = {
  tooltipRoot: Element;
  isOpen: Placement;
  floatingPropsCallback: () => Record<string, unknown>;
  children: React.ReactNode;
  floatingRef: UseFloatingReturn;
  maxWidthPx: number;
  zIndex: number;
  backgroundColor: ColorPropsEnum;
  borderRadius: BorderRadiusType;
};

const TooltipContent = ({
  tooltipRoot,
  isOpen,
  floatingPropsCallback,
  children,
  floatingRef,
  maxWidthPx = 300,
  zIndex = 50,
  backgroundColor = ColorPropsEnum.WHITE,
  borderRadius = BorderRadiusType.full
}: Partial<TooltipContentProps>) => {
  return (
    <>
      {tooltipRoot &&
        floatingPropsCallback &&
        ReactDOM.createPortal(
          isOpen && (
            <div
              className={[
                styles['tooltip-content'],
                styles[`tooltip-content--${backgroundColor}`],
                styles[`tooltip-content--${borderRadius}`]
              ].join(' ')}
              ref={floatingRef?.refs.setFloating}
              style={{
                position: floatingRef?.strategy,
                top: floatingRef?.y ?? 0,
                left: floatingRef?.x ?? 0,
                width: 'max-content',
                maxWidth: `${maxWidthPx}px`,
                zIndex: zIndex
              }}
              {...floatingPropsCallback()}>
              {children}
            </div>
          ),
          tooltipRoot
        )}
    </>
  );
};

export default TooltipContent;
