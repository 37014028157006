import styles from './filters-bar.module.scss';
import { Dropdown } from '@anatoscope/circlestorybook';
import { treatmentsDatagridStateSelector } from '../../../../store/datagrids-settings/datagrids-settings.selectors';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { OrderStepEnum } from '../../../../enum/order';
import { datagridSettingsActions } from '../../../../store/datagrids-settings/datagrids-settings.reducers';
import Search from '../../../../features/search/Search';
import { useCallback, useEffect, useState } from 'react';
import { mapFamiliesForDropdown, mapOrderStepsForDropdown } from '../../../../utils/order.utils';
import { DatagridName, FilterName } from '../../../../enum/datagrid';
import { useTranslation } from 'react-i18next';
import { Family } from '../../../../enum/product';

const FiltersBar = () => {
  const dispatch = useAppDispatch();
  const datagridSettings = useAppSelector(treatmentsDatagridStateSelector);
  const [searchValue, setSearchValue] = useState<string>(datagridSettings?.searchValue);
  const { t } = useTranslation(['dashboard']);

  const updateSearchValue = useCallback(() => {
    dispatch(
      datagridSettingsActions.setDatagridSearch({
        datagrid: DatagridName.TREATMENTS,
        searchValue: searchValue.trim()
      })
    );
  }, [dispatch, searchValue]);

  useEffect(() => {
    // add a delay to launch a search when user stops typing
    const delayDebounceFn = setTimeout(() => {
      updateSearchValue();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [updateSearchValue]);

  const handleKeyDown = (key: string) => {
    if (key === 'Enter') {
      updateSearchValue();
    }
  };

  const handleChangeSearchValue = (value: string) => {
    setSearchValue(value);
  };

  const handleChangeValue = (newValue: Family | OrderStepEnum, filterName: FilterName) => {
    const updatedFilters = datagridSettings?.filters?.map((filter) => {
      const filterCopy = { ...filter };
      if (filterCopy.name === filterName) {
        filterCopy.value = newValue;
      }
      return filterCopy;
    });
    dispatch(
      datagridSettingsActions.setDatagridFilters({
        datagrid: DatagridName.TREATMENTS,
        filters: updatedFilters
      })
    );
  };

  const familyFilter = datagridSettings?.filters?.find(
    (filter) => filter.name === FilterName.FAMILY
  );
  const currentStepFilter = datagridSettings?.filters?.find(
    (filter) => filter.name === FilterName.CURRENT_STEP
  );
  return (
    <div className={styles['filters-bar']}>
      <div className={styles['filters-bar__filters']}>
        <Dropdown
          className={styles['filters-bar__filters__dropdown']}
          data={[mapFamiliesForDropdown()]}
          radius="full"
          value={familyFilter?.value}
          onChange={(newValue: Family) => handleChangeValue(newValue, FilterName.FAMILY)}
          placeholder={t('filter.allFamilies')}></Dropdown>
        <Dropdown
          className={styles['filters-bar__filters__dropdown']}
          data={[mapOrderStepsForDropdown()]}
          radius="full"
          value={currentStepFilter?.value}
          onChange={(newValue: OrderStepEnum) =>
            handleChangeValue(newValue, FilterName.CURRENT_STEP)
          }
          placeholder={t('filter.allOrderSteps')}></Dropdown>
        <Search
          searchValue={searchValue}
          onChange={handleChangeSearchValue}
          onKeyDown={handleKeyDown}></Search>
      </div>
    </div>
  );
};

export default FiltersBar;
