import { ToolEnum } from '../../../../../../enum/map.enum.ts';
import { mapActions } from '../../../../../../store/map/map.reducer.tsx';
import { TeethMode } from '../../../../../../enum/product.ts';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks.tsx';
import styles from './treatment-teeth-missing-extract-menu.module.scss';
import { CreationStep } from '../../../../../../models/order.tsx';
import { selectCurrentItemSelector } from '../../../../../../store/orders/orders.selectors.tsx';
import { mapContextSelector } from '../../../../../../store/map/map.selectors.tsx';
import { ordersActions } from '../../../../../../store/orders/orders.reducer.tsx';
import { useTranslation } from 'react-i18next';

/**
 *
 * @param selectedTeethMissingOrExtract
 *   The current teeth option selected
 * @param setSelectedTeethMissingOrExtract
 *   Set the new teeth option selected.
 * @param isValidCurrentItemCallback
 *   Check if a current item exists and respect the rules defined by the lab. If not, the user should finish his adding
 *   before changing his selection.
 * @param addMultiRangeCallback
 *   If the current item is valid and a multi-range product, we assume that the user have finished his adding.
 * @param stepStatus
 *   The status (active, valid or disabled) of the current step (reference, plan, custom...)
 * @constructor
 */
const TreatmentTeethMissingOrExtractMenu = ({
  selectedTeethMissingOrExtract,
  setSelectedTeethMissingOrExtract,
  stepStatus,
  isValidCurrentItemCallback,
  addMultiRangeCallback
}: {
  isValidCurrentItemCallback: () => boolean;
  addMultiRangeCallback: (resetMap: boolean) => void;
  selectedTeethMissingOrExtract: ToolEnum | undefined;
  setSelectedTeethMissingOrExtract: (selectedMissingOrExtract: ToolEnum | undefined) => void;
  stepStatus: CreationStep;
}) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation(['treatment']);

  const mapContext = useAppSelector(mapContextSelector);
  const currentItem = useAppSelector(selectCurrentItemSelector);

  /**
   * On Teeth missing or extract click.
   *
   * @param activeTool
   */
  const onTeethMissingOrExtractClick = (activeTool: ToolEnum): void => {
    const isValidCurrentItem = isValidCurrentItemCallback();
    if (!stepStatus.isDisabled && isValidCurrentItem) {
      if (selectedTeethMissingOrExtract === activeTool) {
        addMultiRangeCallback(true);
        setSelectedTeethMissingOrExtract(undefined);
      } else {
        /**
         * If the user choose a product but doesn't select any teeth. We reset all.
         */
        if (currentItem && !mapContext?.userAction) {
          dispatch(ordersActions.setStepPlanSelections({ selections: { product: undefined } }));
          dispatch(ordersActions.setCurrentItem(undefined));
        } else if (currentItem) {
          // When we added a multi-range, manually reset the selection on positions to avoid any conflict.
          addMultiRangeCallback(false);
          dispatch(mapActions.removeActiveProduct());
          dispatch(mapActions.resetMapContext());
        }
        setSelectedTeethMissingOrExtract(activeTool);
        dispatch(mapActions.computeMouthSelectionTooth(activeTool));
        dispatch(mapActions.setTeethMode(TeethMode.SINGLE_TOOTH));
      }
    }
  };
  return (
    <div
      className={styles['treatment-teeth-missing-extract-menu--teeth-missing-extract']}
      id="plan-step--teeth-map--teeth-missing-extract"
      data-cy="treatmentTeethMissingExtractMenu">
      <div
        className={[
          styles['treatment-teeth-missing-extract-menu--teeth-missing-extract--switch'],
          selectedTeethMissingOrExtract && selectedTeethMissingOrExtract === ToolEnum.EXTRACT
            ? styles['treatment-teeth-missing-extract-menu--teeth-missing-extract--switch--top']
            : '',
          selectedTeethMissingOrExtract && selectedTeethMissingOrExtract === ToolEnum.MISSING
            ? styles['treatment-teeth-missing-extract-menu--teeth-missing-extract--switch--bottom']
            : ''
        ].join(' ')}>
        <button
          type="button"
          data-cy="planStepExtractTeeth"
          className={styles['treatment-teeth-missing-extract-menu--teeth-missing-extract--extract']}
          onClick={() => onTeethMissingOrExtractClick(ToolEnum.EXTRACT)}>
          <img
            className={styles['treatment-teeth-missing-extract-menu--teeth-missing-extract--img']}
            src="https://storage.googleapis.com/public-circle-dental/images/svg/extract-tooth.svg"
            alt={t('treatment.plan.missing-extract.extract')}
          />
        </button>
        <button
          type="button"
          data-cy="planStepMissingTeeth"
          className={styles['treatment-teeth-missing-extract-menu--teeth-missing-extract--missing']}
          onClick={() => {
            onTeethMissingOrExtractClick(ToolEnum.MISSING);
          }}>
          <img
            className={styles['treatment-teeth-missing-extract-menu--teeth-missing-extract--img']}
            src="https://storage.googleapis.com/public-circle-dental/images/svg/missing-tooth.svg"
            alt={t('treatment.plan.missing-extract.missing')}
          />
        </button>
      </div>
    </div>
  );
};

export default TreatmentTeethMissingOrExtractMenu;
