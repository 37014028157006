export enum ToothSelectionEnum {
  SELECTED = 'selected',
  SELECTABLE = 'selectable',
  UNSELECTABLE = 'unselectable'
}

export enum SelectionContextEnum {
  RANGE_STARTED = 'range-started',
  RANGE_ENDED = 'range-ended',
  SINGLE_TOOTH = 'single-tooth',
  ZONE_STARTED = 'zone-started',
  ZONE_ENDED = 'zone-ended'
}

export enum ZoneLinkEnum {
  START = 'start',
  END = 'end',
  END_START = 'end-start' // zone contains a single tooth which two links will begin and end
}

export enum ToolEnum {
  MISSING = 'MISSING',
  EXTRACT = 'EXTRACT'
}
export enum CursorEnum {
  POINTER = 'pointer',
  NOT_ALLOWED = 'not-allowed',
  START = 'start',
  END = 'end'
}

// Only works for FDI notation
export type PositionKeyString =
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '21'
  | '22'
  | '23'
  | '24'
  | '25'
  | '26'
  | '27'
  | '28'
  | '31'
  | '32'
  | '33'
  | '34'
  | '35'
  | '36'
  | '37'
  | '38'
  | '41'
  | '42'
  | '43'
  | '44'
  | '45'
  | '46'
  | '47'
  | '48';
