import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './search.module.scss';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { IconButton } from '@anatoscope/circlestorybook';
import { useState, KeyboardEvent } from 'react';
import i18n from '../../i18n';
import { ColorPropsEnum } from '../../enum/color';

interface SearchProps {
  searchValue: string;
  onChange: (value: string) => void;
  onKeyDown: (value: string) => void;
}

const Search = ({ searchValue, onChange, onKeyDown }: SearchProps) => {
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <div className={[styles['search'], focused && styles['search--focused']].join(' ')}>
      <input
        className={styles['search__content']}
        type="search"
        placeholder={i18n.t('filter.search', { ns: 'dashboard' })}
        value={searchValue}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => onKeyDown(event.key)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value);
        }}
      />
      {searchValue && (
        <IconButton
          faIconClass="fa-regular fa-xmark"
          radius="full"
          size="xs"
          color={ColorPropsEnum.GREY}
          onClick={() => onChange('')}
        />
      )}
      <span className={styles['search__icon']}>
        <FontAwesomeIcon icon={faSearch} size="lg" />
      </span>
    </div>
  );
};

export default Search;
