import { BubblePicto, Loader } from '@anatoscope/circlestorybook';
import { ColorPropsEnum } from '../../../../../../enum/color.ts';
import { getLocalizedProperty } from '../../../../../../utils/utils.tsx';
import { FullProduct, Product } from '../../../../../../models/product.tsx';
import { useEffect, useState } from 'react';
import Tooltip from '../../../../../../features/tooltip/Tooltip.tsx';
import TooltipContent from '../../../../../../features/tooltip/TooltipContent/TooltipContent.tsx';
import { ComponentType } from '../../../../../../enum/component.ts';
import { MappedProducts } from '../../../../../../features/order-manager/products-manager.tsx';
import {
  CreationStep,
  OrderItemComponentLight,
  PlanSelectionOptions
} from '../../../../../../models/order.tsx';
import { Family, ProductCategory } from '../../../../../../enum/product.ts';
import { BorderRadiusType } from '../../../../../../enum/styles.ts';
import styles from './treatment-product-bubble-menu.module.scss';
import { useTranslation } from 'react-i18next';
import { ordersActions } from '../../../../../../store/orders/orders.reducer.tsx';
import { useGetOneProductMutation } from '../../../../../../services/products-api.services.ts';
import { useAppDispatch } from '../../../../../../store/hooks.tsx';
import { Material } from '../../../../../../models/common-types.tsx';
import { getFrameComponent, hasFrameComponent } from '../../../../utils.ts';
import { Component } from '../../../../../../models/component.ts';

/**
 * Treatment Product Menu Bubbles (without the categories part)
 *
 * @param selectedOptions
 *   User selections (family, category, product) to add styles on bubbles
 * @param mappedProducts
 *   All products activated for the user lab.
 * @param stepStatus
 *   The status (active, valid or disabled) of the current step (reference, plan, custom...)
 * @param isValidCurrentItemCallback
 *   Check if a current item exists and respect the rules defined by the lab. If not, the user should finish his adding
 *   before changing his selection.
 * @param addMultiRangeCallback
 *   If the current item is valid and a multi-range product, we assume that the user have finished his adding.
 * @constructor
 */
const TreatmentProductBubbleMenu = ({
  selectedOptions,
  mappedProducts,
  stepStatus,
  isValidCurrentItemCallback,
  addMultiRangeCallback
}: {
  selectedOptions: PlanSelectionOptions;
  mappedProducts: MappedProducts;
  stepStatus: CreationStep;
  isValidCurrentItemCallback: () => boolean;
  addMultiRangeCallback: () => void;
}) => {
  const { t } = useTranslation(['component']);
  const dispatch = useAppDispatch();

  const [isOpenTooltip, setIsOpenTooltip] = useState<boolean | undefined>(undefined);
  // Used for the material frame label.
  // Can be reused to display some other properties of Component but should be renamed in this case.
  const [frameSubtitle, setFrameSubtitle] = useState<string | undefined>(undefined);

  const currentFamily = selectedOptions.family;
  const currentCategory = selectedOptions.category;
  const currentProduct = selectedOptions.product;

  const mappedProductsWithFamily = mappedProducts?.[currentFamily as Family];
  const mappedProductWithCategory = mappedProductsWithFamily?.[currentCategory as ProductCategory];

  const [
    getDetailProduct,
    {
      isLoading: isLoadingGetDetailProduct,
      isSuccess: isSuccessGetDetailProduct,
      data: detailProduct
    }
  ] = useGetOneProductMutation();

  /**
   * Get item components with specific property.
   *
   * @param {OrderItemLight} currentItem - The current order item.
   * @param {ComponentType} componentType - The component type to match.
   * @param {string} property - The specific property to add/update.
   * @param {number} id - The ID for the property.
   * @returns {OrderItemComponentLight[]} - An array of order item components with the specific property.
   */
  const getItemComponentsWithProperty = (
    currentItem: FullProduct,
    componentType: ComponentType,
    property: string,
    id: number
  ): OrderItemComponentLight[] => {
    const itemComponents: OrderItemComponentLight[] = [];
    currentItem?.components?.forEach((component: Component) => {
      if (component.componentType === componentType) {
        itemComponents.push({ ...component, [property]: { id } });
      } else {
        itemComponents.push(component);
      }
    });
    return itemComponents;
  };

  /**
   * Dispatch the product selection on Step Plan selections.
   * That part of the reducer doesn't change the map. It's important if we don't allow the click on it if the user haven't
   * chosen the frame yet.
   */
  const manageProductSelection = (): void => {
    if (isSuccessGetDetailProduct) {
      dispatch(
        ordersActions.setStepPlanSelections({
          selections: { product: detailProduct }
        })
      );

      /**
       * Force set currentItem on selections reducer object when a product doesn't have material.
       */
      if (!hasFrameComponent(detailProduct)) {
        handleClickProductWithoutFrame(detailProduct);
      }
    } else {
      dispatch(
        ordersActions.setStepPlanSelections({
          selections: { product: undefined }
        })
      );
    }
  };

  /**
   * Manage the click on a noFrame product.
   * @param product
   */
  const handleClickProductWithoutFrame = (product: FullProduct): void => {
    setFrameSubtitle(t('treatment.plan.product.frame.noFrame', { ns: 'treatment' }));
    setIsOpenTooltip(false);
    dispatch(
      ordersActions.setItemWithoutFrame({
        product: product,
        itemComponents: product.components
      })
    );
  };

  /**
   * Manage the click on a frame product.
   * @param material
   */
  const handleClickFinishProductButton = (material: Material | undefined): void => {
    const itemComponents = material
      ? getItemComponentsWithProperty(
          selectedOptions?.product as FullProduct,
          ComponentType.FRAME,
          'material',
          +material.id
        )
      : selectedOptions?.product?.components;
    setFrameSubtitle(
      material
        ? t(`material.${material.code}`)
        : t('treatment.plan.product.frame.noFrame', { ns: 'treatment' })
    );
    setIsOpenTooltip(false);
    dispatch(
      ordersActions.setCurrentItem({
        product: selectedOptions?.product as FullProduct,
        itemComponents
      })
    );
  };

  /**
   * Get the detail of the product when the user clicks on the bubble picto.
   * With that we can create the frame menu if the product have a frame component.
   *
   * @param product
   */
  const handleClickProductButton = (product: Product): void => {
    // Make the call when :
    // - it's the first click
    // - we click on another product
    const isValidCurrentItem = isValidCurrentItemCallback();
    if (
      !stepStatus.isDisabled &&
      isValidCurrentItem &&
      (!selectedOptions?.product || selectedOptions?.product?.id !== product?.id) &&
      product.id !== detailProduct?.id
    ) {
      addMultiRangeCallback();
      getDetailProduct(product.id);
    }

    // If the user have some spasms on his fingers.
    if (!stepStatus.isDisabled && isValidCurrentItem && product.id === detailProduct?.id) {
      addMultiRangeCallback();
      manageProductSelection();
    }
  };

  /**
   * Delete the frame subtitle when the current product changes.
   */
  useEffect(() => {
    setFrameSubtitle(undefined);
  }, [currentProduct]);

  /**
   * if the menu is active and the detail have successfully get, we manage the selection part.
   */
  useEffect(() => {
    manageProductSelection();
  }, [isSuccessGetDetailProduct]);

  const treatmentBubbleMenu = mappedProductWithCategory?.map((product: Product) => {
    const isCurrentProductHaveFrameComponent = hasFrameComponent(product);
    let currentProductFrameComponent = undefined;

    // To upgrade when we will add the other products.
    if (isCurrentProductHaveFrameComponent && currentProduct?.components) {
      currentProductFrameComponent = getFrameComponent(currentProduct.components) as Component;
    }

    return (
      <div
        key={`${currentFamily}-${currentCategory}-${product.labelFr.toLowerCase()}`}
        className={styles['treatment-product-bubble-menu--items--item']}>
        {
          <Tooltip openTooltip={isOpenTooltip}>
            <BubblePicto
              mainTitleColor={ColorPropsEnum.WHITE}
              subtitle={
                currentProduct?.id === product.id && isCurrentProductHaveFrameComponent
                  ? frameSubtitle
                  : undefined
              }
              displaysBorder={!!(currentProduct && currentProduct.id === product.id)}
              mainTitle={product[getLocalizedProperty('label') as keyof Product]}
              className={[
                styles['treatment-product-bubble-menu--items--item--bubble'],
                isLoadingGetDetailProduct
                  ? styles['treatment-product-bubble-menu--items--item--bubble--loading']
                  : ' '
              ].join(' ')}
              backgroundColor={ColorPropsEnum.PRIMARY}
              isClickable={stepStatus.isActive && !stepStatus.isDisabled}
              onClick={() => {
                setIsOpenTooltip(undefined);
                handleClickProductButton(product);
              }}
              size="large"
              color={ColorPropsEnum[`FAMILY_${product.family}` as keyof typeof ColorPropsEnum]}
              url={product.imageUrl}
              data-cy="planStepProductsItem"
              data-cy-selected={!!(currentProduct && currentProduct.id === product.id)}
            />
            {isCurrentProductHaveFrameComponent &&
              isSuccessGetDetailProduct &&
              product.id === currentProduct?.id && (
                <TooltipContent
                  borderRadius={BorderRadiusType.medium}
                  backgroundColor={ColorPropsEnum.PURPLE_MEDIUM}>
                  <div
                    className={styles['treatment-product-bubble-menu--tooltip']}
                    data-cy="treatmentProductBubbleMenu">
                    <img
                      className={styles['treatment-product-bubble-menu--tooltip--img']}
                      src={product.imageUrl}
                      alt={product[getLocalizedProperty('label') as keyof Product] as string}
                    />
                    <div className={styles['treatment-product-bubble-menu--tooltip--items']}>
                      <button
                        onClick={() => {
                          handleClickFinishProductButton(undefined);
                        }}
                        type="button"
                        key={`${currentProduct.id}-noFrame`}
                        className={styles['treatment-product-bubble-menu--tooltip--items--item']}>
                        {t('treatment.plan.product.frame.noFrame', { ns: 'treatment' })}
                      </button>
                      {currentProductFrameComponent?.materials?.map((material) => {
                        return (
                          <button
                            onClick={() => {
                              handleClickFinishProductButton(material);
                            }}
                            type="button"
                            data-cy="treatmentProductBubbleMenuItem"
                            key={`${currentProduct.id}-${material.id}`}
                            className={
                              styles['treatment-product-bubble-menu--tooltip--items--item']
                            }>
                            {t(`material.${material.code}`)}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </TooltipContent>
              )}
            {isCurrentProductHaveFrameComponent && isLoadingGetDetailProduct && (
              <TooltipContent
                borderRadius={BorderRadiusType.medium}
                backgroundColor={ColorPropsEnum.PURPLE_MEDIUM}>
                <div
                  className={[
                    styles['treatment-product-bubble-menu--tooltip'],
                    styles['treatment-product-bubble-menu--tooltip--loading']
                  ].join(' ')}>
                  <Loader color={ColorPropsEnum.WHITE} />
                </div>
              </TooltipContent>
            )}
          </Tooltip>
        }
      </div>
    );
  });
  return (
    <div
      className={[
        styles['treatment-product-bubble-menu--items'],
        isLoadingGetDetailProduct ? styles['treatment-product-bubble-menu--loading'] : ''
      ].join(' ')}>
      {' '}
      {treatmentBubbleMenu ?? ''}
    </div>
  );
};

export default TreatmentProductBubbleMenu;
