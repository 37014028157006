import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken, isCurrentUser } from './firebase.services.tsx';
import { BaseQueryApi } from '@reduxjs/toolkit/query';
import { backOff, BackoffOptions } from 'exponential-backoff';

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_GCP_GATEWAY,
  prepareHeaders: async (headers: Headers, api: Pick<BaseQueryApi, 'endpoint'>) => {
    if (api.endpoint === 'uploadToStorage') {
      return headers;
    }
    headers.set('x-api-key', import.meta.env.VITE_API_KEY_GCP_GATEWAY);
    if (isCurrentUser()) {
      const token = await getToken();
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

const exponentialBackOffBaseQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: object
) => {
  const backOffOptions: BackoffOptions = {
    maxDelay: 1000,
    numOfAttempts: 5,
    timeMultiple: 1
  };
  return await backOff(async () => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error && 'status' in result.error && result.error.status === 504) {
      throw new Error('504 Gateway Timeout');
    }
    return result;
  }, backOffOptions);
};

export const api = createApi({
  baseQuery: exponentialBackOffBaseQuery,
  tagTypes: ['Orders', 'Order', 'UserInfo', 'OneOrder'],
  endpoints: () => ({})
});
