import { ordersActions } from '../../../../store/orders/orders.reducer.tsx';
import { OrderTreatmentStep } from '../../../../enum/order.ts';
import { Dispatch } from '@reduxjs/toolkit';

const GoToPlanStep = (dispatch: Dispatch): void => {
  dispatch(
    ordersActions.setStepStatus({
      step: OrderTreatmentStep.PLAN,
      stepStatus: {
        isActive: true,
        isDisabled: false,
        isValid: false
      }
    })
  );
  dispatch(
    ordersActions.setActiveStepStatus({
      step: OrderTreatmentStep.REFERENCE,
      isActive: false
    })
  );
};

export { GoToPlanStep };
