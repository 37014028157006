import {
  DentalArchEnum,
  GingivaShadeEnum,
  MaterialEnum,
  ToothShadeEnum,
  ToothShapeEnum
} from '../enum/component';
import {
  PositionKeyString,
  SelectionContextEnum,
  ToothSelectionEnum,
  ZoneLinkEnum
} from '../enum/map.enum';
import { TeethMode } from '../enum/product.ts';
import { ComponentRule } from './component.ts';
import { ProductRule } from './product.tsx';
import { ColorPropsEnum, FamilyColorEnum } from '../enum/color.ts';

export interface ProductBubble {
  type: string;
  size: string;
  url: string;
  color: ColorPropsEnum;
  isClickable: boolean;
  displaysBorder: boolean;
  backgroundColor: ColorPropsEnum;
  tooltip: {
    position: string;
    isClickable: boolean;
    children?: React.ReactNode;
  };
}

export const productBubbleSkeleton: Partial<ProductBubble> = {
  type: 'product',
  size: 'small',
  isClickable: true,
  displaysBorder: true,
  backgroundColor: ColorPropsEnum.PURPLE_MEDIUM,
  tooltip: {
    position: 'right',
    isClickable: true
  }
};

export interface ProductIdForPosition {
  productId: number;
  uniqueProductId: string;
}

export interface Position {
  teethShade: ToothShadeEnum | undefined;
  shape: ToothShapeEnum | undefined;
  missing: boolean;
  extract: boolean;
  selection: ToothSelectionEnum;
  gingivaShade: GingivaShadeEnum | undefined;
  frame: MaterialEnum | undefined;
  range?: number;
  notation: PositionKeyString | undefined;
  arch: DentalArchEnum | undefined;
  zone_link: ZoneLinkEnum | undefined;
  productIds: Array<ProductIdForPosition>;
  bubbles?: {
    [key: string]: ProductBubble;
  };
  familyColor: FamilyColorEnum | undefined;
}

export interface MapClickPayload {
  notation: PositionKeyString;
  teethShade?: ToothShadeEnum;
  shape?: ToothShapeEnum;
  gingivaShade?: GingivaShadeEnum;
  frameMaterial?: MaterialEnum;
  familyColor?: FamilyColorEnum;
}

export interface MapContext {
  userAction: SelectionContextEnum | undefined;
  activeArch: DentalArchEnum | undefined;
  start: PositionKeyString | undefined;
  end: PositionKeyString | undefined;
  teethMode: TeethMode | undefined;
  teethComponentRule: ComponentRule | undefined;
  productRule: ProductRule | undefined;
  productId: number | undefined;
}

export interface MapReducer {
  positions: { [key: string]: Position };
  mapContext: MapContext | undefined;
}

export interface MapComponents {
  EXTRACT: Array<number | undefined>;
  MISSING: Array<number | undefined>;
  TOOTH: Array<Array<number>>;
  GINGIVA: Array<Array<number>>;
  FRAME: Array<Array<number>>;
  SHADES: { [x: string]: Array<number | undefined> };
  SHAPES: { [x: string]: Array<number | undefined> };
}

export const notationToIndex: { [key: string]: number } = {
  '18': 0,
  '17': 1,
  '16': 2,
  '15': 3,
  '14': 4,
  '13': 5,
  '12': 6,
  '11': 7,
  '21': 8,
  '22': 9,
  '23': 10,
  '24': 11,
  '25': 12,
  '26': 13,
  '27': 14,
  '28': 15,
  '48': 16,
  '47': 17,
  '46': 18,
  '45': 19,
  '44': 20,
  '43': 21,
  '42': 22,
  '41': 23,
  '31': 24,
  '32': 25,
  '33': 26,
  '34': 27,
  '35': 28,
  '36': 29,
  '37': 30,
  '38': 31
};

export const sortedUpperPositionsArray: Array<PositionKeyString> = [
  '18',
  '17',
  '16',
  '15',
  '14',
  '13',
  '12',
  '11',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28'
];

export const sortedLowerPositionsArray: Array<PositionKeyString> = [
  '48',
  '47',
  '46',
  '45',
  '44',
  '43',
  '42',
  '41',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38'
];

export const sortedPositionsArray: Array<PositionKeyString> = [
  ...sortedUpperPositionsArray,
  ...sortedLowerPositionsArray
];
