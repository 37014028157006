import {
  ArrowDirectionEnum,
  ArrowVariantEnum,
  LabelVariantEnum,
  LineFillEnum
} from './order-steps-item.enum';
import styles from './order-steps-item.module.scss';
import { Button } from '@anatoscope/circlestorybook';

interface OrderStepsItemProps {
  labelVariant: LabelVariantEnum;
  label: string;
  isClickable?: boolean;
  lineFill?: LineFillEnum;
  arrowDirection?: ArrowDirectionEnum;
  arrowVariant?: ArrowVariantEnum;
}

const OrderStepsItem = ({
  label,
  labelVariant,
  isClickable = false,
  lineFill,
  arrowDirection,
  arrowVariant = ArrowVariantEnum.GREY
}: OrderStepsItemProps) => {
  return (
    <>
      {arrowDirection === ArrowDirectionEnum.LEFT && (
        <span
          className={[
            styles['order-steps-item__left-arrow'],
            styles[`order-steps-item__left-arrow--${arrowVariant}`]
          ].join(' ')}></span>
      )}
      {isClickable ? (
        <Button label={label} size="s" className={styles['order-steps-item__button']}></Button>
      ) : (
        <div
          className={[styles['order-steps-item'], styles[`order-steps-item--${labelVariant}`]].join(
            ' '
          )}>
          {label}
        </div>
      )}
      {arrowDirection === ArrowDirectionEnum.RIGHT && (
        <span
          className={[
            styles['order-steps-item__right-arrow'],
            styles[`order-steps-item__right-arrow--${arrowVariant}`]
          ].join(' ')}></span>
      )}
      {lineFill && (
        <span
          className={[
            styles['order-steps-item__line'],
            styles[`order-steps-item__line--${lineFill}`]
          ].join(' ')}></span>
      )}
    </>
  );
};

export default OrderStepsItem;
