import { useAppDispatch, useAppSelector } from './store/hooks.tsx';
import { isAuthenticatedSelector } from './store/auth/auth.selectors.tsx';
import RoutesDentist from './RoutesDentist.tsx';
import { getAuthState, isDentistUser } from './services/firebase.services.tsx';
import { authActions } from './store/auth/auth.reducers.tsx';
import { User } from 'firebase/auth';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { history } from './models/history.tsx';
import {
  faCheck,
  faEnvelope,
  faHeadset,
  faList,
  faPencil,
  faXmark
} from '@fortawesome/pro-regular-svg-icons';
import { faChevronLeft, faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { useLazyGetUserInfoQuery } from './services/user.api.services.ts';

library.add(
  faList,
  faPencil,
  faHeadset,
  faXmark,
  faCheck,
  faPlus,
  faChevronRight,
  faChevronLeft,
  faEnvelope
);

function App() {
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  history.navigate = useNavigate();
  history.location = useLocation();
  const dispatch = useAppDispatch();
  const [getAuthenticatedUser] = useLazyGetUserInfoQuery();

  useEffect(() => {
    // Control user authentication with firebase (refresh, browser tabs...)
    const saveUserState = async (user: User | null) => {
      let isAuthorized = false;
      // Reducers don't like await. They are so impatient.
      if (user) {
        isAuthorized = await isDentistUser();
      }
      // Redux loves so much simple object or complex string :)
      dispatch(
        authActions.saveUserState({
          firebaseUser: JSON.stringify(user),
          isAuthorized
        })
      );
    };
    getAuthState(saveUserState);
  });

  // Previously we requested /me to get info of the authenticated user when needed
  // Now we do this here once he is connected
  // And use the value in the store when needed
  // So during registration, which uses the same form as dentist creation from the profile page,
  // We don't get a forbidden acces error for trying to reach /me unnecessarily
  useEffect(() => {
    if (isAuthenticated) {
      getAuthenticatedUser();
    }
  }, [isAuthenticated]);
  return (
    <>
      <div id="portal-root"></div>
      <div id="side-bar-modal-root"></div>
      <div id="dropdown-root"></div>
      <div id="dropdown-menu-root"></div>
      <div id="tooltip-root"></div>
      <div id="dialog-root"></div>
      {isAuthenticated !== undefined && <RoutesDentist></RoutesDentist>}
    </>
  );
}

export default App;
