import { api } from './api';
import { FullProduct, ProductListResponse } from '../models/product';

export const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEstimatedDeliveryDate: builder.mutation({
      query: (productIds) => ({
        url: '/products/estimateDeliveryDate',
        method: 'POST',
        body: { productIds }
      })
    }),
    getAllProducts: builder.query<ProductListResponse, void>({
      query: () => ({
        url: '/products?limit=0&filter.deletedAt=$null',
        method: 'GET'
      })
    }),
    getOneProduct: builder.mutation<FullProduct, number>({
      query: (productId) => ({
        url: `/products/${productId}`,
        method: 'GET'
      })
    })
  })
});

export const {
  useGetAllProductsQuery,
  useGetOneProductMutation,
  useGetEstimatedDeliveryDateMutation
} = productsApi;
