import React, { useState } from 'react';
import styles from './login-page.module.scss';
import { AttentionBox, Button, Link, Text, TextField, Box } from '@anatoscope/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { authActions } from '../../../store/auth/auth.reducers';
import { useTranslation } from 'react-i18next';
import {
  attentionBoxErrorSelector,
  fieldErrorSelector
} from '../../../store/feedback/feedback.selector';
import { feedbackActions } from '../../../store/feedback/feedback.reducer';
import { useNavigate } from 'react-router-dom';
import Onboarding from '../../../features/onboarding/Onboarding';
import { Page } from '../../../enum/user';
import { ColorPropsEnum } from '../../../enum/color';

const LoginPage = () => {
  const { t } = useTranslation(['auth']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [emailFieldOptions, setEmailFieldOptions] = useState<{
    variant: ColorPropsEnum;
    helperText: string;
  }>({
    variant: ColorPropsEnum.DEFAULT,
    helperText: ''
  });
  const [passwordFieldOptions, setPasswordFieldOptions] = useState<{
    variant: ColorPropsEnum;
    helperText: string;
  }>({
    variant: ColorPropsEnum.DEFAULT,
    helperText: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Selectors
  const attentionBoxError = useAppSelector(attentionBoxErrorSelector);
  const fieldError = useAppSelector(fieldErrorSelector);

  const handleEmailOnBlur = () => {
    if (emailFieldOptions.variant != ColorPropsEnum.DEFAULT)
      setEmailFieldOptions({
        variant: ColorPropsEnum.DEFAULT,
        helperText: ''
      });
  };

  const handlePasswordOnBlur = () => {
    if (passwordFieldOptions.variant != ColorPropsEnum.DEFAULT)
      setPasswordFieldOptions({
        variant: ColorPropsEnum.DEFAULT,
        helperText: ''
      });
  };

  const handleClickRegister = () => {
    navigate('/register');
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (email.length > 0 && password.length > 0) {
      try {
        setIsLoading(true);
        await dispatch(authActions.login({ username: email, password })).unwrap();
      } catch (error) {
        return;
      } finally {
        setIsLoading(false);
      }
    } else {
      if (email.length === 0) {
        setEmailFieldOptions({
          variant: ColorPropsEnum.DANGER,
          helperText: t('error.emptyEmail')
        });
      }
      if (password.length === 0) {
        setPasswordFieldOptions({
          variant: ColorPropsEnum.DANGER,
          helperText: t('error.emptyPassword')
        });
      }
    }
  };

  if (fieldError) {
    setEmailFieldOptions({
      variant: ColorPropsEnum.DANGER,
      helperText: fieldError.message
    });
  }

  const handleCloseErrorMessage = () => {
    dispatch(feedbackActions.resetAttentionBoxError());
  };

  return (
    <div className={styles['login-page']}>
      <header className={styles['login-page__header']}>
        <img
          src="/logo/logo-circle-title-white-vertical.svg"
          alt="Circle logo"
          className={styles['hotline-page__main__box__logo-circle']}
        />
        <Text
          className={styles['login-page__header__title']}
          label="Shape the future"
          color={ColorPropsEnum.WHITE}></Text>
      </header>
      {attentionBoxError && (
        <AttentionBox
          withoutIcon={false}
          mode="danger"
          className={styles['login-page__message']}
          onClose={handleCloseErrorMessage}
          text={attentionBoxError.message}
        />
      )}
      <Box
        padding="spacing-0"
        className={styles['login-page__box']}
        radius="large"
        color={ColorPropsEnum.WHITE}>
        <Onboarding page={Page.LOGIN} />
        <div className={styles['login-page__content']}>
          <Text
            label={t('title')}
            type="title"
            size="m"
            textAlign="center-align"
            color={ColorPropsEnum.PRIMARY}
            className={styles['login-page__content__title']}
            bold={true}
          />

          <form
            noValidate
            id="login-page__section__form"
            className={styles['login-page__content__form']}
            onSubmit={handleSubmit}>
            <TextField
              label={t('email')}
              id="email"
              value={email}
              placeholder="email@circle.com"
              type="email"
              variant={emailFieldOptions.variant}
              helperText={emailFieldOptions.helperText}
              wrapperClassName={styles['login-page__content__form__input']}
              onChange={(evt: Event) => setEmail((evt.target as HTMLInputElement).value)}
              onBlur={handleEmailOnBlur}
              radius="full"
            />
            <TextField
              label={t('password')}
              id="password"
              value={password}
              placeholder={t('password')}
              type="password"
              variant={passwordFieldOptions.variant}
              helperText={passwordFieldOptions.helperText}
              wrapperClassName={styles['login-page__content__form__input']}
              onChange={(evt: Event) => setPassword((evt.target as HTMLInputElement).value)}
              onBlur={handlePasswordOnBlur}
              radius="full"
            />
            <Link
              data-cy="forgotten-password"
              className={styles['login-page__content__form__forgotten-password']}
              color={ColorPropsEnum.PRIMARY}
              href="#"
              label={t('forgotPassword')}
              size="s"
            />

            <div className={styles['login-page__content__form__button']}>
              <Button
                label={t('buttonSubmit')}
                isLoading={isLoading}
                type="submit"
                variant={ColorPropsEnum.SUCCESS}
                className={styles['login-page__content__form__button__submit']}
              />
            </div>
          </form>
        </div>
      </Box>

      <footer className={styles['login-page__footer']}>
        <Text
          className={styles['login-page__footer__text']}
          label={t('noAccount')}
          size="s"
          color={ColorPropsEnum.WHITE}
        />
        <Button
          data-cy="register-link"
          category="secondary"
          variant={ColorPropsEnum.WHITE}
          label={t('signup')}
          size="s"
          onClick={handleClickRegister}
        />
      </footer>
    </div>
  );
};

export default LoginPage;
