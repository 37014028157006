import { DentalArchEnum } from '../../../enum/component';
import { CursorEnum, PositionKeyString, ZoneLinkEnum } from '../../../enum/map.enum';
import {
  Position,
  sortedLowerPositionsArray,
  sortedUpperPositionsArray
} from '../../../models/map';
import { isUpperArch } from './utils';

/**
 * Object containing not allowed cursor for all keyed tooth numbers.
 *
 * @type {Object<number, CursorEnum>}
 */
export const NotAllowedCursors: { [key: number]: CursorEnum } = {
  18: CursorEnum.NOT_ALLOWED,
  17: CursorEnum.NOT_ALLOWED,
  16: CursorEnum.NOT_ALLOWED,
  15: CursorEnum.NOT_ALLOWED,
  14: CursorEnum.NOT_ALLOWED,
  13: CursorEnum.NOT_ALLOWED,
  12: CursorEnum.NOT_ALLOWED,
  11: CursorEnum.NOT_ALLOWED,
  21: CursorEnum.NOT_ALLOWED,
  22: CursorEnum.NOT_ALLOWED,
  23: CursorEnum.NOT_ALLOWED,
  24: CursorEnum.NOT_ALLOWED,
  25: CursorEnum.NOT_ALLOWED,
  26: CursorEnum.NOT_ALLOWED,
  27: CursorEnum.NOT_ALLOWED,
  28: CursorEnum.NOT_ALLOWED,
  48: CursorEnum.NOT_ALLOWED,
  47: CursorEnum.NOT_ALLOWED,
  46: CursorEnum.NOT_ALLOWED,
  45: CursorEnum.NOT_ALLOWED,
  44: CursorEnum.NOT_ALLOWED,
  43: CursorEnum.NOT_ALLOWED,
  42: CursorEnum.NOT_ALLOWED,
  41: CursorEnum.NOT_ALLOWED,
  31: CursorEnum.NOT_ALLOWED,
  32: CursorEnum.NOT_ALLOWED,
  33: CursorEnum.NOT_ALLOWED,
  34: CursorEnum.NOT_ALLOWED,
  35: CursorEnum.NOT_ALLOWED,
  36: CursorEnum.NOT_ALLOWED,
  37: CursorEnum.NOT_ALLOWED,
  38: CursorEnum.NOT_ALLOWED
};

/**
 * compute global range between missig teeth at the ends of the arch
 * @param {{ [key: string]: Position }} positions
 * @param {DentalArchEnum} arch
 * @returns {Array<PositionKeyString>}
 */
const getRangeWithoutMissingAtTheEndOfTheArch = (
  positions: { [key: string]: Position },
  arch: DentalArchEnum
): Array<PositionKeyString> => {
  const positionsArray = isUpperArch(arch)
    ? [...sortedUpperPositionsArray]
    : [...sortedLowerPositionsArray];

  let firstTooth = 0;
  let lastTooth = positionsArray.length - 1;

  // Find first non-missing tooth
  while (firstTooth <= lastTooth && positions[positionsArray[firstTooth]]?.missing) {
    firstTooth++;
  }

  // Find last non-missing tooth
  while (lastTooth >= firstTooth && positions[positionsArray[lastTooth]]?.missing) {
    lastTooth--;
  }

  // If all teeth are missing, firstTooth > lastTooth
  return firstTooth <= lastTooth ? positionsArray.slice(firstTooth, lastTooth + 1) : [];
};

/**
 * compute all available ranges between products on the map
 * @param {{ [key: string]: Position }} positions
 * @param  {PositionKeyString[]} range
 * @returns {PositionKeyString[][]}
 */
const getAllAvailableRanges = (
  positions: { [key: string]: Position },
  range: PositionKeyString[]
): PositionKeyString[][] => {
  let availableToothRange: Array<PositionKeyString> = [];
  const allAvailableRanges: Array<Array<PositionKeyString>> = [];
  range.forEach((position) => {
    if (positions[position]?.productIds.length === 0) {
      // No product on the tooth
      availableToothRange.push(position);
    } else {
      allAvailableRanges.push(availableToothRange);
      availableToothRange = [];
    }
  });
  if (availableToothRange.length > 0) {
    allAvailableRanges.push(availableToothRange);
  }
  return allAvailableRanges;
};

/**
 *
 * Computes forbidden positions on specific arch for a range or multi-range selection, before the first click on map
 * we do not consider missing teeth in the middle of the arc
 * checks if a missing tooth at the extremities could block range selection on an arch
 * checks for existing products on map
 *
 * @param {number} minTeeth
 * @param {{ [key: string]: Position }} positions
 * @param {DentalArchEnum} arch
 * @returns {Array<PositionKeyString>}
 */
export const computeInitForbiddenPositions = (
  minTeeth: number,
  positions: { [key: string]: Position },
  arch: DentalArchEnum
): Array<PositionKeyString> => {
  const forbiddenPositions: Array<PositionKeyString> = [];

  // 1 - compute range without missing teeth at the extremities on specific arch
  const rangeWithoutMissingAtTheEnds: Array<PositionKeyString> =
    getRangeWithoutMissingAtTheEndOfTheArch(positions, arch);

  // 2 - in this global rangeWithoutMissingAtTheEnds, compute all available ranges without product
  const allAvailableRanges: Array<Array<PositionKeyString>> = getAllAvailableRanges(
    positions,
    rangeWithoutMissingAtTheEnds
  );

  // 3 - check if my product has enough space in each range
  allAvailableRanges.forEach((range) => {
    if (range.length >= minTeeth) {
      range.forEach((position, index) => {
        if (index + minTeeth > range.length && index + 1 - minTeeth < 0) {
          forbiddenPositions.push(position);
        }
      });
    } else {
      range.forEach((position) => {
        forbiddenPositions.push(position);
      });
    }
  });

  return forbiddenPositions;
};

/**
 * Compute forbidden positions for single-range after the first click according to min/max rule
 * @param {PositionKeyString} selectedPosition
 * @param {number} minTeeth
 * @param {number} maxTeeth
 * @param {DentalArchEnum} currentArch
 * @returns {Array<PositionKeyString>}
 */
export const computeSingleRangeForbiddenPositions = (
  selectedPosition: PositionKeyString,
  minTeeth: number,
  maxTeeth: number,
  currentArch: DentalArchEnum
): Array<PositionKeyString> => {
  const forbiddenPositions: Array<PositionKeyString> = [];

  let archPositions = isUpperArch(currentArch)
    ? [...sortedUpperPositionsArray]
    : [...sortedLowerPositionsArray];

  // in order to facilitate the calculation min/max rules here below : reverse the positions array if the user started to click from right to left.
  // TODO : probalbly not working for small single range like bridge or ackers
  if (['2', '3'].includes(selectedPosition.charAt(0))) {
    archPositions = archPositions.reverse();
  }

  const firstToothIndex = archPositions.findIndex((position) => selectedPosition === position);

  archPositions.forEach((position: PositionKeyString, index: number) => {
    if (index + 1 < firstToothIndex + minTeeth) {
      forbiddenPositions.push(position);
    }

    if (index + 1 > firstToothIndex + maxTeeth) {
      forbiddenPositions.push(position);
    }
  });
  return forbiddenPositions;
};

/**
 *
 * @param {{ [key: string]: Position }} positions
 * @param {DentalArchEnum} currentArch
 * @returns {Array<PositionKeyString>}
 */
export const getTeethBetweenZoneMultirange = (
  positions: { [key: string]: Position },
  currentArch: DentalArchEnum
): Array<PositionKeyString> => {
  let startZone: number | null = null;
  let endZone: number | null = null;
  let nextStartZone: number | null = null;
  const forbiddenPositions: Array<PositionKeyString> = [];
  const archPositions = isUpperArch(currentArch)
    ? [...sortedUpperPositionsArray]
    : [...sortedLowerPositionsArray];
  archPositions.forEach((position: PositionKeyString, i: number) => {
    switch (positions[position]?.zone_link) {
      case ZoneLinkEnum.START:
        startZone = i;
        break;
      case ZoneLinkEnum.END:
        endZone = i;
        break;
      case ZoneLinkEnum.END_START:
        if (startZone) {
          endZone = i;
          nextStartZone = i;
        } else if (endZone) {
          startZone = i;
        }
        break;
    }

    if (startZone && endZone) {
      forbiddenPositions.push(...[...archPositions].slice(startZone + 1, endZone));
      startZone = nextStartZone ?? null;
      endZone = null;
    }
  });
  return forbiddenPositions;
};
