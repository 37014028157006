import { Box, Divider, Text } from '@anatoscope/circlestorybook';
import styles from './hotline-page.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetAllLaboratoriesQuery } from '../../../services/establishments-api.services.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { ColorPropsEnum } from '../../../enum/color.ts';

const HotlinePage = () => {
  const { t } = useTranslation(['hotline']);
  const { data: labs } = useGetAllLaboratoriesQuery('');

  return (
    <div className={styles['hotline-page']}>
      <Box color={ColorPropsEnum.WHITE} className={styles['hotline-page__main']} radius="large">
        <>
          <section className={styles['hotline-page__main__box']}>
            <Text
              label={t('laboratory.title')}
              type="title"
              size="l"
              color={ColorPropsEnum.PRIMARY}
              textAlign="center-align"
              bold={true}
            />
            {labs?.data?.length && labs.data[0].logo && (
              <img
                src={labs.data[0].logo}
                alt="Partner laboratory logo"
                className={styles['hotline-page__main__box__logo-lab']}
              />
            )}
            <div className={styles['hotline-page__main__box__detail']}>
              <Text label={t('laboratory.text')} size="m" textAlign="center-align" />
              <Text label={t('laboratory.purpose')} size="s" textAlign="center-align" />
            </div>
            {labs?.data?.length && (
              <div className={styles['hotline-page__main__box__contact']}>
                <div className={styles['hotline-page__main__box__contact__tel-mail']}>
                  <div className={styles['hotline-page__main__box__contact__value']}>
                    {labs.data[0]?.phoneNumber && (
                      <>
                        <FontAwesomeIcon icon={faPhone} color={ColorPropsEnum.WHITE} />
                        <a
                          className={styles['hotline-page__main__box__contact__value__text']}
                          href={`tel:${labs.data[0].phoneNumber}`}>
                          {labs.data[0].phoneNumber}
                        </a>
                      </>
                    )}
                  </div>
                  <div className={styles['hotline-page__main__box__contact__value']}>
                    {labs.data[0]?.email && (
                      <>
                        <FontAwesomeIcon icon={faEnvelope} color={ColorPropsEnum.WHITE} />
                        <a
                          className={styles['hotline-page__main__box__contact__value__text']}
                          href={`mailto:${labs.data[0].email}`}>
                          {labs.data[0].email}
                        </a>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles['hotline-page__main__box__contact__value']}>
                  {labs.data[0]?.address && (
                    <>
                      <FontAwesomeIcon icon={faLocationDot} color={ColorPropsEnum.WHITE} />
                      <Text
                        color={ColorPropsEnum.WHITE}
                        size="s"
                        label={`${labs.data[0].address.address}${
                          labs.data[0].address.additionalAddress
                            ? ' ' + labs.data[0].address.additionalAddress
                            : ''
                        }, ${labs.data[0].address.zipCode}  ${labs.data[0].address.city}`}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
          </section>
          <Divider direction="vertical" className={styles['hotline-page__main__divider']} />
          <section className={styles['hotline-page__main__box']}>
            <Text
              label={t('circle.title')}
              type="title"
              size="l"
              color={ColorPropsEnum.PRIMARY}
              textAlign="center-align"
              bold={true}
            />
            <img
              src="/logo/logo-circle-title-purple-vertical.svg"
              alt="Circle logo"
              className={styles['hotline-page__main__box__logo-circle']}
            />
            <div className={styles['hotline-page__main__box__detail']}>
              <Text label={t('circle.text')} size="m" textAlign="center-align" />
              <Text label={t('circle.purpose')} size="s" textAlign="center-align" />
            </div>
            <div className={styles['hotline-page__main__box__contact']}>
              <div className={styles['hotline-page__main__box__contact__tel-mail']}>
                <div className={styles['hotline-page__main__box__contact__value']}>
                  <FontAwesomeIcon icon={faPhone} color={ColorPropsEnum.WHITE} />
                  <a
                    className={styles['hotline-page__main__box__contact__value__text']}
                    href="tel:+33 (0)6 16 96 33 67">
                    +33 (0)6 16 96 33 67
                  </a>
                </div>
                <div className={styles['hotline-page__main__box__contact__value']}>
                  <FontAwesomeIcon icon={faEnvelope} color={ColorPropsEnum.WHITE} />
                  <a
                    className={styles['hotline-page__main__box__contact__value__text']}
                    href="mailto:contact@circle.dental">
                    contact@circle.dental
                  </a>
                </div>
              </div>
              <div className={styles['hotline-page__main__box__contact__value']}>
                <FontAwesomeIcon icon={faLocationDot} color={ColorPropsEnum.WHITE} />
                <Text
                  color={ColorPropsEnum.WHITE}
                  size="s"
                  label="26 Av. Jean Kuntzmann, 38330 Montbonnot-Saint-Martin"
                />
              </div>
            </div>
          </section>
        </>
      </Box>
    </div>
  );
};

export default HotlinePage;
