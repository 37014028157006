import stylesTooltip from '../../teeth-map-bubble-tooltip.module.scss';
import { BubblePicto, Chips, Text } from '@anatoscope/circlestorybook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { OrderItemComponentLight, OrderItemLight } from '../../../../../models/order.tsx';
import { ColorPropsEnum } from '../../../../../enum/color.ts';
import { getLocalizedProperty } from '../../../../../utils/utils.tsx';
import { ComponentType, PositionKey } from '../../../../../enum/component.ts';
import { FullProduct } from '../../../../../models/product.tsx';
import { useTranslation } from 'react-i18next';
import { useGetCommonTypesQuery } from '../../../../../services/common-types-api.services.ts';
import {
  selectCurrentItemSelector,
  selectItemsSelector
} from '../../../../../store/orders/orders.selectors.tsx';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks.tsx';
import { mapActions } from '../../../../../store/map/map.reducer.tsx';
import { getFrameComponent } from '../../../utils.ts';
import { Material } from '../../../../../models/common-types.tsx';
import { ordersActions } from '../../../../../store/orders/orders.reducer.tsx';

/**
 * Tooltip details when the user clicks on the product bubble on the plan step when a product has been added to the map.
 *
 * @param bubbleItem
 *   Item concerned about the bubble and the tooltip. It's the full detailed product.
 * @param toothPosition
 *   The position of the tooth concerned about the bubble.
 * @constructor
 */
const TooltipDetails = ({
  bubbleItem,
  toothPosition
}: {
  bubbleItem: OrderItemLight;
  toothPosition: PositionKey;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['treatment']);

  const { data: commonTypes, isSuccess: isSuccessGetCommonTypes } = useGetCommonTypesQuery();

  // Items added.
  const items = useAppSelector(selectItemsSelector);
  const currentItem = useAppSelector(selectCurrentItemSelector);

  /**
   * Get all teeth positions from an item.
   * @param item
   */
  const getAllTeethPositionFromItem = (item: OrderItemLight) => {
    return (
      item?.itemComponents
        ?.filter(
          (component: OrderItemComponentLight) =>
            component?.teethPositions?.includes(toothPosition) ||
            component?.injectionPositions?.includes(toothPosition) ||
            component?.stumpPositions?.includes(toothPosition)
        )
        ?.reduce(
          (positions: PositionKey[], component: OrderItemComponentLight) =>
            positions.concat(
              component?.teethPositions?.includes(toothPosition) ? component.teethPositions : [],
              component?.injectionPositions?.includes(toothPosition)
                ? component.injectionPositions
                : [],
              component?.stumpPositions?.includes(toothPosition) ? component.stumpPositions : []
            ),
          []
        ) ?? []
    );
  };

  /**
   * Delete the product from the current item, or the list of items and finally from all positions on map context.
   */
  const handleDeleteProductsOnToothPosition = (): void => {
    let teethPositions: PositionKey[] = [];
    // Delete the order item based on current bubble item.
    if (!currentItem) {
      const restOfItems = items?.filter((item: OrderItemLight) => {
        // To delete the good item when the mouth contains a lot of identical product id.
        if (item.product.id === bubbleItem.product.id && teethPositions.length === 0) {
          teethPositions = getAllTeethPositionFromItem(item);
        }
        return (
          item.product.id !== bubbleItem.product.id ||
          (item.product.id === bubbleItem.product.id &&
            item?.itemComponents?.every((component) => {
              return (
                !component.teethPositions?.includes(toothPosition) &&
                !component.injectionPositions?.includes(toothPosition) &&
                !component.stumpPositions?.includes(toothPosition)
              );
            }))
        );
      });
      dispatch(ordersActions.setItems(restOfItems?.length ? restOfItems : undefined));
      dispatch(mapActions.resetPositions(teethPositions));
    } else {
      // Reset the map
      dispatch(mapActions.removeActiveProduct());
      dispatch(mapActions.resetSelectionTeeth());
      dispatch(mapActions.resetMapContext());
      // Init again with the current item.
      dispatch(mapActions.initSelectionTooth());
      const componentTooth = currentItem?.product?.components?.find(
        (component) => component.componentType === ComponentType.TOOTH
      );
      dispatch(
        mapActions.initMapContext({
          productId: currentItem.product.id,
          teethMode: currentItem.product.teethMode,
          teethComponentRule: componentTooth?.rule,
          productRule: currentItem?.product.productRule
        })
      );
    }
  };

  /**
   * Get the id material from the product.
   */
  const currentFrameMaterialId =
    bubbleItem?.itemComponents &&
    (getFrameComponent(bubbleItem.itemComponents) as OrderItemComponentLight)?.material?.id;

  /**
   * Get the material detail from the id.
   */
  const materialCommonTypes =
    isSuccessGetCommonTypes &&
    currentFrameMaterialId &&
    commonTypes.materials.find((material: Material) => material.id === currentFrameMaterialId);

  /**
   * Product properties to display into the tooltip opened.
   */
  const productPropertiesToDisplay = {
    label: (
      <Text
        key={`product-label`}
        className={stylesTooltip['teeth-map-bubble-tooltip__detail__item']}
        label={`${bubbleItem.product?.[getLocalizedProperty('label') as keyof FullProduct]}`}
        color={ColorPropsEnum.WHITE}
        size="l"
        italic={true}
      />
    ),
    category: (
      <Chips
        key={`component-category`}
        className={stylesTooltip['teeth-map-bubble-tooltip__detail__item']}
        color={`family-${bubbleItem.product.family.toLowerCase()}`}
        firstLabel={t(`categories.${bubbleItem.product?.category}`, { ns: 'catalog' })}
      />
    )
  };

  /**
   * The component properties to display into the tooltip opened.
   */
  const componentPropertiesToDisplay = {
    material: (
      <Text
        key="component-frame-material"
        className={stylesTooltip['teeth-map-bubble-tooltip__detail__item']}
        label={
          currentFrameMaterialId && currentFrameMaterialId >= 0
            ? materialCommonTypes && t(`material.${materialCommonTypes.code}`, { ns: 'component' })
            : t('treatment.plan.product.frame.noFrame', { ns: 'treatment' })
        }
        color={ColorPropsEnum.WHITE}
        size="s"
      />
    )
  };

  return (
    <div className={stylesTooltip['teeth-map-bubble-tooltip']}>
      <BubblePicto
        className={stylesTooltip['teeth-map-bubble-tooltip__product']}
        url={bubbleItem.product.imageUrl}
        color={`family-${bubbleItem.product.family.toLowerCase()}`}
        isClickable={false}
        displaysBorder={true}
        backgroundColor={ColorPropsEnum.PURPLE_MEDIUM}
        size="large"></BubblePicto>
      <div className={stylesTooltip['teeth-map-bubble-tooltip__detail']}>
        {Object.values(productPropertiesToDisplay).map(
          (productPropertyChild) => productPropertyChild
        )}
        {Object.values(componentPropertiesToDisplay).map(
          (componentTypeValuesChild) => componentTypeValuesChild
        )}
      </div>
      <div className={stylesTooltip['teeth-map-bubble-tooltip__actions']}>
        <button
          type="button"
          className={stylesTooltip['teeth-map-bubble-tooltip__actions__delete']}
          onClick={handleDeleteProductsOnToothPosition}>
          <FontAwesomeIcon icon={faTrash} size="xs" />
        </button>
      </div>
    </div>
  );
};

export default TooltipDetails;
