import styles from './treatment-page.module.scss';
import TreatmentHeader from './treatment-header/TreatmentHeader.tsx';
import { useGetOneOrderQuery } from '../../../services/orders-api.services.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ordersActions } from '../../../store/orders/orders.reducer.tsx';
import { useAppDispatch, useAppSelector } from '../../../store/hooks.tsx';
import { OrderTreatmentMode, OrderTreatmentStep } from '../../../enum/order.ts';
import { goToValidTargetStep } from './navigation.tsx';
import { isNotSubmittingOrder } from '../../../utils/order.utils.ts';
import { WorkflowStepEnum } from '../../../enum/workflow-step.ts';
import { selectEditionStepIsValid } from '../../../store/orders/orders.selectors.tsx';
import { Loader, Text } from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../../enum/color.ts';

const TreatmentPage = ({
  step,
  mode,
  children
}: {
  step: OrderTreatmentStep;
  mode: string;
  children: React.ReactNode;
}) => {
  const { t: tCommon } = useTranslation(['common']);
  const navigate = useNavigate();
  const { orderNumber } = useParams();
  const dispatch = useAppDispatch();
  const [skip, setSkip] = useState(!orderNumber);
  const [isLoaded, setIsLoaded] = useState(false);
  const { data: order, isSuccess: isGetOrderSuccess } = useGetOneOrderQuery(orderNumber as string, {
    skip
  });
  // If the reference step is valid, the user went through the first step. We will use this boolean to force
  // the redirection if the step is not valid when a user use a direct link when the order is not in readonly phase.
  // If an order is in submitting step, he should go through all the step in order.
  const isReferenceIsValid = useAppSelector((state) =>
    selectEditionStepIsValid(state, OrderTreatmentStep.REFERENCE)
  );

  useEffect(() => {
    // Set references to use them in the navigation bar.
    dispatch(
      ordersActions.setOrderReferences({
        orderNumber: order?.orderNumber,
        patient: {
          reference: order?.patient.reference
        }
      })
    );

    if (order && isNotSubmittingOrder(order)) {
      Object.values(OrderTreatmentStep).forEach((declaredStep: OrderTreatmentStep) => {
        dispatch(
          ordersActions.setStepStatus({
            step: declaredStep,
            stepStatus: {
              isActive: step === declaredStep,
              isDisabled: order?.currentStep ? isNotSubmittingOrder(order) : false,
              isValid: order?.currentStep ? isNotSubmittingOrder(order) : true
            }
          })
        );
      });
    } else {
      dispatch(
        ordersActions.setStepStatus({
          step,
          stepStatus: {
            isActive: true,
            isDisabled: false,
            isValid: false
          }
        })
      );
    }

    setIsLoaded(true);
  }, [isGetOrderSuccess]);

  useEffect(() => {
    if (mode === OrderTreatmentMode.CREATE) {
      setSkip(true);
      setIsLoaded(true);
    } else {
      setSkip(!orderNumber);
      setIsLoaded(!orderNumber);
    }
  }, [orderNumber, mode]);

  // Avoid any direct access to a step because for the different step, the back can not tell us which steps are valid
  // and revalidate each step in every refresh page can be not efficient.
  // So when the component is mounted, we check the step and redirect the user to the first one if needed.
  useEffect(() => {
    if (
      isGetOrderSuccess &&
      step !== OrderTreatmentStep.REFERENCE &&
      order &&
      (order.currentStep === WorkflowStepEnum.SUBMITTING || !order?.currentStep) &&
      !isReferenceIsValid
    ) {
      goToValidTargetStep(
        navigate,
        dispatch,
        {
          isActive: false,
          isValid: true
        },
        OrderTreatmentStep.REFERENCE,
        orderNumber
      );
    }
  }, [isGetOrderSuccess]);

  /**
   * Avoid a glimpse when the plan step is loading. Because this component is not fully reloaded, isLoaded stay with
   * the value to true and the page appears and disappears with the useEffect which manage the order loading state.
   * This is for the creation/edition state redirection only.
   */
  const isFullLoaded = () => {
    return (
      (mode === OrderTreatmentMode.EDIT && orderNumber && isLoaded && isGetOrderSuccess) ||
      (mode === OrderTreatmentMode.CREATE && isLoaded)
    );
  };

  return (
    <div data-cy="treatmentPage" id="treatment-page" className={styles['treatment-page']}>
      <TreatmentHeader />
      {isFullLoaded() && children}
      {!isFullLoaded() && (
        <div className={styles['treatment-page__loading']}>
          <Loader color={ColorPropsEnum.WHITE} />
          <Text color={ColorPropsEnum.WHITE} label={tCommon('loading')} />
        </div>
      )}
    </div>
  );
};
export default TreatmentPage;
