import { Box, Tabs, Text } from '@anatoscope/circlestorybook';
import styles from './profile-page.module.scss';
import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../../enum/color';

const ProfilePage = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation(['profile']);
  return (
    <>
      <Text
        className={styles['profile-page__title']}
        color={ColorPropsEnum.WHITE}
        label={t('title')}
        size="xs"
        type="title"
      />
      <Box color={ColorPropsEnum.WHITE} radius="large" className={styles['profile-page__box']}>
        <>
          <Tabs
            id="tabs-profile"
            className={styles['profile-page__tabs']}
            tabs={[
              {
                label: t('tabs.personalInformation'),
                to: '/profile/personal-information'
              },
              {
                label: t('tabs.team'),
                to: '/profile/team',
                end: false
              },
              {
                label: t('tabs.clinic'),
                to: '/profile/clinic',
                end: false
              }
            ]}></Tabs>
          {children}
        </>
      </Box>
    </>
  );
};

export default ProfilePage;
