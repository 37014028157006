import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  RadioList,
  Text,
  TextField
} from '@anatoscope/circlestorybook';
import styles from './personal-information.module.scss';
import {
  useGetUserInfoQuery,
  usePatchDentistMutation,
  useResetPasswordMutation
} from '../../../../services/user.api.services';
import useForm from '../../../../utils/useForm';
import { DentalNotation, Language } from '../../../../enum/user';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { languages } from '../../../../utils/utils';
import SkeletonList from '../../../../features/skeleton-list/SkeletonList';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import { useAppDispatch } from '../../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { CheckboxStringItem } from '../../../../models/form';
import { ColorPropsEnum } from '../../../../enum/color';

const PersonalInformation = () => {
  const { t } = useTranslation(['profile']);
  const dispatch = useAppDispatch();
  const { data: user, isLoading: isGetUserLoading, error: errorGetUser } = useGetUserInfoQuery();
  const [resetPassword, { isLoading: isLoadingResetPassword, isSuccess: isSuccessResetPassword }] =
    useResetPasswordMutation();
  const [
    patchDentist,
    { isSuccess: isPatchedDentist, isLoading: isEditingUser, isError: isErrorEditingUser }
  ] = usePatchDentistMutation();
  const [isDialogResetPasswordOpened, setIsDialogResetPasswordOpened] = useState<boolean>(false);
  const navigate = useNavigate();

  const onSubmit = async () => {
    patchDentist({
      ...values,
      isNotifiedTreatmentUpdate: (values.isNotifiedTreatmentUpdate as CheckboxStringItem[])[0]
        .isChecked
    });
  };

  useEffect(() => {
    if (isSuccessResetPassword) {
      dispatch(
        feedbackActions.setToast({
          message: t('toast.resetPassword'),
          type: 'success'
        })
      );
      setIsDialogResetPasswordOpened(false);
    }
  }, [isSuccessResetPassword, dispatch, t]);

  useEffect(() => {
    if (isPatchedDentist) {
      dispatch(
        feedbackActions.setToast({
          message: t('toast.userPatched', { ns: 'profile' }),
          type: 'success'
        })
      );
    }
  }, [isPatchedDentist, dispatch, t]);

  type PersonalInfo = {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    dentalNotation: DentalNotation | '';
    language: Language | '';
    isNotifiedTreatmentUpdate: CheckboxStringItem[];
  };

  const initUserInfo: PersonalInfo = useMemo(
    () => ({
      email: user?.email ?? '',
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      language: user?.language ?? '',
      phoneNumber: user?.phoneNumber ?? '',
      dentalNotation: user?.dentalNotation ?? '',
      isNotifiedTreatmentUpdate: [
        {
          value: 'isNotifiedTreatmentUpdate',
          isChecked: user?.isNotifiedTreatmentUpdate,
          label: t('personalInformation.preferences.notification.emailNotification')
        }
      ]
    }),
    [user]
  );

  const resetUserPassword = async () => {
    setIsDialogResetPasswordOpened(false);
    if (user) {
      resetPassword(user.email);
    }
  };

  const { values, setValues, handleCheck, handleSubmit, handleSelect, handleBlur, handleChange } =
    useForm(initUserInfo, onSubmit);

  useEffect(() => {
    setValues(initUserInfo);
  }, [initUserInfo, isErrorEditingUser, setValues]);

  return (
    <>
      <Dialog
        title={t('dialog.resetPassword.title')}
        text={t('dialog.resetPassword.text', { email: user?.email })}
        isLoading={isLoadingResetPassword}
        isOpened={isDialogResetPasswordOpened}
        cancelButtonLabel={t('action.cancel', { ns: 'common' })}
        confirmButtonLabel={t('dialog.resetPassword.action')}
        onCancel={() => setIsDialogResetPasswordOpened(false)}
        onConfirm={resetUserPassword}
      />
      <form onSubmit={handleSubmit} className={styles['personal-information__form']}>
        <div className={styles['personal-information__content']}>
          <article className={styles['personal-information__article']}>
            <div>
              <Text
                className={styles['personal-information__article__title']}
                label={t('personalInformation.contact.title')}
                bold={true}
                color={ColorPropsEnum.PRIMARY}></Text>
              {isGetUserLoading ? (
                <SkeletonList
                  className={styles['personal-information__skeleton']}
                  heightItem="30px"
                  widthItem="200px"
                  count={6}></SkeletonList>
              ) : (
                <>
                  <TextField
                    color={ColorPropsEnum.PRIMARY}
                    id="firstName"
                    name="firstName"
                    label={t('personalInformation.contact.labelInput.firstName')}
                    value={values?.firstName}
                    placeholder={t('personalInformation.contact.placeholder.firstName')}
                    className={styles['personal-information__article__input']}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    radius="full"
                  />
                  <TextField
                    color={ColorPropsEnum.PRIMARY}
                    id="lastName"
                    name="lastName"
                    label={t('personalInformation.contact.labelInput.lastName')}
                    value={values?.lastName}
                    placeholder={t('personalInformation.contact.placeholder.lastName')}
                    className={styles['personal-information__article__input']}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    radius="full"
                  />
                  <TextField
                    id="email"
                    label={t('personalInformation.contact.labelInput.email')}
                    placeholder={t('personalInformation.contact.placeholder.email')}
                    name="email"
                    isDisabled={true}
                    value={values?.email}
                    className={styles['personal-information__article__input']}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    radius="full"
                  />
                  <TextField
                    color={ColorPropsEnum.PRIMARY}
                    id="phoneNumber"
                    label={t('personalInformation.contact.labelInput.phoneNumber')}
                    placeholder={t('personalInformation.contact.placeholder.phoneNumber')}
                    name="phoneNumber"
                    value={values?.phoneNumber}
                    className={styles['personal-information__article__input--small']}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    radius="full"
                  />
                  <TextField
                    color={ColorPropsEnum.PRIMARY}
                    id="role"
                    name="role"
                    label={t('personalInformation.contact.labelInput.role')}
                    value={
                      errorGetUser === undefined
                        ? t(`user.roles.${user?.role.toLowerCase()}`, { ns: 'common' })
                        : null
                    }
                    placeholder={t('form.unknown', { ns: 'common' })}
                    className={styles['personal-information__article__input--small']}
                    isDisabled={true}
                    radius="full"
                  />
                </>
              )}
            </div>
          </article>
          <div
            style={{
              height: '100%',
              width: '4px'
            }}>
            <Divider direction="vertical" />
          </div>
          <article className={styles['personal-information__article']}>
            <div>
              <Text
                className={styles['personal-information__article__title']}
                bold={true}
                label={t('personalInformation.preferences.title')}
                color={ColorPropsEnum.PRIMARY}></Text>
              {isGetUserLoading ? (
                <SkeletonList
                  className={styles['personal-information__skeleton']}
                  heightItem="30px"
                  widthItem="200px"
                  count={6}></SkeletonList>
              ) : (
                <>
                  <RadioList
                    title={t('personalInformation.preferences.labelInput.dentalNotation')}
                    className={styles['personal-information__article__radio']}
                    options={[
                      {
                        label: t('personalInformation.preferences.labelInput.fdi'),
                        helperText: t('personalInformation.preferences.helperText.fdi'),
                        value: DentalNotation.ISO
                      },
                      {
                        label: t('personalInformation.preferences.labelInput.us'),
                        helperText: t('personalInformation.preferences.helperText.us'),
                        value: DentalNotation.US
                      }
                    ]}
                    name="dentalNotation"
                    radius="full"
                    displayedInRow={true}
                    selectedValue={values?.dentalNotation}
                    onClick={(newValue: DentalNotation) => handleSelect(newValue, 'dentalNotation')}
                  />
                  <RadioList
                    radius="full"
                    title="Langue"
                    options={languages}
                    name="language"
                    displayedInRow={true}
                    selectedValue={values.language}
                    className={styles['personal-information__article__radio']}
                    onClick={(newValue: Language) => handleSelect(newValue, 'language')}
                  />
                  <Text
                    className={styles['personal-information__notification__title']}
                    label={t('personalInformation.preferences.notification.title')}></Text>
                  <Checkbox
                    data-cy="notification-checkbox"
                    label={(values.isNotifiedTreatmentUpdate as CheckboxStringItem[])[0].label}
                    isChecked={
                      (values.isNotifiedTreatmentUpdate as CheckboxStringItem[])[0].isChecked
                    }
                    onClick={() =>
                      handleCheck('isNotifiedTreatmentUpdate', 'isNotifiedTreatmentUpdate')
                    }
                  />
                </>
              )}
            </div>
          </article>
        </div>
        <div>
          <div
            style={{
              height: '4px',
              width: '100%',
              marginBottom: '1rem'
            }}>
            <Divider />
          </div>
          <div className={styles['personal-information__footer']}>
            <Button
              label={t('personalInformation.contact.button')}
              onClick={() => setIsDialogResetPasswordOpened(true)}
              isDisabled={isGetUserLoading || errorGetUser !== undefined}
            />
            <Button
              label={t('action.cancel', { ns: 'common' })}
              category="secondary"
              iconLeft="fa-xmark"
              onClick={() => navigate('/treatments')}
              isDisabled={isGetUserLoading || errorGetUser !== undefined}
            />

            <Button
              label={t('action.save', { ns: 'common' })}
              type="submit"
              iconLeft="fa-check"
              variant={ColorPropsEnum.SUCCESS}
              isLoading={isEditingUser}
              isDisabled={isGetUserLoading || errorGetUser !== undefined}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default PersonalInformation;
