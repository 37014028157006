import styles from './menu.module.scss';
import { MenuItems } from '../../../models/navigation.tsx';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEllipsisVertical, faGear } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { SyntheticEvent, useState } from 'react';

library.add(faGear, faEllipsisVertical);

interface MenuProps {
  items: MenuItems;
  zIndex: number;
}

export const Menu = ({ items, zIndex = 3 }: MenuProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isHoverDisabled, setIsHoverDisabled] = useState<boolean>(false);

  const expand = (e: SyntheticEvent) => {
    const target = e.target as HTMLElement;
    // if hovering over the expand button prevent hover event
    if (
      target instanceof HTMLElement &&
      (target.className.split(' ').includes(styles['menu__toggle-button']) ||
        target.className.split(' ').includes(styles['menu__toggle-button__icon']))
    ) {
      return;
    }
    // else expand
    return setIsOpened(true);
  };

  const collapse = () => {
    return setIsOpened(false);
  };

  const onToggleBtnClick = () => {
    // if menu has been opened with the toggle button, disable expanding/collapsing menu on hovering
    if (!isOpened) {
      setIsHoverDisabled(true);
    }
    // restore hovering when we collapse with the toggle button
    if (isOpened && isHoverDisabled) {
      setIsHoverDisabled(false);
    }

    return setIsOpened(!isOpened);
  };

  const onMouseLeaveToggleBtn = (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    // when mouse is hovering the area over the toggle button, and leaves towards the menu, force the menu to open
    const relatedTarget = e.relatedTarget as HTMLElement | null;
    if (
      relatedTarget instanceof HTMLElement &&
      relatedTarget.className.split(' ').includes(styles['menu'])
    ) {
      return setIsOpened(true);
    }
  };

  const itemsList = ['upper', 'lower'].map((section) => (
    <ul
      key={section}
      className={[styles['menu__section'], styles[`menu__section--${section}`]].join(' ')}>
      {items[section as 'upper' | 'lower'].map((item) => (
        <li key={item.label.replace(/ /g, '')}>
          {item.link && (
            <NavLink
              className={({ isActive }) =>
                [
                  styles['menu__section__item'],
                  styles['menu__section__item--link'],
                  item.isDisabled && styles['menu__section__item--disabled'],
                  !isOpened
                    ? styles['menu__section__item--collapsed--link']
                    : styles['menu__section__item--expanded--link'],
                  styles[isActive ? 'menu__section__item--active' : '']
                ].join(' ')
              }
              to={item.link}
              onClick={(event) => {
                if (item.isDisabled) event.preventDefault();
              }}>
              {item.icon && (
                <div className={styles['menu__section__item__icon']}>
                  <FontAwesomeIcon icon={item.icon} size="lg" />
                </div>
              )}
              <span
                className={[
                  styles['menu__section__item__text'],
                  !isOpened ? styles['menu__section__item__text--collapsed'] : ''
                ].join(' ')}>
                {item.label}
              </span>
            </NavLink>
          )}
          {item.onClick && (
            <button
              className={[
                styles['menu__section__item'],
                styles['menu__section__item--button'],
                !isOpened
                  ? styles['menu__section__item--collapsed--button']
                  : styles['menu__section__item--expanded--button']
              ].join(' ')}
              disabled={item.isDisabled}
              onClick={() => item.onClick && item.onClick()}
              data-cy={item['data-cy'] || ''}>
              {item.icon && (
                <div className={styles['menu__section__item__icon']}>
                  <FontAwesomeIcon icon={item.icon} size="lg" />
                </div>
              )}
              <span
                className={[
                  styles['menu__section__item__text'],
                  !isOpened && styles['menu__section__item__text--collapsed']
                ].join(' ')}>
                {item.label}
              </span>
            </button>
          )}
        </li>
      ))}
    </ul>
  ));

  return (
    <nav>
      <div
        style={{ zIndex: zIndex }}
        className={[
          styles['menu'],
          !isOpened ? styles['menu--collapsed'] : styles['menu--expanded']
        ].join(' ')}
        {...(isHoverDisabled ? {} : { onMouseEnter: expand })}
        {...(isHoverDisabled ? {} : { onMouseLeave: collapse })}>
        <a href="/" className={styles['menu__head']}>
          <img src="/logo/logo-circle-white.svg" alt="Circle logo" width="33" height="33" />
          {isOpened && (
            <img src="/logo/logo-circle-text.svg" alt="Circle logo text" width="93" height="33" />
          )}
        </a>
        {itemsList}
        <button
          style={{ zIndex: zIndex }}
          className={styles['menu__toggle-button']}
          onMouseLeave={onMouseLeaveToggleBtn}
          onClick={onToggleBtnClick}
          data-cy={'menu_trigger'}>
          <div
            className={[
              styles['menu__toggle-button__icon'],
              !isOpened
                ? styles['menu__toggle-button__icon--collapsed']
                : styles['menu__toggle-button__icon--expanded']
            ].join(' ')}>
            <FontAwesomeIcon icon={faChevronRight} size="lg" />
          </div>
        </button>
        {/* { sorry, adding the menu right border as a dom elelement, explanation:
            adding the border on .menu in css makes this 1px border hoverable
            which we don't particulary want because, the menu_trigger button must remain unhoverable
        } */}
        <div className={styles['menu__border']}></div>
      </div>
    </nav>
  );
};
