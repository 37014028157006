import { Text } from '@anatoscope/circlestorybook';
import styles from './treatment-header.module.scss';
import NavigationBar from './navigation-bar/NavigationBar.tsx';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../../../enum/color.ts';

const TreatmentHeader = () => {
  const { t } = useTranslation(['treatment']);

  const navigate = useNavigate();
  return (
    <div className={styles['treatment-header']}>
      <div className={styles['treatment-header__nav']}>
        <div className={styles['treatment-header__logo']}>
          <a href="/" className={styles['treatment-header__slogan']}>
            <img
              data-cy="treatmentHeaderLogo"
              src="/logo/logo-circle-white.svg"
              alt="Circle logo"
              className={styles['treatment-header__slogan__img']}
            />
            <div className={styles['treatment-header__slogan__text']}>
              <Text
                data-cy="treatmentHeaderSlogan"
                color={ColorPropsEnum.WHITE}
                size="s"
                label={t('treatment.slogan.newTreatment')}
                className={styles['treatment-header__slogan__text__principal']}
              />
              <Text
                data-cy="treatmentHeaderCatchPhrase"
                color={ColorPropsEnum.WHITE}
                size="xs"
                label={t('treatment.slogan.catchPhrase')}
              />
            </div>
          </a>
        </div>

        <div className={styles['treatment-header__breadcrumb']}>
          <NavigationBar></NavigationBar>
        </div>
        <div className={styles['treatment-header__empty']}></div>
      </div>

      <button
        data-cy="treatmentHeaderQuit"
        className={styles['treatment-header__quit']}
        onClick={() => navigate('/treatments')}>
        <FontAwesomeIcon icon={faXmark} size={'xl'} color={ColorPropsEnum.WHITE} />
      </button>
    </div>
  );
};

export default TreatmentHeader;
