import { useTranslation } from 'react-i18next';
import styles from './onboarding.module.scss';
import { Text } from '@anatoscope/circlestorybook';
import { Page } from '../../enum/user';
import { ColorPropsEnum } from '../../enum/color';

const Onboarding = ({ page }: { page: Page }) => {
  const { t } = useTranslation(['auth']);

  return (
    <article className={styles['onboarding']}>
      <div className={styles['onboarding__content']}>
        <Text
          label={t('onboarding.hello')}
          textAlign="center-align"
          type="title"
          color={ColorPropsEnum.WHITE}
          size="s"
          bold={true}
        />
        <div>
          <Text
            label={t(`onboarding.${page}.text-1`)}
            textAlign="center-align"
            color={ColorPropsEnum.WHITE}
          />

          {page === Page.LOGIN && (
            <Text
              label={t('onboarding.login.text-2')}
              textAlign="center-align"
              color={ColorPropsEnum.WHITE}
            />
          )}
        </div>
        <img src="/image/register-ipad.svg" alt="ipad" />
        <div>
          <Text
            label={t(`onboarding.${page}.text-3`)}
            bold={true}
            textAlign="center-align"
            color={ColorPropsEnum.WHITE}
            size="s"
          />

          {page === Page.LOGIN && (
            <Text
              label={t('onboarding.login.text-4')}
              bold={true}
              size="s"
              textAlign="center-align"
              color={ColorPropsEnum.WHITE}
            />
          )}
        </div>
      </div>
    </article>
  );
};

export default Onboarding;
