import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrdersReducer } from '../../models/order.ts';
import { OrderTreatmentStep } from '../../enum/order.ts';
import {
  CreationStep,
  OrderItemLight,
  OrderReferences,
  OrderSteps,
  PlanSelectionOptions
} from '../../models/order.tsx';
import { Diagnostic, DiagnosticUpdate } from '../../models/diagnostic.tsx';

const initialOrders: OrdersReducer = {
  order: undefined,
  items: undefined,
  currentItem: undefined,
  files: undefined,
  editionSteps: {
    [OrderTreatmentStep.REFERENCE]: {
      isValid: false,
      isDisabled: false,
      isActive: false
    },
    [OrderTreatmentStep.PLAN]: {
      isValid: false,
      isDisabled: true,
      isActive: false
    },
    [OrderTreatmentStep.CUSTOM]: {
      isValid: false,
      isDisabled: true,
      isActive: false
    },
    [OrderTreatmentStep.SCAN]: {
      isValid: false,
      isDisabled: true,
      isActive: false
    },
    [OrderTreatmentStep.SUBMIT]: {
      isValid: false,
      isDisabled: true,
      isActive: false
    }
  },
  edition: {
    plan: {
      selectedOptions: {
        family: undefined,
        category: undefined,
        product: undefined
      }
    }
  }
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialOrders,
  reducers: {
    setOrderReferences: (state, action: PayloadAction<OrderReferences>) => {
      state.order = {
        orderNumber: action.payload?.orderNumber,
        patient: {
          reference: action.payload?.patient?.reference
        }
      };
    },
    setDiagnostic: (state, action: PayloadAction<DiagnosticUpdate>) => {
      state.order = {
        ...state.order,
        patient: {
          ...state?.order?.patient,
          diagnostic: {
            ...state?.order?.patient?.diagnostic,
            ...(action.payload as Diagnostic)
          }
        }
      };
    },
    setItems: (state, action: PayloadAction<OrderItemLight[] | undefined>) => {
      state.items = action.payload;
    },
    setItem: (state, action: PayloadAction<OrderItemLight>) => {
      state.items = state.items ? [...state.items, action.payload] : [action.payload];
    },
    setCurrentItem: (state, action: PayloadAction<OrderItemLight | undefined>) => {
      state.currentItem = action.payload;
    },
    setItemWithoutFrame: (state, action: PayloadAction<OrderItemLight>) => {
      state.edition.plan.selectedOptions.product = action.payload.product;
      state.currentItem = action.payload;
    },
    setStepStatus: (
      state,
      action: PayloadAction<{ step: OrderTreatmentStep; stepStatus: CreationStep }>
    ) => {
      state.editionSteps[action.payload.step as keyof OrderSteps] = {
        ...state.editionSteps[action.payload.step],
        ...action.payload.stepStatus
      };
    },
    setValidCreationStepsStatus: (
      state,
      action: PayloadAction<{ step: OrderTreatmentStep; isValid: boolean }>
    ) => {
      state.editionSteps[action.payload.step as keyof OrderSteps] = {
        ...state.editionSteps[action.payload.step],
        isValid: action.payload.isValid
      };
    },
    setDisabledStepStatus: (
      state,
      action: PayloadAction<{ step: OrderTreatmentStep; isDisabled: boolean }>
    ) => {
      state.editionSteps[action.payload.step as keyof OrderSteps] = {
        ...state.editionSteps[action.payload.step],
        isDisabled: action.payload.isDisabled
      };
    },
    setActiveStepStatus: (
      state,
      action: PayloadAction<{ step: OrderTreatmentStep; isActive: boolean }>
    ) => {
      state.editionSteps[action.payload.step as keyof OrderSteps] = {
        ...state.editionSteps[action.payload.step],
        isActive: action.payload.isActive
      };
    },
    setStepPlanSelections: (
      state,
      action: PayloadAction<{
        selections: PlanSelectionOptions;
      }>
    ) => {
      state.edition[OrderTreatmentStep.PLAN].selectedOptions = {
        ...state.edition[OrderTreatmentStep.PLAN].selectedOptions,
        ...action.payload.selections
      };
    },
    reset: () => initialOrders,
    resetActiveStepStatus: (state) => {
      Object.values(OrderTreatmentStep).forEach((step) => {
        state.editionSteps[step] = {
          ...state.editionSteps[step],
          isActive: false
        };
      });
    },
    resetStepStatus: (state) => {
      state.editionSteps = initialOrders.editionSteps;
    },
    resetPlanEdition: (state) => {
      state.edition.plan = initialOrders.edition.plan;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<OrdersReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialOrders };
    });
  }
});

const ordersActions = ordersSlice.actions;

export { ordersSlice, ordersActions };
