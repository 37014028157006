export enum EstablishmentType {
  LABORATORY = 'LABORATORY',
  CLINIC = 'CLINIC',
  DESIGN_CENTER = 'DESIGN_CENTER'
}

export enum EstablishmentPayload {
  DESIGN_CENTER = 'designCenter',
  LABORATORY = 'laboratory',
  CLINIC = 'clinic'
}
