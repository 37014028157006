import { useCallback } from 'react';
import DatagridFeature from '../../../features/datagrid/DatagridFeature';
import { Button, Text } from '@anatoscope/circlestorybook';
import styles from './dashboard-page.module.scss';
import { LoadDataProps } from '../../../models/datagrid';
import { useLazyGetOrdersQuery } from '../../../services/orders-api.services';
import { dashboardColumns } from './dashboard-columns';
import { useTranslation } from 'react-i18next';
import FiltersBar from './filters-bar/FiltersBar';
import { useAppSelector } from '../../../store/hooks';
import { treatmentsDatagridStateSelector } from '../../../store/datagrids-settings/datagrids-settings.selectors';
import { FilterName } from '../../../enum/datagrid';
import { useNavigate } from 'react-router-dom';
import { ColorPropsEnum } from '../../../enum/color';
import { useGetUserInfoQuery } from '../../../services/user.api.services.ts';

const DashboardPage = () => {
  const { t } = useTranslation(['common']);
  const [getOrders] = useLazyGetOrdersQuery();
  const datagridSettings = useAppSelector(treatmentsDatagridStateSelector);
  const navigate = useNavigate();
  const { data: connectedUser, isSuccess: isConnectedUserSuccess } = useGetUserInfoQuery();

  const loadData = async ({ skip, limit }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filtersQuery = buildOrdersFilters();
    const searchQuery = datagridSettings?.searchValue
      ? `&search=${encodeURIComponent(datagridSettings?.searchValue)}`
      : '';

    return getOrders({ page, limit, filtersQuery, searchQuery })
      .unwrap()
      .then((orders) => {
        return { data: orders.data, count: orders.meta.totalItems };
      })
      .catch(() => {
        return { data: [], count: 0 };
      });
  };

  const buildOrdersFilters = useCallback(() => {
    const filters = [];
    const familyFilter = datagridSettings?.filters?.find(
      (filter) => filter.name === FilterName.FAMILY
    );
    if (familyFilter?.value) {
      filters.push(`&filter.families=${familyFilter.value}`);
    }
    const currentStepFilter = datagridSettings?.filters?.find(
      (filter) => filter.name === FilterName.CURRENT_STEP
    );
    if (currentStepFilter?.value) {
      filters.push(`&filter.currentStep=${currentStepFilter.value}`);
    }
    return filters.length ? filters.join('') : '';
  }, [datagridSettings?.filters]);

  const dataSource = useCallback(loadData, [getOrders, datagridSettings, buildOrdersFilters]);

  return (
    <div id="dashboard-page" className={styles['dashboard-page']}>
      <div className={styles['dashboard-page__header']}>
        <Text
          className={styles['dashboard-page__header__title']}
          color={ColorPropsEnum.WHITE}
          label={t('link.myTreatments').toUpperCase()}
          size="xs"
          type="title"
        />
        <Button
          category="secondary"
          className={styles['dashboard-page__header__button']}
          label={t('action.createTreatment', { ns: 'dashboard' })}
          onClick={() => navigate('/treatment/create')}
          variant={ColorPropsEnum.WHITE}
          iconLeft="fa-regular fa-pencil"
          isDisabled={!isConnectedUserSuccess || !connectedUser?.isAllowedToOrder}
        />
      </div>
      <DatagridFeature
        style={{
          minHeight: 'calc(100dvh - 4.5rem)',
          border: 'none',
          backgroundColor: 'transparent'
        }}
        key="orders"
        dataSource={dataSource}
        columns={dashboardColumns}
        rowHeight={60}
        rowHeights={{ '-1': 100 }} // here: the fake row (id:-1) at the end is given a custom height
        showHeader={false}
        rowClassName={styles['dashboard-page__datagrid__row']}
        showActiveRowIndicator={false}
      />
      <FiltersBar />
    </div>
  );
};

export default DashboardPage;
