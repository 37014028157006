import styles from './menu-tools.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faEyeSlash, faLightbulb, faScalpel } from '@fortawesome/pro-light-svg-icons';
import { ColorPropsEnum } from '../../../../enum/color';
import InstructionForm from '../../../../features/instruction-form/InstructionForm.tsx';
import Tooltip from '../../../../features/tooltip/Tooltip.tsx';
import TooltipContent from '../../../../features/tooltip/TooltipContent/TooltipContent.tsx';
import { useState } from 'react';

const MenuTools = () => {
  const [isOpenInstructions, setIsOpenInstructions] = useState(false);

  return (
    <div className={styles['menu-tools']} data-cy="treatmentMenuTools">
      <Tooltip
        position="left"
        maxWidthPx={368}
        openTooltip={isOpenInstructions}
        zIndex={2}
        data-cy="treatmentMenuToolsInstructionsTooltip">
        <button
          className={styles['menu-tools--tool']}
          type="button"
          data-cy="treatmentMenuToolsInstructionsButton">
          <FontAwesomeIcon icon={faComment} color={ColorPropsEnum.WHITE} size="xl" />
        </button>
        <TooltipContent>
          <InstructionForm setIsOpenInstructions={setIsOpenInstructions} />
        </TooltipContent>
      </Tooltip>
      <button className={styles['menu-tools--tool']} disabled>
        <FontAwesomeIcon icon={faScalpel} color={ColorPropsEnum.WHITE} size="xl" />
      </button>
      <button className={styles['menu-tools--tool']} disabled>
        <FontAwesomeIcon icon={faEyeSlash} color={ColorPropsEnum.WHITE} size="xl" />
      </button>
      <button className={styles['menu-tools--tool']} disabled>
        <FontAwesomeIcon icon={faLightbulb} color={ColorPropsEnum.WHITE} size="xl" />
      </button>
    </div>
  );
};
export default MenuTools;
